import * as React from 'react';
import Clipboard from 'react-clipboard.js';
import {toastSuccess} from "../../lib/util/toast";

export interface ICopyIconProps {
  keyword: string
}

interface ICopyIconState {
  //..
}

export class CopyIcon extends React.Component<ICopyIconProps, ICopyIconState> {

  getText = () => {
    return this.props.keyword;
  };

  onSuccess = () => {
    toastSuccess(`'${this.props.keyword}' copied to clipboard successfully.`);
  };

  render(){
    return (
        <Clipboard
            option-text={this.getText} onSuccess={this.onSuccess}
            className="btn btn-sm btn-light border border-secondary mr-2">
          <i className="fas fa-copy" />
        </Clipboard>
    );
  }

}

export default CopyIcon;