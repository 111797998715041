import {Country} from "../../../countries";

const SupportedCountries = [
    Country.UnitedStates,
    Country.Australia,
    Country.Brazil,
    Country.Canada,
    Country.France,
    Country.Germany,
    Country.India,
    Country.Italy,
    Country.Japan,
    Country.Mexico,
    Country.Netherlands,
    Country.Spain,
    Country.UnitedKingdom
];

export default SupportedCountries