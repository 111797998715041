import * as React from "react";
import * as _ from "lodash";
import {Modal} from "react-bootstrap";
import "../../../css/rc-tree-select.css";
import {IndustryInterface} from "../../lib/api/keyword-keg/search/industries";
import {IFilters, IUserFilter} from "../../lib/api/keyword-keg/search/filters";
import {NumericFilter} from './filter-modal/numeric-filter';
import {RegExpFilter} from './filter-modal/regexp-filter';
import {IndustryFilter} from "./filter-modal/industry-filter";
import {SerpRadioFilter} from "./filter-modal/serp-radio-filter";
import {SaveFilterForm} from "./filter-modal/save-filter-form";

export interface FilterModalProps {
  show: boolean,
  onHide: () => void,
  onChange: (filters: IFilters, userFilter?: IUserFilter) => void,
  allowSERPFilter: boolean,
  allowCTROpportunityFilter: boolean,
  allowSEODifficultyFilter: boolean,
  allowKeywordPotentialFilter: boolean,
  industries: { [id: number]: IndustryInterface },
  currency: {
    rate: number,
    symbol: string
  },
  filters: IFilters,
  userFilter: IUserFilter,
  redirectToSignIn?: boolean,
  redirectToPricing?: boolean
}

interface FilterModalState {
  filters?: any,
  regexpError?: boolean,
  show?: boolean,
  filterName?: string
}

const SERP_FILTERS = [
  {
    property: 'ads',
    label: 'Ads'
  },
  {
    property: 'total_local',
    label: 'Local Listings'
  },
  {
    property: 'total_news',
    label: 'News Listings'
  },
  {
    property: 'total_fresh',
    label: 'Fresh Listings'
  },
  {
    property: 'has_snippet',
    label: 'Has Snippet'
  },
  {
    property: 'total_videos',
    label: 'Video Listings'
  },
  {
    property: 'total_images',
    label: 'Image Listings'
  },
  {
    property: 'total_pla',
    label: 'Product Listings'
  },
  {
    property: 'has_knowledge_graphs',
    label: 'Knowledge Graphs'
  }
];

type LabelFunc = (props: FilterModalProps) => string;
interface NumericFilterDef {
  name: string,
  label: string | LabelFunc,
  otherProps?: { [key: string]: string },
  condition?: (props: FilterModalProps) => boolean,
}

const NUMERIC_FILTERS: NumericFilterDef[] = [
  {
    name: 'monthly_volume',
    label: 'Search Volume:'
  },
  {
    name: 'value',
    label: ({currency}) => `Value (${currency.symbol}):`
  },
  {
    name: 'cpc',
    label: ({currency}) => `CPC (${currency.symbol}):`
  },
  {
    name: 'words',
    label: 'Total Words:'
  },
  {
    name: 'length',
    label: 'Keyword Length:'
  },
  {
    name: 'competition',
    label: 'Competition:',
    otherProps: {
      step: "0.01",
      max: "1"
    }
  },
  {
    name: 'seo_difficulty',
    label: 'SEO Difficulty:',
    condition: ({allowSEODifficultyFilter: b}) => b
  },
  {
    name: 'onpage_difficulty',
    label: 'On-Page Difficulty:',
    condition: ({allowSEODifficultyFilter: b}) => b
  },
  {
    name: 'offpage_difficulty',
    label: 'Off-Page Difficulty:',
    condition: ({allowSEODifficultyFilter: b}) => b
  },
  {
    name: 'kw_potential',
    label: 'Keyword Power:',
    condition: ({allowKeywordPotentialFilter: b}) => b
  }
];

export class FilterModal extends React.Component<FilterModalProps, FilterModalState> {

  state = {
    filters: this.props.filters,
    regexpError: false,
    show: false,
    filterName: ""
  };

  static defaultProps = {
    redirectToSignIn: false,
    redirectToPricing: false
  };

  componentDidMount(): void {
    $('body').append($('<div>', {class: "search-result-filters-overlay"}));
  }

  componentDidUpdate(prevProps: Readonly<FilterModalProps>, prevState: Readonly<FilterModalState>, prevContext: any): void {
    const lastShow = prevProps.show && prevState.show;
    const thisShow = this.props.show && this.state.show;

    if(lastShow != thisShow){
      $('body').toggleClass('search-result-filters-expanded');
    }
  }

  redirectFreeUser(show: boolean) {
    if (!show) {
      return false;
    }
    if (this.props.redirectToSignIn) {
      window.location.href = "/auth/login";
      return true;
    } else if (this.props.redirectToPricing) {
      window.location.href = "/pricing";
      return true;
    }
    return false;
  }

  componentWillReceiveProps(nextProps: FilterModalProps) {
    if (this.redirectFreeUser(nextProps.show)) {
      return;
    }
    ///
    this.setState({
      show: nextProps.show,
      filters: nextProps.filters,
      filterName: nextProps.userFilter ? nextProps.userFilter.name : ""
    });
  }

  handleIndustriesChange = (industries) => {
    let newFilters = _.merge({}, this.state.filters);
    newFilters.industries = industries;
    this.setState({
      filters: newFilters
    });
  };

  handleNumericLimitChange = (name, constraint, val) => {
    let newFilters = _.merge({}, this.state.filters);
    newFilters[name] = newFilters[name] || {};
    newFilters[name][constraint] = val.toString().trim() ? val : null;
    this.setState({
      filters: newFilters
    });
  };

  handleRegExpChange = (regexp) => {
    this.setState({
      filters: _.merge({}, this.state.filters, {
        regexp: regexp
      })
    });
  };

  checkRegExp() {
    return true;
  }

  handleSaveFilter = () => {
    if (!this.checkRegExp()) {
      this.setState({
        regexpError: true
      });
      return;
    }
    if (!this.state.filterName) {
      return;
    }
    let filterName = this.state.filterName;
    this.setState({
      filterName: ""
    });
    let uf: IUserFilter = {
      name: filterName,
      id: this.props.userFilter ? this.props.userFilter.id : null,
      filters: this.props.userFilter ? this.props.userFilter.filters : ""
    };
    this.props.onChange(this.state.filters, uf);
    this.props.onHide();
  };

  handleApply = () => {
    if (this.props.userFilter && this.props.userFilter.id) {
      this.handleSaveFilter();
      return;
    }
    if (!this.checkRegExp()) {
      this.setState({
        regexpError: true
      });
      return;
    }
    this.props.onChange(this.state.filters);
    this.props.onHide();
  };

  handleFilterNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filterName: e.target.value
    });
  };

  resetFilters = () => {
    const newFilters = {industries: [], serp: {}, regexp: ""};
    ['monthly_volume', 'value', 'cpc', 'words', 'length', 'competition', 'onpage_difficulty', 'offpage_difficulty', 'seo_difficulty', 'ctr_opportunity', 'kw_potential'].forEach((n) => {
      newFilters[n] = {min: null, max: null};
    });
    ['ads', 'total_local', 'total_news', 'total_videos', 'total_fresh', 'has_snippet', 'total_images', 'total_pla', 'has_knowledge_graphs'].forEach((p) => {
      newFilters.serp[p] = "maybe";
    });
    this.props.onChange(newFilters);
    this.props.onHide();
  };

  getSERPFilter = (property: string) => {
    if (!('serp' in this.state.filters) || !(property in this.state.filters.serp)) return "maybe";
    return this.state.filters.serp[property];
  };

  getRegExp() {
    return this.state.filters.regexp || "";
  }

  handleSERPChange = (property: string, value: any) => {
    let newFilters = _.merge({}, this.state.filters);
    newFilters.serp = newFilters.serp || {};
    newFilters.serp[property] = value;
    this.setState({
      filters: newFilters
    });
  };

  renderNumericFilter({
                        name,
                        label,
                        otherProps,
                        condition
                      }: NumericFilterDef) {
    if (condition && !condition(this.props)) {
      return null;
    }
    let labelString = (typeof label === "string") ? label : label(this.props);
    return <NumericFilter
      key={name}
      filters={this.state.filters}
      handleNumericLimitChange={this.handleNumericLimitChange}
      label={labelString}
      name={name}
      {...otherProps}
    />;
  }

  renderSERPRadioFilter(property: string, label: string) {
    return (
      <SerpRadioFilter
        key={property}
        property={property}
        label={label}
        getSERPFilter={this.getSERPFilter}
        handleSERPChange={this.handleSERPChange}
      />
    );
  }

  render(): JSX.Element | false | null {
    return (
        <div className="search-result-filters-wrapper card border-0" id="search-result-filters-wrapper">
          <div className="card-header d-flex align-items-center">
            <h5 className="mb-0 font-weight-normal">Advanced Filter</h5>
            <button type="button" className="close toggle-filter ml-auto" onClick={() => this.props.onHide()}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="card-body">
            <div className="container-fluid py-0">
              <div className="row">
                {NUMERIC_FILTERS.map((item) => this.renderNumericFilter(item))}
              </div>
              <RegExpFilter
                  regexpError={this.state.regexpError}
                  getRegExp={() => this.getRegExp()}
                  handleRegExpChange={this.handleRegExpChange}
              />

              <IndustryFilter
                  industries={this.props.industries}
                  selectedIndustries={this.state.filters.industries}
                  handleIndustriesChange={this.handleIndustriesChange}
              />


              {this.props.allowSERPFilter && <div className="row">
                {SERP_FILTERS.map(({property, label}) =>
                    this.renderSERPRadioFilter(property, label))}
              </div>}
            </div>
          </div>
          <div className="card-footer">
            <div className="row gap-y">
              <div className="col-md-6">
                <SaveFilterForm
                    filterName={this.state.filterName}
                    userFilter={this.props.userFilter}
                    handleFilterNameChange={this.handleFilterNameChange}
                    handleSaveFilter={this.handleSaveFilter}
                />
              </div>
              <div className="col-md-6 text-right">
                <button className="btn btn-secondary btn-rounded mr-3" onClick={this.resetFilters}>Reset</button>
                <button className="btn btn-primary btn-rounded" onClick={this.handleApply}>Apply</button>
              </div>
            </div>
          </div>
        </div>

    );
  }

}

export default FilterModal;
