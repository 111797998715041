import * as $ from "jquery";
import * as Promise from "bluebird";

export interface IResponse {

}

export function addNegativeKeywords(keywords: string[]): Promise<IResponse> {
  return new Promise<IResponse>((resolve, reject) => {
    $.ajax({
      url: `/negative-keywords`,
      method: "POST",
      data: {
        keywords: JSON.stringify(keywords),
        __csrf_token: $("meta[name='csrf-token']").attr('content')
      },
      success: function (response: {error: boolean, data: IResponse}) {
        resolve(response.data);
      },
      error: function (jqXHR: {responseJSON: {message: string}}) {
        reject(new Error(`/negative-keywords returned an error : ${jqXHR.responseJSON.message}`));
      }
    } as JQueryAjaxSettings)
  });
}

export default addNegativeKeywords;