import * as React from 'react';
import {Badge, Tooltip} from "react-bootstrap";
import {KeywordType, KeywordTypeCodes, KeywordTypeNames} from "../../lib/keyword/keyword-types";
import {IKeywordResult} from "../../lib/api/keyword-keg/bulk-upload/get";
import {KkOverlayTrigger} from "../common/kk-overlay-trigger";

export interface IKeywordTypeProps {
  keyword: IKeywordResult,
  id: string
}

interface IKeywordTypeState {
  types: KeywordType[],
}

const TypeTags: {[key: string]: {name: string, flag: string}} = {
  "alphabetical": {
    name: KeywordTypeNames[KeywordType.Alphabetical],
    flag: "AL"
  },
  "questions": {
    name: KeywordTypeNames[KeywordType.Questions],
    flag: "QU"
  },
  "buyer-intent": {
    name: KeywordTypeNames[KeywordType.BuyerIntent],
    flag: "BI"
  },
  "product-info": {
    name: KeywordTypeNames[KeywordType.ProductInfo],
    flag: "PI"
  },
  "prepositions": {
    name: KeywordTypeNames[KeywordType.Prepositions],
    flag: "PR"
  },
  "comparisons": {
    name: KeywordTypeNames[KeywordType.Comparisons],
    flag: "CO"
  },
};

export class KeywordTypeTags extends React.Component<IKeywordTypeProps, IKeywordTypeState> {

  props: IKeywordTypeProps;
  state: IKeywordTypeState = {
    types: []
  };

  constructor(props: IKeywordTypeProps) {
    super(props);
    let types: KeywordType[] = [];
    let kw = this.props.keyword;
    types = this.checkType(kw.is_question, KeywordType.Questions, types);
    types = this.checkType(kw.is_buyer_intent, KeywordType.BuyerIntent, types);
    types = this.checkType(kw.is_product_info, KeywordType.ProductInfo, types);
    types = this.checkType(kw.is_preposition, KeywordType.Prepositions, types);
    types = this.checkType(kw.is_comparison, KeywordType.Comparisons, types);
    this.state = {
      types: types
    };
  }

  private checkType(field: boolean, aType: KeywordType, types: KeywordType[]) {
    if (field) {
      types.push(aType);
    }
    return types;
  }

  getTypeFlag() {
    return _.map(this.state.types, (t, i) => {
      const code = KeywordTypeCodes[t];
      const name = TypeTags[code].name;
      const flag = TypeTags[code].flag;
      const rand = Math.random();
      const tooltip = <Tooltip id={`type-tooltip-${this.props.id}-${code}`}>{name}</Tooltip>;
      return <KkOverlayTrigger placement="top" overlay={tooltip} key={`type-tooltip-${this.props.id}-${code}-${i}`}>
        <span className={"rounded-pill badge badge-success"}>{flag}</span>
      </KkOverlayTrigger>
    });
  }

  render() {
    return <span>
      {this.getTypeFlag()}
    </span>;
  }

}

export default KeywordTypeTags;
