import {Country} from "../../../countries";

const SupportedCountries = [
    Country.UnitedStates,
    Country.Argentina,
    Country.Australia,
    Country.Austria,
    Country.Belgium,
    Country.Canada,
    Country.France,
    Country.Germany,
    Country.HongKong,
    Country.India,
    Country.Indonesia,
    Country.Italy,
    Country.Japan,
    Country.SouthKorea,
    Country.Malaysia,
    Country.Mexico,
    Country.Netherlands,
    Country.NewZealand,
    Country.Norway,
    Country.Poland,
    Country.Philippines,
    Country.Russia,
    Country.SaudiArabia,
    Country.SouthAfrica,
    Country.Spain,
    Country.Sweden,
    Country.Switzerland,
    Country.Taiwan,
    Country.Turkey,
    Country.UnitedKingdom
];

export default SupportedCountries