import * as $ from "jquery";
import * as Promise from "bluebird";

export interface IResponse {
    id: number
    download_path: string
    status: "running" | "complete"
}

export function getExport(id: number): Promise<IResponse>  {
    return new Promise<IResponse>((resolve, reject) => {
        $.ajax({
            url: `/export/${id}`,
            method: "GET",
            success: function (response: {error: boolean, data: IResponse}) {
                resolve(response.data);
            },
            error: function (jqXHR: {responseJSON: {message: string}}) {
                reject(new Error(`/export/{id} returned an error : ${jqXHR.responseJSON.message}`));
            }
        } as JQueryAjaxSettings)
    });
}

export default getExport;