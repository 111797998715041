import * as $ from "jquery";
import * as Promise from "bluebird";

export interface IResponse {
    id: number
}

export function newList(name: string): Promise<IResponse>  {
    return new Promise<IResponse>((resolve, reject) => {
        $.ajax({
            url: "/lists/new",
            method: "POST",
            data: {
                name: name,
                __csrf_token: $("meta[name='csrf-token']").attr('content')
            },
            success: function (response: {error: boolean, data: IResponse}) {
                resolve(response.data);
            },
            error: function (jqXHR: {responseJSON: {message: string}}) {
                reject(new Error(jqXHR.responseJSON.message));
            }
        } as JQueryAjaxSettings);
    });
}

export default newList;