import * as React from "react";
import {getMonthShortName} from "../lib/util/time";
import {number_format} from "../lib/util/number-format";
import {SparklineBar} from "./sparkline-bar";
import * as moment from 'moment';

export interface IProps {
    data: number[],
    reverse: boolean,
    width: number,
    height: number,
    gap: number,
    color: string
}

export interface IState {

}

export class Sparkline extends React.Component<IProps, IState> {

    getTooltip(i: number, num: number) {
        return `${moment().subtract((i + 1),'month').format("MMM YYYY")} - ${number_format(num)}`;

        // const today = new Date();
        // const target = new Date(today.setMonth(today.getMonth() - (i + 1)));
        // return `${getMonthShortName(target.getMonth())} ${target.getFullYear()} - ${number_format(num)}`;
    }

    render() {
        var data = this.props.data.slice();
        let sparkBars = data.length ? data.map((num, i) => {
            return <SparklineBar
                key={i}
                num={num}
                height={this.props.height}
                data={this.props.data}
                tooltip={this.getTooltip(i, num)}
            />
        }): [<span key={"solo"}/>];

        if (!this.props.reverse) {
            sparkBars.reverse();
        }
        return (
            <div className={"sparkline"}>
                <div className="sparkline-container">
                    {sparkBars}
                </div>
            </div>
        );
    }
}

export default Sparkline;
