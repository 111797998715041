import * as React from "react";
import * as Select2 from 'react-select2-wrapper';
import * as $ from "jquery";

$.fn.select2.amd.require(['select2/selection/search'], function (Search) {
    Search.prototype.searchRemoveChoice = function (decorated, item) {
        this.trigger('unselect', {
            data: item
        });
        this.$search.val('');
        this.handleSearch();
    };
}, null, true);

export class MySelect2 extends Select2 {
};