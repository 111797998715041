import * as $ from "jquery";
import * as Promise from "bluebird";

import Country from "../../countries";
import Language from "../../languages";
import API from "../../suggest/fetchers";
import ResultType from "../../../keyword/keyword-types";

export type SERPFilter = "yes" | "no" | "maybe";
type RangeFilter = {
    min?: number | null,
    max?: number | null
};

export interface Filters {
    sort?: {
        column?: string,
        order?: "asc" | "desc"
    },
    countries?: Country[],
    languages?: Language[],
    apis?: API[],
    resultTypes?: ResultType[],
    monthly_volume?: RangeFilter,
    value?: RangeFilter,
    cpc?: RangeFilter,
    words?: RangeFilter,
    length?: RangeFilter,
    competition?: RangeFilter,
    industries?: number[],
    searchSpeed?: "slow" | "medium" | "fast",
    serp?: {
        ads?: SERPFilter,
        total_local?: SERPFilter,
        total_news?: SERPFilter,
        total_fresh?: SERPFilter,
        has_snippet?: SERPFilter,
        total_videos?: SERPFilter,
        total_images?: SERPFilter,
        total_pla?: SERPFilter,
        has_knowledge_graphs?: SERPFilter,
        [key: string]: any
    },
    onpage_difficulty?: RangeFilter,
    offpage_difficulty?: RangeFilter,
    seo_difficulty?: RangeFilter,
    ctr_opportunity?: RangeFilter,
    kw_potential?: RangeFilter,
    regexp?: string
}

export const AdvancedMetricsFilters = [
    'serp',
    'onpage_difficulty',
    'offpage_difficulty',
    'seo_difficulty',
    'ctr_opportunity',
    'kw_potential',
];

export interface IUserFilter {
    id: number,
    name: string,
    filters: string
}

export interface IUserFiltersResponse {
    filters: IUserFilter[],
    defaultFilter: IUserFilter
}

export interface ISaveUserFilterResponse {
    filter: IUserFilter,
    filters: IUserFilter[],
}

export interface IFilters extends Filters {
}

export function getFilters(): Promise<Filters> {
    return new Promise<Filters>((resolve, reject) => {
        $.ajax({
            url: "/search/filters",
            method: "GET",
            success: function (response: { error: boolean, data: { filters: Filters } }) {
                resolve(response.data.filters);
            },
            error: function (jqXHR: { responseJSON: { message: string } }) {
                reject(new Error(`GET /search/filters returned an error : ${jqXHR.responseJSON.message}`));
            }
        } as JQueryAjaxSettings);
    })
}

export function updateFilters(filters: Filters): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        $.ajax({
            url: "/search/filters",
            method: "POST",
            data: {
                filters: JSON.stringify(filters),
                __csrf_token: $('meta[name="csrf-token"]').attr('content')
            },
            success: function (response: { error: boolean }) {
                resolve();
            },
            error: function (jqXHR: { responseJSON: { message: string } }) {
                reject(new Error(`POST /search/filters returned an error : ${jqXHR.responseJSON.message}`));
            }
        } as JQueryAjaxSettings);
    })
}

export function saveUserFilter(name: string, filterId: number, filters: Filters): Promise<ISaveUserFilterResponse> {
    return new Promise<ISaveUserFilterResponse>((resolve, reject) => {
        $.ajax({
            url: "/search/user-filters",
            method: "POST",
            data: {
                filter_name: name,
                filter_id: filterId,
                filters: JSON.stringify(filters),
                __csrf_token: $('meta[name="csrf-token"]').attr('content')
            },
            success: function (response: { error: boolean, data: ISaveUserFilterResponse }) {
                resolve(response.data);
            },
            error: function (jqXHR: { responseJSON: { message: string } }) {
                reject(new Error(`POST /search/user-filters returned an error : ${jqXHR.responseJSON.message}`));
            }
        } as JQueryAjaxSettings);
    })
}

export function clearDefaultUserFilter(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        $.ajax({
            url: "/search/user-filters/clear",
            method: "POST",
            data: {
                __csrf_token: $('meta[name="csrf-token"]').attr('content')
            },
            success: function (response: { error: boolean }) {
                resolve();
            },
            error: function (jqXHR: { responseJSON: { message: string } }) {
                reject(new Error(`POST /search/user-filters/clear returned an error : ${jqXHR.responseJSON.message}`));
            }
        } as JQueryAjaxSettings);
    })
}

export function getUserFilters(): Promise<IUserFiltersResponse> {
    return new Promise<IUserFiltersResponse>((resolve, reject) => {
        $.ajax({
            url: "/search/user-filters",
            method: "GET",
            success: function (response: { error: boolean, data: IUserFiltersResponse }) {
                resolve(response.data);
            },
            error: function (jqXHR: { responseJSON: { message: string } }) {
                reject(new Error(`GET /search/user-filters returned an error : ${jqXHR.responseJSON.message}`));
            }
        } as JQueryAjaxSettings);
    })
}


export default getFilters;
