export enum Language {
    English,
    Afrikaans,
    Albanian,
    Amharic,
    Arabic,
    Azerbaijani,
    Basque,
    Belarusian,
    Bengali,
    Bihari,
    Bosnian,
    Bulgarian,
    Catalan,
    ChineseSimplified,
    ChineseTraditional,
    Croatian,
    Czech,
    Danish,
    Dutch,
    Esperanto,
    Estonian,
    Faroese,
    Finnish,
    French,
    Frisian,
    Galician,
    Georgian,
    German,
    Greek,
    Gujarati,
    Hebrew,
    Hindi,
    Hungarian,
    Icelandic,
    Indonesian,
    Interlingua,
    Irish,
    Italian,
    Japanese,
    Javanese,
    Kannada,
    Korean,
    Latin,
    Latvian,
    Lithuanian,
    Macedonian,
    Malay,
    Malayam,
    Maltese,
    Marathi,
    Nepali,
    NorwegianNorsk,
    NorwegianNynorsk,
    Occitan,
    Persian,
    Polish,
    PortugueseBrazil,
    PortuguesePortugal,
    Punjabi,
    Romanian,
    Russian,
    ScottishGaelic,
    Serbian,
    Sinhalese,
    Slovak,
    Slovenian,
    Spanish,
    Sudanese,
    Swahili,
    Swedish,
    Tagalog,
    Tamil,
    Telugu,
    Thai,
    Tigrinya,
    Turkish,
    Ukrainian,
    Urdu,
    Uzbek,
    Vietnamese,
    Welsh,
    Xhosa,
    Zulu
}

export let Codes: {[key: number]: string} = {};
export let Names: {[key: number]: string} = {};

Codes[Language.English] = "en";
Codes[Language.Afrikaans] = "af";
Codes[Language.Albanian] = "sq";
Codes[Language.Amharic] = "sm";
Codes[Language.Arabic] = "ar";
Codes[Language.Azerbaijani] = "az";
Codes[Language.Basque] = "eu";
Codes[Language.Belarusian] = "be";
Codes[Language.Bengali] = "bn";
Codes[Language.Bihari] = "bh";
Codes[Language.Bosnian] = "bs";
Codes[Language.Bulgarian] = "bg";
Codes[Language.Catalan] = "ca";
Codes[Language.ChineseSimplified] = "CN";
Codes[Language.ChineseTraditional] = "TW";
Codes[Language.Croatian] = "hr";
Codes[Language.Czech] = "cs";
Codes[Language.Danish] = "da";
Codes[Language.Dutch] = "nl";
Codes[Language.Esperanto] = "eo";
Codes[Language.Estonian] = "et";
Codes[Language.Faroese] = "fo";
Codes[Language.Finnish] = "fi";
Codes[Language.French] = "fr";
Codes[Language.Frisian] = "fy";
Codes[Language.Galician] = "gl";
Codes[Language.Georgian] = "ka";
Codes[Language.German] = "de";
Codes[Language.Greek] = "el";
Codes[Language.Gujarati] = "gu";
Codes[Language.Hebrew] = "iw";
Codes[Language.Hindi] = "hi";
Codes[Language.Hungarian] = "hu";
Codes[Language.Icelandic] = "is";
Codes[Language.Indonesian] = "id";
Codes[Language.Interlingua] = "ia";
Codes[Language.Irish] = "ga";
Codes[Language.Italian] = "it";
Codes[Language.Japanese] = "ja";
Codes[Language.Javanese] = "jw";
Codes[Language.Kannada] = "kn";
Codes[Language.Korean] = "ko";
Codes[Language.Latin] = "la";
Codes[Language.Latvian] = "lv";
Codes[Language.Lithuanian] = "lt";
Codes[Language.Macedonian] = "mk";
Codes[Language.Malay] = "ms";
Codes[Language.Malayam] = "ml";
Codes[Language.Maltese] = "mt";
Codes[Language.Marathi] = "mr";
Codes[Language.Nepali] = "ne";
Codes[Language.NorwegianNorsk] = "no";
Codes[Language.NorwegianNynorsk] = "nn";
Codes[Language.Occitan] = "oc";
Codes[Language.Persian] = "fa";
Codes[Language.Polish] = "pl";
Codes[Language.PortugueseBrazil] = "br";
Codes[Language.PortuguesePortugal] = "pt";
Codes[Language.Punjabi] = "pa";
Codes[Language.Romanian] = "ro";
Codes[Language.Russian] = "ru";
Codes[Language.ScottishGaelic] = "gd";
Codes[Language.Serbian] = "sr";
Codes[Language.Sinhalese] = "si ";
Codes[Language.Slovak] = "sk";
Codes[Language.Slovenian] = "sl";
Codes[Language.Spanish] = "es";
Codes[Language.Sudanese] = "su";
Codes[Language.Swahili] = "sw";
Codes[Language.Swedish] = "sv";
Codes[Language.Tagalog] = "tl";
Codes[Language.Tamil] = "ta";
Codes[Language.Telugu] = "te";
Codes[Language.Thai] = "th";
Codes[Language.Tigrinya] = "ti";
Codes[Language.Turkish] = "tr";
Codes[Language.Ukrainian] = "uk";
Codes[Language.Urdu] = "ur";
Codes[Language.Uzbek] = "uz";
Codes[Language.Vietnamese] = "vi";
Codes[Language.Welsh] = "cy";
Codes[Language.Xhosa] = "xh";
Codes[Language.Zulu] = "zu";

Names[Language.English] = "English";
Names[Language.Afrikaans] = "Afrikaans";
Names[Language.Albanian] = "Albanian (Shqip)";
Names[Language.Amharic] = "Amharic";
Names[Language.Arabic] = "Arabic (العربية)";
Names[Language.Azerbaijani] = "Azerbaijani (Azərbaycanca)";
Names[Language.Basque] = "Basque (Euskal)";
Names[Language.Belarusian] = "Belarusian (Беларуская)";
Names[Language.Bengali] = "Bengali (বাঙ্গালী)";
Names[Language.Bihari] = "Bihari (বিহারী)";
Names[Language.Bosnian] = "Bosnian (Bosanski)";
Names[Language.Bulgarian] = "Bulgarian (Български)";
Names[Language.Catalan] = "Catalan (Català)";
Names[Language.ChineseSimplified] = "Chinese - Simplified (中国 - 简体)";
Names[Language.ChineseTraditional] = "Chinese - Traditional (中文 - 繁體)";
Names[Language.Croatian] = "Croatian (Hrvatski)";
Names[Language.Czech] = "Czech (Čeština)";
Names[Language.Danish] = "Danish (Dansk)";
Names[Language.Dutch] = "Dutch (Nederlands)";
Names[Language.Esperanto] = "Esperanto";
Names[Language.Estonian] = "Estonian (Eesti)";
Names[Language.Faroese] = "Faroese (Føroyskt)";
Names[Language.Finnish] = "Finnish (Suomi)";
Names[Language.French] = "French (Français)";
Names[Language.Frisian] = "Frisian";
Names[Language.Galician] = "Galician (Galego)";
Names[Language.Georgian] = "Georgian (ქართული)";
Names[Language.German] = "German (Deutsch)";
Names[Language.Greek] = "Greek (ελληνικά)";
Names[Language.Gujarati] = "Gujarati (ગુજરાતી)";
Names[Language.Hebrew] = "Hebrew (עברית)";
Names[Language.Hindi] = "Hindi (हिंदी)";
Names[Language.Hungarian] = "Hungarian (Magyar)";
Names[Language.Icelandic] = "Icelandic";
Names[Language.Indonesian] = "Indonesian (Bahasa Indonesia)";
Names[Language.Interlingua] = "Interlingua";
Names[Language.Irish] = "Irish (Gaeilge)";
Names[Language.Italian] = "Italian (Italiano)";
Names[Language.Japanese] = "Japanese (日本語)";
Names[Language.Javanese] = "Javanese (Jawa)";
Names[Language.Kannada] = "Kannada (ಕನ್ನಡ)";
Names[Language.Korean] = "Korean (한국어)";
Names[Language.Latin] = "Latin (Latine)";
Names[Language.Latvian] = "Latvian (Latviešu Valoda)";
Names[Language.Lithuanian] = "Lithuanian (Lietuvių Kalba)";
Names[Language.Macedonian] = "Macedonian (Македонски Јазик)";
Names[Language.Malay] = "Malay (Bahasa Melayu)";
Names[Language.Malayam] = "Malayam (മലയാളം)";
Names[Language.Maltese] = "Maltese (Malti)";
Names[Language.Marathi] = "Marathi (मराठी)";
Names[Language.Nepali] = "Nepali (नेपाली)";
Names[Language.NorwegianNorsk] = "Norwegian (Norsk)";
Names[Language.NorwegianNynorsk] = "Norwegian (Nynorsk)";
Names[Language.Occitan] = "Occitan (Lenga d'òc)";
Names[Language.Persian] = "Persian (فارسی)";
Names[Language.Polish] = "Polish (Polski)";
Names[Language.PortugueseBrazil] = "Portuguese - Brazil (Português - Brasil)";
Names[Language.PortuguesePortugal] = "Portuguese - Portugal (Português - Portugal)";
Names[Language.Punjabi] = "Punjabi (ਪੰਜਾਬੀ ਦੇ)";
Names[Language.Romanian] = "Romanian (Român)";
Names[Language.Russian] = "Russian (Русский)";
Names[Language.ScottishGaelic] = "Scottish Gaelic (Gàidhlig)";
Names[Language.Serbian] = "Serbian (Cрпски)";
Names[Language.Sinhalese] = "Sinhalese (සිංහල)";
Names[Language.Slovak] = "Slovak (Slovenský)";
Names[Language.Slovenian] = "Slovenian (Slovenščina)";
Names[Language.Spanish] = "Spanish (Español)";
Names[Language.Sudanese] = "Sudanese";
Names[Language.Swahili] = "Swahili (Kiswahili)";
Names[Language.Swedish] = "Swedish (Svenska)";
Names[Language.Tagalog] = "Tagalog";
Names[Language.Tamil] = "Tamil (தமிழ்)";
Names[Language.Telugu] = "Telugu";
Names[Language.Thai] = "Thai (ภาษาไทย)";
Names[Language.Tigrinya] = "Tigrinya (ትግርኛ)";
Names[Language.Turkish] = "Turkish (Türk)";
Names[Language.Ukrainian] = "Ukrainian (Українська)";
Names[Language.Urdu] = "Urdu (اُردُو‎)";
Names[Language.Uzbek] = "Uzbek (O’zbekcha)";
Names[Language.Vietnamese] = "Vietnamese (Việt)";
Names[Language.Welsh] = "Welsh (Cymraeg)";
Names[Language.Xhosa] = "Xhosa";
Names[Language.Zulu] = "Zulu";

export let CodeToLanguage: {[code: string]: Language} = {};

for (const k in Codes) {
    CodeToLanguage[Codes[k]] = Number(k);
}

export let All: Language[] = [
    Language.English
];

export default Language;