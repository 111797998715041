import * as $ from "jquery";
import * as Promise from "bluebird";
import {IKeywordsSource, KeywordsSourceTypeNames} from "../keywords-source";
import {IKeywordResult, IParams as IResultParams, addResultParams} from "../sf/results";

export interface IResponse {
  number_added: number,
  operation_id: number
}

export interface IOperationStatusResponse {
  number_added: number,
  id: number,
  status: "running" | "complete" | "failed",
  hard_limit: number,
  hard_limit_reached: boolean,
  total_keywords: number,
  total_processed: number
}

export function addKeywords(id: number,
  keywords: string[],
  source: IKeywordsSource,
  all: boolean,
  resultParams?: Partial<IResultParams>
): Promise<IResponse> {
  let sourceName = KeywordsSourceTypeNames[source.sourceType];
  let data: {[name: string]: any} = {
    keywords: JSON.stringify(keywords),
    __csrf_token: $("meta[name='csrf-token']").attr('content'),
    source_name: sourceName,
    source_id: source.id,
    all: all
  };
  if (resultParams) {
    data = addResultParams(data, resultParams);
  }
  return new Promise<IResponse>((resolve, reject) => {
    $.ajax({
      url: `/lists/${id}/add-keywords`,
      method: "POST",
      data: data,
      success: function (response: {error: boolean, data: IResponse}) {
        resolve(response.data);
      },
      error: function (jqXHR: {responseJSON: {message: string}}) {
        let e = new Error(jqXHR.responseJSON.message);
        reject(e);
      }
    } as JQueryAjaxSettings);
  });
}

export function checkOperationStatus(id: number
): Promise<IOperationStatusResponse> {
  
  return new Promise<IOperationStatusResponse>((resolve, reject) => {
    $.ajax({
      url: `/lists/add-keywords/status/${id}`,
      method: "GET",
      success: function (response: {error: boolean, data: IOperationStatusResponse}) {
        resolve(response.data);
      },
      error: function (jqXHR: {responseJSON: {message: string}}) {
        let e = new Error(jqXHR.responseJSON.message);
        reject(e);
      }
    } as JQueryAjaxSettings);
  });
}

export default addKeywords;