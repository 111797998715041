import * as Promise from "bluebird";
import * as $ from "jquery";

import AbstractSuggestAPIFetcher from "./abstract";
import {Country, Codes as CountryCodes} from "../../countries";
import {Language, Codes as LanguageCodes} from "../../languages";
import SupportedCountries from "./ask/supported-countries";
import SupportedLanguages from "./ask/supported-languages";

interface GlobalOptions {

}

export default class AskSuggestAPIFetcher extends AbstractSuggestAPIFetcher<GlobalOptions> {

    public getAPIName(): string {
        return "Ask API";
    }

    protected getSupportedCountries(): Country[] {
        return SupportedCountries;
    }

    protected getSupportedLanguages(): Language[] {
        return SupportedLanguages;
    }

    public requiresCountry() {
        return false;
    }

    public requiresLanguage() {
        return false;
    }

    /**
     * Get the URL to be used in the AJAX call
     * @param {string} keyword
     * @returns {string}
     */
    protected getURL(keyword: string): string {
        return `https://ss.ask.com/query?sstype=prefix&q=${encodeURIComponent(keyword)}`;
    }

    /**
     * Filter the response keywords
     * @param {string[]} keywords
     * @returns {string[]}
     */
    protected filter(keywords: string[]): string[] {
        //Ask API returns HTML
        return keywords.map(html => $(`<div>${html}</div>`).text());
    }

    public fetch(keyword: string): Promise<string[]> {
        const varName = "ask" + Date.now() + Math.floor(Math.random()*1000);
        return new Promise<string[]>((resolve, reject) => {
            $.ajax({
                url: this.getURL(keyword),
                method: "GET",
                dataType: "jsonp",
                jsonp: "fn",
                jsonpCallback: varName,
                timeout: 5000,
                success: (response) => {
                    resolve(this.filter(response[1]));
                },
                error: () => {
                    reject(new Error(this.getErrorMessage()));
                }
            });
        });
    }

}