import * as $ from "jquery";
import * as Promise from "bluebird";


export interface IDeleteResultsParams{
  id: number
  resultIds: number[],
  all: boolean
}

export class ResultsDeleter{

  constructor(protected params: IDeleteResultsParams){
  }

  protected getUrl(){
    return `/sf/results/${this.params.id}/delete`;
  }

  deleteResults(): Promise<{}> {
    let params = this.params;

    let url = this.getUrl();
    return new Promise((resolve, reject) => {
      $.ajax({
        url: url,
        method: "POST",
        data: {
          resultIds: JSON.stringify(params.resultIds),
          all: params.all ? 1 : 0,
          __csrf_token: $('meta[name="csrf-token"]').attr('content')
        },
        success: (response: {
          error: boolean,
          code?: string,
          message?: string
        }) => {
          if (!response.error) {
            resolve();
          } else {
            reject(new Error(`${url} returned an error : ${response.code || response.message}`));
          }
        },
        error: (jqXHR: {responseJSON: {message: string}}) => {
          reject(new Error(`${url} returned an error : ${jqXHR.responseJSON.message}`));
        }
      } as JQueryAjaxSettings);
    });
  }
}

export function deleteResults(params: IDeleteResultsParams): Promise<{}> {
  return (new ResultsDeleter(params)).deleteResults();
}

export default deleteResults;
