export const toastSuccess = (msg: string, hideAfter?: number) => {
    toast(msg, 'success', hideAfter);
};

export const toastError = (msg: string, hideAfter?: number) => {
    toast(msg, 'danger', hideAfter);
};

const toast = (msg: string, icon: string, hideAfter?: number) => {
    $.toast({
        text: msg,
        icon: icon,
        position: 'bottom-right',
        // showHideTransition: 'slide'
        hideAfter
    });
};