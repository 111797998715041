import toUpper = require("lodash/toUpper");
const METRICS_TO_REPLACE = [
  "monthly_volume",
  "cpc",
  "trend",
  "value",
  "competition"
];

export interface IColumn {
  id: string,
  metric_id: string,
  name: string,
  description: string
}

interface USerCol {
  id: string,
  name: string,
  description: string
}
export function determineColumnsToUse(
  userColumns: USerCol[],
  country: string): IColumn[] {
  return userColumns.map((column) => {
    let newColumn: IColumn = {
      id: column.id,
      metric_id: column.id,
      name: column.name,
      description: column.description
    };
    if (METRICS_TO_REPLACE.indexOf(column.id) !== -1) {
      if (country == "all" || country == "global") {
        //If country is all, use global metrics, and add (World) to name
        newColumn.name += " (World)";
      } else {
        //If country is not all, use country specific metrics, and add (COUNTRY_CODE) to name
        newColumn.metric_id += "_" + country;
        newColumn.name += " (" + toUpper(country) + ")";
      }
    }
    return newColumn;
  });
}