import * as React from 'react';
import {FormEvent} from 'react';
import {IConfig} from "../../lib/api/keyword-keg/config";
import {getTagifyValue} from "../../lib/random";

export interface IKeywordInputProps {
    strictMode: boolean,
    error: boolean | string,
    onChange: (value: string, thenSearch?: boolean) => void,
    onStrictModeChange: (checked: boolean) => void,
    isFreeUser: () => boolean,
    config: IConfig,
    showQuestionMarkAddOn?: boolean
}

interface IKeywordInputState {
    tags: any[],
    tag: string,
    editingTagKey: number
}

export class KeywordInput extends React.Component<IKeywordInputProps, IKeywordInputState> {

    static defaultProps = {
        error: false,
        showQuestionMarkAddOn: false,
        onChange: function () {
        },
        onSearch: function () {
        }
    };

    private lastKeyCode: number;

    state: IKeywordInputState = {
        tags: [],
        tag: '',
        editingTagKey: null
    };

    props: IKeywordInputProps;
    private keywordInputComponent: any;
    private tagify: Tagify;

    componentDidMount(): void {
        this.tagify = new Tagify(this.keywordInputComponent, {
            "delimiters": /[,\n\t;]/
        });

        this.tagify
            .on('add', this.updateTags)
            .on('remove', this.updateTags)
            .on('edit:updated', this.updateTags)
            .on('keydown', this.handleKeydown)
            // .on('edit:input edit:updated edit:start edit:keydown', e => console.log(e.type, e.detail))

        //Hack allows enter-to-search with older version of tagify
        $('span[contenteditable]').on('keydown', (e) => {
            this.lastKeyCode = e.keyCode;
        });
    }

    setTagInputValue(query: string){
        this.tagify.loadOriginalValues(query.split(new RegExp("[,\r\n\t;]")))
    }

    handleKeydown = (event) => {
        // console.log('handleKeydown', {event})
        /*try {
            this.lastKeyCode = event.detail.originalEvent.keyCode;
        } catch (e) {
            console.error(e)
        }*/
    };

    handleStrictModeChange = (e: FormEvent<HTMLInputElement>) => {
        this.props.onStrictModeChange(e.currentTarget.checked);
    };

    private updateTags = (e?) => {
        let entered = this.lastKeyCode === 13;
        this.setState({
            tags: getTagifyValue(this.tagify)
        }, () => {
            if (entered) {
                this.addTagsFromInput(true);
                this.lastKeyCode = null;
            }
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        const tagify = this.tagify;

        if(!tagify){
            return true;
        }

        // check if value has changed
        if (nextProps.value && nextProps.value.join() !== this.props.value.join()) {
            tagify.loadOriginalValues(nextProps.value)
            // this.tagify.addTags(nextProps.value, true, true)
        }

        // // do not allow react to re-render since the component is modifying its own HTML
        // return this.props.strictMode !== nextProps.strictMode;

        return true;
    }

    _handleKeywordInputRef = component => {
        this.keywordInputComponent = component;
    };

    addTagsFromInput(thenSearch?: boolean) {
        setTimeout(() => {
            let searchString = getTagifyValue(this.tagify).join(',');
            this.props.onChange(searchString, thenSearch);
            if (thenSearch) {
                $('#find-keywords-tutorial').addClass('d-none');
            }
        });
    }

    handleClearAllKeywords = (e) => {
        e.preventDefault();
        this.setTagInputValue("");
        this.updateTags(e);
    };

    render() {
        return (
            <div className="row gap-y align-items-center">
                <div className="col-md-10">
                    <input
                        ref={this._handleKeywordInputRef}
                        id="keywords"
                        type="text"
                        className="tags-control"
                        placeholder="For multi-seed keyword search, enter a keyword and press Tab to enter more keywords"/>
                    {this.state.tags.length > 0 && <div>
                        <a onClick={this.handleClearAllKeywords} href={"#"}>
                            Clear all keywords
                        </a>
                    </div>}
                </div>
                <div className="col-md-2 d-md-flex">
                    <div className="checkbox checkbox-primary ml-md-auto">
                        <input type="checkbox" id="strict-mode"
                               checked={this.props.strictMode}
                               onChange={this.handleStrictModeChange}
                        />
                        <label htmlFor="strict-mode" className="control-label">
                            <span className="mr-2">Strict Mode</span>
                            <span
                                data-toggle="popover"
                                data-content="When you enable this, only those results that exactly match the keywords you entered are shown to you">
                              <i className="far fa-question-circle text-info"/>
                            </span>
                        </label>
                    </div>
                </div>
            </div>
        );
    }
}

export default KeywordInput;
