import {Country} from "../../../countries";

let MarketIds: {[key: number]: number} = {};

MarketIds[Country.UnitedStates] = 1;
MarketIds[Country.Australia] = 111172;
MarketIds[Country.Brazil] = 526970;
MarketIds[Country.Canada] = 7;
MarketIds[Country.France] = 5;
MarketIds[Country.Germany] = 4;
MarketIds[Country.India] = 44571;
MarketIds[Country.Italy] = 35691;
MarketIds[Country.Japan] = 6;
MarketIds[Country.Mexico] = 771770;
MarketIds[Country.Netherlands] = 328451;
MarketIds[Country.Spain] = 44551;
MarketIds[Country.UnitedKingdom] = 3;

export default MarketIds;