import * as React from "react";
import {Modal} from "react-bootstrap";
import {getAllLists} from "../../lib/api/keyword-keg/list/all";
import {newList} from "../../lib/api/keyword-keg/list/new";
import {addKeywords, checkOperationStatus} from "../../lib/api/keyword-keg/list/add-keywords";
import {IKeywordResult, IParams as IResultParams} from "../../lib/api/keyword-keg/sf/results";
import {IKeywordsSource} from "../../lib/api/keyword-keg/keywords-source";
import * as _ from "lodash";
import 'react-select/dist/react-select.css';
import {toastError, toastSuccess} from "../../lib/util/toast";
import {MySelect2 as Select2} from "../../lib/my-select-2";

export interface IAddToListModalProps {
    show: boolean,
    keywords: IKeywordResult[],
    onHide: () => void,
    keywordsSource: IKeywordsSource,
    addAll: boolean,
    getResultParams: () => Partial<IResultParams>
}

interface IAddToListModalState {
    lists?: {
        id: number,
        name: string
    }[],
    select2Options: {
        id: number,
        text: string
    }[],
    selected?: number,
    newListInputValue?: string,
    addingNewList: boolean,
    submitted: boolean,
    limitErrorShown: boolean
}

export class AddToListModal extends React.Component<IAddToListModalProps, IAddToListModalState> {

    state: IAddToListModalState = {
        lists: [],
        select2Options: [],
        selected: null,
        newListInputValue: "",
        addingNewList: false,
        submitted: false,
        limitErrorShown: false
    };

    componentDidMount() {
        this.loadLists();
    }

    componentDidUpdate(prevProps: Readonly<IAddToListModalProps>, prevState: Readonly<IAddToListModalState>) {
        if (!_.isEqual(prevState.lists, this.state.lists)) {
            this.setState({
                select2Options: this.state.lists.map((list, i) => {
                    return {
                        id: list.id,
                        text: list.name
                    };
                })
            });
        }
    }

    componentWillReceiveProps(nextProps: IAddToListModalProps) {
        if (!this.props.show && nextProps.show) {
            this.loadLists();
        }
    }

    loadLists() {
        getAllLists().then((response) => {
            this.setState({
                lists: response.lists,
                selected: response.last_list_added_to
            });
        }).catch(() => {
            //User not logged in
        });
    }

    handleNewListInputKeyPress = (e: React.KeyboardEvent<any>) => {
        if (e.key == 'Enter') {
            this.handleNewList();
        }
    };

    handleNewListInputChange = (e: { target: HTMLInputElement & EventTarget }) => {
        this.setState({
            newListInputValue: e.target.value
        });
    };

    handleNewList = () => {
        if (!this.state.newListInputValue.trim()) return;
        newList(this.state.newListInputValue).then((response) => {
            this.setState({
                lists: this.state.lists.concat([{
                    id: response.id,
                    name: this.state.newListInputValue
                }]),
                selected: response.id,
                newListInputValue: ""
            });
        }).catch((reason) => {
            toastError(reason);
        });
    };

    handleSubmit = () => {
        if (this.state.selected === null) return;
        this.setState({
            submitted: true,
            limitErrorShown: false
        });
        let keywords = _.map(this.props.keywords, (k) => k.search_result);
        addKeywords(
            this.state.selected,
            keywords,
            this.props.keywordsSource,
            this.props.addAll,
            this.props.getResultParams()
        ).then((data) => {
            this.waitForBackgroundAddToListOperation(data.operation_id);
        }).catch((reason: string) => {
            toastError(reason);
        });
    };

    waitForBackgroundAddToListOperation(operationId: number) {
        checkOperationStatus(operationId).then((data) => {
            if (data.hard_limit_reached && !this.state.limitErrorShown) {
                toastError(`Sorry, due to technical reasons, a list cannot have more than ${data.hard_limit} elements. As a result some elements may not be added to the list.`,
                    15000);
                this.setState({
                    limitErrorShown: true
                });
            }
            if (data.status === 'complete') {
                this.onAfterAddingToList(data);
            } else if (data.status === 'running') {
                setTimeout(() => {
                    this.waitForBackgroundAddToListOperation(operationId)
                }, 500);
            }
        }).catch((reason: string) => {
            toastError(reason);
            this.props.onHide();
        });
    }

    onAfterAddingToList(data: { number_added: number }) {
        this.setState({
            submitted: false,
            limitErrorShown: false
        }, () => {
            let msg = ` to list ${this.state.lists.filter((l) => l.id == this.state.selected)[0].name}`;
            if (data.number_added == 0) {
                msg = `No keywords were added` + msg;
            } else if (data.number_added == 1) {
                msg = `Successfully added 1 keyword` + msg;
            } else {
                msg = `Successfully added ${data.number_added} keywords` + msg;
            }
            toastSuccess(msg);
            this.props.onHide();
        });
    }

    handleListSelectChange = (selectedOption: { value: number, label: string }) => {
        this.setState({selected: selectedOption ? selectedOption.value : null});
    };

    handleListSelectChangeV2 = (e) => {
        if(e.target.value && e.target.value != this.state.selected){
            console.log(e.target.value, this.state.selected);
            this.setState({
                selected: e.target.value
            });
        }
    };

    renderNewListInput() {
        return (
            <div className="form-group">
                <label className="control-label">New list name</label>
                <div className="input-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter name for a new list"
                        value={this.state.newListInputValue}
                        onChange={this.handleNewListInputChange}
                        onKeyPress={this.handleNewListInputKeyPress}
                    />
                    <div className="input-group-append">
                        <button
                            className="btn btn-secondary"
                            type="button"
                            onClick={this.handleNewList}
                        >Create
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        console.log("Render add selected to list modal.");
        return <Modal show={this.props.show} onHide={this.props.onHide} enforceFocus={false}>
            <Modal.Header closeButton>
                <h5 className="modal-title">Select an existing list or create a new one</h5>
                <button type="button" className="close" onClick={this.props.onHide}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <div className="modal-body py-5">
                <div className="form-group">
                    <label className="control-label">Select Existing List</label>
                    <Select2
                        id={'add-selected-to-list-modal-select-existing-list'}
                        multiple={false}
                        data={this.state.select2Options}
                        options={
                            {
                                placeholder: 'Select...',
                            }
                        }
                        className="form-control select2 add-to-list-modal-existing-list-select"
                        onChange={this.handleListSelectChangeV2}
                        value={this.state.selected}
                    />
                </div>
                {this.renderNewListInput()}
            </div>
            <div className="modal-actions has-multiple">
                <button type="button" className="btn btn-secondary" onClick={this.props.onHide}>Cancel</button>
                <button type="button" className="btn btn-primary"
                        disabled={this.state.selected === null || this.state.submitted}
                        onClick={this.handleSubmit}>Add to List
                </button>
            </div>
        </Modal>
    }

}

export default AddToListModal;