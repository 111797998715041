/*
 * Source: https://stackoverflow.com/a/29018745/2742117
 */
export function binarySearch<X>(ar: Array<X>, el: X, compare_fn: (a: X, b: X) => number): number {
  var m = 0;
  var n = ar.length - 1;
  while (m <= n) {
    var k = (n + m) >> 1;
    var cmp = compare_fn(el, ar[k]);
    if (cmp > 0) {
      m = k + 1;
    } else if (cmp < 0) {
      n = k - 1;
    } else {
      return k;
    }
  }
  return -m - 1;
}