import * as React from 'react';

import SERPMetrics from "../search-table/serp-metrics";
import {IKeywordResult, IColumn}
  from "../../lib/api/keyword-keg/sf/results";
import number_format from "../../lib/util/number-format";
import {IConfig} from "../../lib/api/keyword-keg/config";
import {IKeywordResult as IBulkUploadKeywordResult} from "../../lib/api/keyword-keg/bulk-upload/get";
import Sparkline from "../sparkline";
import {CopyIcon} from "./copy-icon";
import {KeywordTypeTags} from "./keyword-type-tags";
import {KeywordSource} from "./keyword-source";

const PRO_VALUES = ['onpage_difficulty', 'offpage_difficulty', 'seo_difficulty', 'ctr_opportunity', 'kw_potential'];

interface IProps {
  blur: boolean,
  result: IKeywordResult,
  column: IColumn,
  currencySymbol: string,
  showDetails: (keyword: IKeywordResult) => void,
  config: IConfig,
  hasDeducedTypes: boolean,
}

interface IState {
}

export class DataTableCell extends React.Component<IProps, IState> {

  props: IProps;
  state: IState = {};

  formatCell(column: { id: string, name: string }, value: any, result: any, onDataClick: () => void) {

    if (!_.isUndefined(value) && (/^cpc(.*)/.test(column.id))) {
      return <span onClick={onDataClick}>
        {this.props.currencySymbol + Number(value).toFixed(2)}
      </span>;
    }

    if (column.id.match(/^value(.*)$/)) {
      return <span onClick={onDataClick}>
        {this.props.currencySymbol + number_format(value)}
      </span>;
    }

    if (column.id.match(/^monthly_volume(.*)/)) {
      return <span onClick={onDataClick}>{number_format(value)}</span>;
    }

    if (column.id.match(/trend(.*)$/)) {
      return <span onClick={onDataClick}>{this.formatTrend(value)}</span>;
    }

    if (column.id == "search_result" || column.id == "keyword") {
      return this.formatSearchResult(value, result, onDataClick);
    }

    if (PRO_VALUES.indexOf(column.id) !== -1) {
      const pricingLink = <a
                             title="Upgrade to see this data" href="/pricing" target="_blank">
        <i className="fas fa-shopping-cart"/>
      </a>;
      if ((column.id == "ctr_opportunity") && !this.props.config.ui.permissions.ctr_opportunity) {
        return pricingLink;
      } else if ((['onpage_difficulty', 'offpage_difficulty', 'seo_difficulty'].indexOf(column.id) !== -1) && !this.props.config.ui.permissions.seo_difficulty) {
        return pricingLink;
      } else if ((column.id == "kw_potential") && !this.props.config.ui.permissions.kw_potential) {
        return pricingLink;
      }
      if (value == 0) {
        if(column.id == 'kw_potential' && result.seo_difficulty > 0){
          return <span onClick={onDataClick}>0</span>;
        } else {
          return <span onClick={onDataClick}>-</span>;
        }
      } else {
        if (_.indexOf(
          ['onpage_difficulty', 'offpage_difficulty', 'seo_difficulty'],
          column.id) != -1) {
          let labelClassAppend = 'bg-success';
          if (value > 70) {
            labelClassAppend = 'bg-danger';
          } else if (value > 50) {
            labelClassAppend = 'bg-warning';
          }
          return <span
            className={"rounded-pill text-contrast indicator " + labelClassAppend}
            onClick={onDataClick}
          >{value}</span>;
        } else {
          return <span onClick={onDataClick}>{value}</span>;
        }
      }
    }
    return <span onClick={onDataClick}>{value}</span>;
  }

  formatTrend(cell: string) {
    let data = cell ? cell.split("|").map((d) => Number(d)) : [];
    return <Sparkline data={(data)} reverse={this.props.config.ui.trendDirection === "desc"} width={100} height={30}
                      gap={2} color="#d36a5f"/>;
  };

  formatSearchResult(value: string, result: IKeywordResult, onDataClick: () => void){
    return (
        <div>
          <p className="m-0" onClick={onDataClick}>{this.props.result.search_result}</p>
          <div className="d-flex align-items-center">
            <CopyIcon keyword={value}/>
            <SERPMetrics result={result}/>
            {this.formatKeywordSource(result)}
          </div>
        </div>
    );
  }

  formatKeywordSource(result: IKeywordResult) {
    if (this.props.hasDeducedTypes) {
      let kw: IBulkUploadKeywordResult = result as IBulkUploadKeywordResult;
      return <KeywordTypeTags id={`result-${result.result_id}`} keyword={kw}/>
    }
    return <KeywordSource id={`result-${result.result_id}`} source={result}/>
  }

  shouldComponentUpdate(nextProps: IProps, nextState: IState) {
    return this.props.result.result_id !== nextProps.result.result_id;
  }

  render() {
    let column = this.props.column;
    let r = this.props.result;
    let className = `column-${column.id}`;
    if (this.props.blur) {
      className += " blurred";
    }
    const dataSpan = this.formatCell(column, r[column.id], r, () => this.props.showDetails(r));
    return <td className={className} data-title={column.name.replace(/<br([\s]+)?\/>/, '')}>
      {this.props.blur ? (
        (column.id == "search_result" ? (Math.random().toString(36) + '00000000000000000').slice(2, 10 + 2) : Array((Math.floor(Math.random() * 5))).join("1"))
      ) : dataSpan}
    </td>;
  }

}

export default DataTableCell;
