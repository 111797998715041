import {Country} from "../../../countries";

let MarketIds: {[key: number]: number} = {};

MarketIds[Country.Australia] = 15;
MarketIds[Country.Austria] = 16;
MarketIds[Country.Canada] = 2;
MarketIds[Country.France] = 71;
MarketIds[Country.Germany] = 77;
MarketIds[Country.India] = 203;
MarketIds[Country.Ireland] = 205;
MarketIds[Country.Italy] = 101;
MarketIds[Country.UnitedKingdom] = 3;
MarketIds[Country.Switzerland] = 193;
MarketIds[Country.UnitedStates] = 0;

export default MarketIds;