import * as React from "react";
import * as _ from "lodash";
import {Modal} from "react-bootstrap";
import getNegativeKeywords from "../../lib/api/keyword-keg/negative-keywords/get";
import addNegativeKeywords from "../../lib/api/keyword-keg/negative-keywords/add";
import removeNegativeKeywords from "../../lib/api/keyword-keg/negative-keywords/remove";
import Tags from "./tagify-wrapper";
import {getTagifyValue} from "../../lib/random";

export interface INegativeKeywordsModalProps {
    show: boolean,
    onHide: () => void,
    onNegativeKeywordsChange: (keywords: string[], getResults: boolean) => void
}

export interface INegativeKeywordsModalState {
    value?: string
    loaded?: boolean
    storedNegativeKeywords?: string[],

    tags: any[],
    editingTagKey: number,

    tagifySettings: any,
    tagifyProps: any
}

const baseTagifySettings = {
    placeholder: "Add a negative keyword and press tab to add more keywords",
};

export class NegativeKeywordsModal extends React.Component<INegativeKeywordsModalProps, INegativeKeywordsModalState> {

    state: INegativeKeywordsModalState = {
        value: "",
        loaded: false,
        storedNegativeKeywords: [],

        tags: [],
        editingTagKey: null,

        tagifySettings: {
            'delimiters': /[,\n\t;]/
        },
        tagifyProps: []
    };

    tagifyCallbacks: {};

    private tagsWrapper: Tags;

    constructor(props) {
        super(props);

        this.tagifyCallbacks = {
            add: this.updateTags,
            remove: this.updateTags,
            edit: this.updateTags,
            "edit:updated": this.updateTags,
            onPaste: () => {
                console.log('Tagify paste');
            }
        };
    }

    private updateTags = (e) => {
        const tagify = this.tagsWrapper.tagify;

        this.setState({
            tags: getTagifyValue(tagify)
        }, () => {
            // console.log('Update neg keywords tags', {type: e.type, tags: this.state.tags, tagify: tagify});
        });
    };

    componentDidMount() {
        getNegativeKeywords().then((response) => {
            this.setState({
                loaded: true,
                value: response.keywords.join(', '),
                storedNegativeKeywords: response.keywords,
                tags: response.keywords
            });
            this.props.onNegativeKeywordsChange(this.state.tags, true);
        }).catch(() => {
            //User not logged in
        });
    }

    save = () => {

        //Calculate keywords that need to be added/removed
        let newKeywords = this.state.tags.filter((keyword) => keyword.trim().length > 0);
        newKeywords = _.take(newKeywords, 3000);
        const oldKeywords = this.state.storedNegativeKeywords;
        const toAdd = _.difference(newKeywords, oldKeywords);
        const toRemove = _.difference(oldKeywords, newKeywords);

        //Add and remove keywords
        addNegativeKeywords(toAdd);
        removeNegativeKeywords(toRemove);

        //Update state
        this.setState({
            storedNegativeKeywords: newKeywords
        });

        this.props.onNegativeKeywordsChange(newKeywords, true);
        this.props.onHide();
    };

    reset = () => {
        removeNegativeKeywords(this.state.storedNegativeKeywords);
        this.setState({
            value: "",
            tags: [],
            storedNegativeKeywords: []
        });
        this.props.onNegativeKeywordsChange([], true);
        this.props.onHide();
    };

    render(): JSX.Element | false | null {
        const tagifySettings = this.state.tagifySettings;
        const tagifyProps = this.state.tagifyProps;
        const settings = {
            ...baseTagifySettings,
            ...tagifySettings,
            callbacks: this.tagifyCallbacks
        };
        return (
            <Modal
                show={this.props.show} onHide={this.props.onHide}
            >
                <div className="modal-content" role="document">
                    <div className="modal-header">
                        <h5 className="modal-title">Manage Negative Keywords</h5>
                        <button type="button" className="close" onClick={this.props.onHide}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body py-5">
                        <div className="form-group mb-0">
                            <Tags
                                settings={settings}
                                {...tagifyProps}
                                inputBeforeTags={true}
                                value={this.state.tags}
                                ref={t => this.tagsWrapper = t}
                            />
                        </div>
                    </div>
                    <div className="modal-actions has-multiple">
                        <button type="button" className="btn btn-secondary" onClick={this.reset}>Reset</button>
                        <button type="button" className="btn btn-primary" onClick={this.save}>Apply</button>
                    </div>
                </div>
            </Modal>

        );
    }

}

export default NegativeKeywordsModal;