import * as React from "react";
import {ToggleButtonGroup, ToggleButton} from  'react-bootstrap';
import MultiToggle from 'react-multi-toggle';
import {SERPFilter} from "../../../lib/api/keyword-keg/search/filters";

export interface ISerpRadioFilterProps{
  property: string,
  label: string,
  getSERPFilter: (property: string) => SERPFilter,
  handleSERPChange: (property: string, value: any) => void
}

interface ISerpRadioFilterState {
}

export class SerpRadioFilter extends React.Component<ISerpRadioFilterProps, ISerpRadioFilterState> {

    render(): JSX.Element | false | null {
        return (
            <div className="col-md-4">
                <div className="form-group">
                    <label className="control-label">{this.props.label}</label>
                    <ToggleButtonGroup
                        type="radio"
                        name="options"
                        defaultValue={this.props.getSERPFilter(this.props.property)}
                    onChange={(value: string) => {
                        this.props.handleSERPChange(this.props.property, value);
                    }}
                    >
                        <ToggleButton variant="alternate" value={'yes'}>Yes</ToggleButton>
                        <ToggleButton variant="alternate" value={'maybe'}>Maybe</ToggleButton>
                        <ToggleButton variant="alternate" value={'no'}>No</ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </div>
        );
    }
}
