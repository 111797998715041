import * as Promise from "bluebird";
import * as $ from "jquery";

import AbstractSuggestAPIFetcher from "./abstract";
import {Country, Codes as CountryCodes} from "../../countries";
import {Language, Codes as LanguageCodes} from "../../languages";
import SupportedCountries from "./alibaba/supported-countries";
import SupportedLanguages from "./alibaba/supported-languages";

interface GlobalOptions {
    language: Language
}

export default class AlibabaSuggestAPIFetcher extends AbstractSuggestAPIFetcher<GlobalOptions> {

    public getAPIName(): string {
        return "Alibaba API";
    }

    protected getSupportedCountries(): Country[] {
        return SupportedCountries;
    }

    protected getSupportedLanguages(): Language[] {
        return SupportedLanguages;
    }

    public requiresCountry() {
        return false;
    }

    public requiresLanguage() {
        return true;
    }

    /**
     * Get the URL to be used in the AJAX call
     * @param {string} keyword
     * @returns {string}
     */
    protected getURL(keyword: string): string {
        if(LanguageCodes[this.options.language] == "en") {
            return `https://connectkeyword.alibaba.com/lenoIframeJson.htm?searchType=product_en&keyword=${encodeURIComponent(keyword)}`;
        } else {
            return `https://connectkeyword.alibaba.com/mutilLenoIframeJson.htm?language=${this.getLanguageCode()}&searchType=&keyword=${encodeURIComponent(keyword)}`;
        }
    }

    /**
     * Filter the response keywords
     * @param {string[]} keywords
     * @returns {string[]}
     */
    protected filter(keywords: string[]): string[] {
        //Ask API returns HTML
        return keywords;
    }

    public fetch(keyword: string): Promise<string[]> {
        const varName = "alibaba" + Date.now() + Math.floor(Math.random()*1000);
        return new Promise<string[]>((resolve, reject) => {
            $.ajax({
                url: this.getURL(keyword),
                method: "GET",
                dataType: "jsonp",
                jsonp: "varname",
                jsonpCallback: varName,
                timeout: 5000,
                success: (response) => {
                    let response = (window[varName] || []).map((o: {keywords: string}) => o.keywords);
                    resolve(this.filter(response));
                    delete window[varName];
                },
                error: () => {
                    reject(new Error(this.getErrorMessage()));
                }
            });
        });
    }

}