import * as React from 'react';

import {IFilters, IUserFilter}
    from "../../lib/api/keyword-keg/search/filters";
import {count} from "../../lib/search/filter";

export interface IFilterButtonProps {
    loadedUserFilters: IUserFilter[],
    appliedFilters: IUserFilter,
    filters: IFilters,
    newFilters: (e: React.MouseEvent<HTMLAnchorElement>) => void
    openFilterModal: (e: React.MouseEvent<HTMLAnchorElement>) => void
    clearFilters: (e: React.MouseEvent<HTMLAnchorElement>) => void,
    applyUserFilter: (uf: IUserFilter) => void,
}

interface IFilterButtonState {

}

export class FilterButton extends React.Component<IFilterButtonProps, IFilterButtonState> {

    applyUserFilter = (uf: IUserFilter) => (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        this.props.applyUserFilter(uf);
    };

    getFilterButtonClasses() {
        let classes = 'btn dropdown-toggle ';
        if (count(this.props.filters) > 0) {
            classes += 'btn-info';
        } else {
            classes += 'btn-alternate';
        }
        return classes;
    }

    render(): JSX.Element | false | null {
        let filterCount = count(this.props.filters);
        let userFilterApplied = !!this.props.appliedFilters;
        return (
            <div className="btn-group mr-md-3">
                <button className={this.getFilterButtonClasses()} type="button"
                        id="copyButtonDropdownMenu" data-toggle="dropdown">Filter
                </button>
                <div className="dropdown-menu">
                    <a className="dropdown-item toggle-filter"
                       href="#"
                       onClick={(e) => {
                           e.preventDefault();
                           this.props.newFilters(e);
                       }}
                    >
                        <i className="fas fa-plus mr-2"/> New Filter
                    </a>
                    {filterCount > 0 && <a
                        onClick={this.props.openFilterModal}
                        className="dropdown-item toggle-filter"
                        href="#"
                    >
                        <i className="fas fa-edit mr-2"/> Edit Filter
                    </a>}
                    {filterCount > 0 && <a
                        onClick={this.props.clearFilters}
                        className="dropdown-item"
                        href="#">
                        <i className="fas fa-minus mr-2"/> Clear Filter
                    </a>}
                    {this.props.loadedUserFilters.length > 0 && <div className="dropdown-divider"/>}
                    {_.map(this.props.loadedUserFilters, (f) => {
                            let applied = this.props.appliedFilters
                                && f.id == this.props.appliedFilters.id;
                            return (
                                <a
                                    key={`user-filter-${f.id}`}
                                    onClick={this.applyUserFilter(f)}
                                    className="dropdown-item"
                                    href="#"
                                >
                                    {applied && <i className="far fa-check-square mr-2"/>}
                                    {!applied && <i className="far fa-square mr-2"/>}
                                    {f.name}
                                </a>
                            );
                        }
                    )}
                </div>
            </div>
        );
    }

}

export default FilterButton;
