import * as _ from "lodash";

export enum Country {
  Global,

  UnitedStates,
  Andorra,
  UnitedArabEmirates,
  Albania,
  Armenia,
  AmericanSamoa,
  Austria,
  Azerbaijan,
  BosniaAndHerzegovina,
  Belgium,
  BurkinaFaso,
  Bulgaria,
  Burundi,
  Benin,
  Bahamas,
  Bhutan,
  Belarus,
  Canada,
  CatalanCountries,
  DemocraticRepublicOfTheCongo,
  CentralAfricanRepublic,
  RepublicOfTheCongo,
  Switzerland,
  IvoryCoast,
  Chile,
  Cameroon,
  Angola,
  Botswana,
  CookIslands,
  CostaRica,
  Indonesia,
  Israel,
  India,
  Japan,
  Kenya,
  SouthKorea,
  Lesotho,
  Morocco,
  Mozambique,
  NewZealand,
  Thailand,
  Tanzania,
  Uganda,
  UnitedKingdom,
  Uzbekistan,
  Venezuela,
  UnitedStatesVirginIslands,
  SouthAfrica,
  Zambia,
  Zimbabwe,
  Afghanistan,
  AntiguaAndBarbuda,
  Anguilla,
  Argentina,
  Australia,
  Bangladesh,
  Bahrain,
  Brunei,
  Bolivia,
  Brazil,
  Belize,
  Colombia,
  Cuba,
  Cyprus,
  DominicanRepublic,
  Ecuador,
  Egypt,
  Ethiopia,
  Fiji,
  Ghana,
  Gibraltar,
  Guatemala,
  HongKong,
  Jamaica,
  Cambodia,
  Kuwait,
  Lebanon,
  Libya,
  Myanmar,
  Malta,
  Mexico,
  Malaysia,
  Namibia,
  NorfolkIsland,
  Nigeria,
  Nicaragua,
  Nepal,
  Oman,
  Panama,
  Peru,
  PapuaNewGuinea,
  Philippines,
  Pakistan,
  PuertoRico,
  Paraguay,
  Qatar,
  SaudiArabia,
  SolomonIslands,
  Singapore,
  SierraLeone,
  ElSalvador,
  Tajikistan,
  Turkey,
  Taiwan,
  Ukraine,
  Uruguay,
  SaintVincentAndTheGrenadines,
  Vietnam,
  CapeVerde,
  CzechRepublic,
  Germany,
  Djibouti,
  Denmark,
  Dominica,
  Algeria,
  Estonia,
  Spain,
  Finland,
  FederatedStatesOfMicronesia,
  France,
  Gabon,
  Georgia,
  Guernsey,
  Greenland,
  Gambia,
  Guadeloupe,
  Greece,
  Guyana,
  Honduras,
  Croatia,
  Haiti,
  Hungary,
  Ireland,
  IsleOfMan,
  Iraq,
  Iceland,
  Italy,
  Jersey,
  Jordan,
  Kyrgyzstan,
  Kiribati,
  Kazakhstan,
  Laos,
  Liechtenstein,
  SriLanka,
  Lithuania,
  Luxembourg,
  Latvia,
  Moldova,
  Montenegro,
  Madagascar,
  Macedonia,
  Mali,
  Mongolia,
  Montserrat,
  Mauritius,
  Maldives,
  Malawi,
  Niger,
  Netherlands,
  Norway,
  Nauru,
  Niue,
  Poland,
  PitcairnIslands,
  Palestine,
  Portugal,
  Romania,
  Serbia,
  Russia,
  Rwanda,
  Seychelles,
  Sweden,
  SaintHelenaAscensionAndTristanDaCunha,
  Slovenia,
  Slovakia,
  SanMarino,
  Senegal,
  Somalia,
  SaoTomeandPrincipe,
  Chad,
  Togo,
  Tokelau,
  TimorLeste,
  Turkmenistan,
  Tunisia,
  Tonga,
  TrinidadAndTobago,
  BritishVirginIslands,
  Vanuatu,
  Samoa
}

export let Codes: {[key: number]: string} = {};
export let Names: {[key: number]: string} = {};

Codes[Country.Global] = "global";
Codes[Country.UnitedStates] = "us";
Codes[Country.Andorra] = "ad";
Codes[Country.UnitedArabEmirates] = "ae";
Codes[Country.Albania] = "al";
Codes[Country.Armenia] = "am";
Codes[Country.AmericanSamoa] = "as";
Codes[Country.Austria] = "at";
Codes[Country.Azerbaijan] = "az";
Codes[Country.BosniaAndHerzegovina] = "ba";
Codes[Country.Belgium] = "be";
Codes[Country.BurkinaFaso] = "bf";
Codes[Country.Bulgaria] = "bg";
Codes[Country.Burundi] = "bi";
Codes[Country.Benin] = "bj";
Codes[Country.Bahamas] = "bs";
Codes[Country.Bhutan] = "bt";
Codes[Country.Belarus] = "by";
Codes[Country.Canada] = "ca";
Codes[Country.CatalanCountries] = "cat";
Codes[Country.DemocraticRepublicOfTheCongo] = "cd";
Codes[Country.CentralAfricanRepublic] = "cf";
Codes[Country.RepublicOfTheCongo] = "cg";
Codes[Country.Switzerland] = "ch";
Codes[Country.IvoryCoast] = "ci";
Codes[Country.Chile] = "cl";
Codes[Country.Cameroon] = "cm";
Codes[Country.Angola] = "ao";
Codes[Country.Botswana] = "bw";
Codes[Country.CookIslands] = "ck";
Codes[Country.CostaRica] = "cr";
Codes[Country.Indonesia] = "id";
Codes[Country.Israel] = "il";
Codes[Country.India] = "in";
Codes[Country.Japan] = "jp";
Codes[Country.Kenya] = "ke";
Codes[Country.SouthKorea] = "kr";
Codes[Country.Lesotho] = "ls";
Codes[Country.Morocco] = "ma";
Codes[Country.Mozambique] = "mz";
Codes[Country.NewZealand] = "nz";
Codes[Country.Thailand] = "th";
Codes[Country.Tanzania] = "tz";
Codes[Country.Uganda] = "ug";
Codes[Country.UnitedKingdom] = "uk";
Codes[Country.Uzbekistan] = "uz";
Codes[Country.Venezuela] = "ve";
Codes[Country.UnitedStatesVirginIslands] = "vi";
Codes[Country.SouthAfrica] = "za";
Codes[Country.Zambia] = "zm";
Codes[Country.Zimbabwe] = "zw";
Codes[Country.Afghanistan] = "af";
Codes[Country.AntiguaAndBarbuda] = "ag";
Codes[Country.Anguilla] = "ai";
Codes[Country.Argentina] = "ar";
Codes[Country.Australia] = "au";
Codes[Country.Bangladesh] = "bd";
Codes[Country.Bahrain] = "bh";
Codes[Country.Brunei] = "bn";
Codes[Country.Bolivia] = "bo";
Codes[Country.Brazil] = "br";
Codes[Country.Belize] = "bz";
Codes[Country.Colombia] = "co";
Codes[Country.Cuba] = "cu";
Codes[Country.Cyprus] = "cy";
Codes[Country.DominicanRepublic] = "do";
Codes[Country.Ecuador] = "ec";
Codes[Country.Egypt] = "eg";
Codes[Country.Ethiopia] = "et";
Codes[Country.Fiji] = "fj";
Codes[Country.Ghana] = "gh";
Codes[Country.Gibraltar] = "gi";
Codes[Country.Guatemala] = "gt";
Codes[Country.HongKong] = "hk";
Codes[Country.Jamaica] = "jm";
Codes[Country.Cambodia] = "kh";
Codes[Country.Kuwait] = "kw";
Codes[Country.Lebanon] = "lb";
Codes[Country.Libya] = "ly";
Codes[Country.Myanmar] = "mm";
Codes[Country.Malta] = "mt";
Codes[Country.Mexico] = "mx";
Codes[Country.Malaysia] = "my";
Codes[Country.Namibia] = "na";
Codes[Country.NorfolkIsland] = "nf";
Codes[Country.Nigeria] = "ng";
Codes[Country.Nicaragua] = "ni";
Codes[Country.Nepal] = "np";
Codes[Country.Oman] = "om";
Codes[Country.Panama] = "pa";
Codes[Country.Peru] = "pe";
Codes[Country.PapuaNewGuinea] = "pg";
Codes[Country.Philippines] = "ph";
Codes[Country.Pakistan] = "pk";
Codes[Country.PuertoRico] = "pr";
Codes[Country.Paraguay] = "py";
Codes[Country.Qatar] = "qa";
Codes[Country.SaudiArabia] = "sa";
Codes[Country.SolomonIslands] = "sb";
Codes[Country.Singapore] = "sg";
Codes[Country.SierraLeone] = "sl";
Codes[Country.ElSalvador] = "sv";
Codes[Country.Tajikistan] = "tj";
Codes[Country.Turkey] = "tr";
Codes[Country.Taiwan] = "tw";
Codes[Country.Ukraine] = "ua";
Codes[Country.Uruguay] = "uy";
Codes[Country.SaintVincentAndTheGrenadines] = "vc";
Codes[Country.Vietnam] = "vn";
Codes[Country.CapeVerde] = "cv";
Codes[Country.CzechRepublic] = "cz";
Codes[Country.Germany] = "de";
Codes[Country.Djibouti] = "dj";
Codes[Country.Denmark] = "dk";
Codes[Country.Dominica] = "dm";
Codes[Country.Algeria] = "dz";
Codes[Country.Estonia] = "ee";
Codes[Country.Spain] = "es";
Codes[Country.Finland] = "fi";
Codes[Country.FederatedStatesOfMicronesia] = "fm";
Codes[Country.France] = "fr";
Codes[Country.Gabon] = "ga";
Codes[Country.Georgia] = "ge";
Codes[Country.Guernsey] = "gg";
Codes[Country.Greenland] = "gl";
Codes[Country.Gambia] = "gm";
Codes[Country.Guadeloupe] = "gp";
Codes[Country.Greece] = "gr";
Codes[Country.Guyana] = "gy";
Codes[Country.Honduras] = "hn";
Codes[Country.Croatia] = "hr";
Codes[Country.Haiti] = "ht";
Codes[Country.Hungary] = "hu";
Codes[Country.Ireland] = "ie";
Codes[Country.IsleOfMan] = "im";
Codes[Country.Iraq] = "iq";
Codes[Country.Iceland] = "is";
Codes[Country.Italy] = "it";
Codes[Country.Jersey] = "je";
Codes[Country.Jordan] = "jo";
Codes[Country.Kyrgyzstan] = "kg";
Codes[Country.Kiribati] = "ki";
Codes[Country.Kazakhstan] = "kz";
Codes[Country.Laos] = "la";
Codes[Country.Liechtenstein] = "li";
Codes[Country.SriLanka] = "lk";
Codes[Country.Lithuania] = "lt";
Codes[Country.Luxembourg] = "lu";
Codes[Country.Latvia] = "lv";
Codes[Country.Moldova] = "md";
Codes[Country.Montenegro] = "me";
Codes[Country.Madagascar] = "mg";
Codes[Country.Macedonia] = "mk";
Codes[Country.Mali] = "ml";
Codes[Country.Mongolia] = "mn";
Codes[Country.Montserrat] = "ms";
Codes[Country.Mauritius] = "mu";
Codes[Country.Maldives] = "mv";
Codes[Country.Malawi] = "mw";
Codes[Country.Niger] = "ne";
Codes[Country.Netherlands] = "nl";
Codes[Country.Norway] = "no";
Codes[Country.Nauru] = "nr";
Codes[Country.Niue] = "nu";
Codes[Country.Poland] = "pl";
Codes[Country.PitcairnIslands] = "pn";
Codes[Country.Palestine] = "ps";
Codes[Country.Portugal] = "pt";
Codes[Country.Romania] = "ro";
Codes[Country.Serbia] = "rs";
Codes[Country.Russia] = "ru";
Codes[Country.Rwanda] = "rw";
Codes[Country.Seychelles] = "sc";
Codes[Country.Sweden] = "se";
Codes[Country.SaintHelenaAscensionAndTristanDaCunha] = "sh";
Codes[Country.Slovenia] = "si";
Codes[Country.Slovakia] = "sk";
Codes[Country.SanMarino] = "sm";
Codes[Country.Senegal] = "sn";
Codes[Country.Somalia] = "so";
Codes[Country.SaoTomeandPrincipe] = "st";
Codes[Country.Chad] = "td";
Codes[Country.Togo] = "tg";
Codes[Country.Tokelau] = "tk";
Codes[Country.TimorLeste] = "tl";
Codes[Country.Turkmenistan] = "tm";
Codes[Country.Tunisia] = "tn";
Codes[Country.Tonga] = "to";
Codes[Country.TrinidadAndTobago] = "tt";
Codes[Country.BritishVirginIslands] = "vg";
Codes[Country.Vanuatu] = "vu";
Codes[Country.Samoa] = "ws";

Names[Country.Global] = "Global";
Names[Country.UnitedStates] = "United States";
Names[Country.Andorra] = "Andorra";
Names[Country.UnitedArabEmirates] = "United Arab Emirates";
Names[Country.Albania] = "Albania";
Names[Country.Armenia] = "Armenia";
Names[Country.AmericanSamoa] = "American Samoa";
Names[Country.Austria] = "Austria";
Names[Country.Azerbaijan] = "Azerbaijan";
Names[Country.BosniaAndHerzegovina] = "Bosnia and Herzegovina";
Names[Country.Belgium] = "Belgium";
Names[Country.BurkinaFaso] = "Burkina Faso";
Names[Country.Bulgaria] = "Bulgaria";
Names[Country.Burundi] = "Burundi";
Names[Country.Benin] = "Benin";
Names[Country.Bahamas] = "Bahamas";
Names[Country.Bhutan] = "Bhutan";
Names[Country.Belarus] = "Belarus";
Names[Country.Canada] = "Canada";
Names[Country.CatalanCountries] = "Catalan Countries";
Names[Country.DemocraticRepublicOfTheCongo] = "Democratic Republic of the Congo";
Names[Country.CentralAfricanRepublic] = "Central African Republic";
Names[Country.RepublicOfTheCongo] = "Republic of the Congo";
Names[Country.Switzerland] = "Switzerland";
Names[Country.IvoryCoast] = "Ivory Coast";
Names[Country.Chile] = "Chile";
Names[Country.Cameroon] = "Cameroon";
Names[Country.Angola] = "Angola";
Names[Country.Botswana] = "Botswana";
Names[Country.CookIslands] = "Cook Islands";
Names[Country.CostaRica] = "Costa Rica";
Names[Country.Indonesia] = "Indonesia";
Names[Country.Israel] = "Israel";
Names[Country.India] = "India";
Names[Country.Japan] = "Japan";
Names[Country.Kenya] = "Kenya";
Names[Country.SouthKorea] = "South Korea";
Names[Country.Lesotho] = "Lesotho";
Names[Country.Morocco] = "Morocco";
Names[Country.Mozambique] = "Mozambique";
Names[Country.NewZealand] = "New Zealand";
Names[Country.Thailand] = "Thailand";
Names[Country.Tanzania] = "Tanzania";
Names[Country.Uganda] = "Uganda";
Names[Country.UnitedKingdom] = "United Kingdom";
Names[Country.Uzbekistan] = "Uzbekistan";
Names[Country.Venezuela] = "Venezuela";
Names[Country.UnitedStatesVirginIslands] = "United States Virgin Islands";
Names[Country.SouthAfrica] = "South Africa";
Names[Country.Zambia] = "Zambia";
Names[Country.Zimbabwe] = "Zimbabwe";
Names[Country.Afghanistan] = "Afghanistan";
Names[Country.AntiguaAndBarbuda] = "Antigua and Barbuda";
Names[Country.Anguilla] = "Anguilla";
Names[Country.Argentina] = "Argentina";
Names[Country.Australia] = "Australia";
Names[Country.Bangladesh] = "Bangladesh";
Names[Country.Bahrain] = "Bahrain";
Names[Country.Brunei] = "Brunei";
Names[Country.Bolivia] = "Bolivia";
Names[Country.Brazil] = "Brazil";
Names[Country.Belize] = "Belize";
Names[Country.Colombia] = "Colombia";
Names[Country.Cuba] = "Cuba";
Names[Country.Cyprus] = "Cyprus";
Names[Country.DominicanRepublic] = "Dominican Republic";
Names[Country.Ecuador] = "Ecuador";
Names[Country.Egypt] = "Egypt";
Names[Country.Ethiopia] = "Ethiopia";
Names[Country.Fiji] = "Fiji";
Names[Country.Ghana] = "Ghana";
Names[Country.Gibraltar] = "Gibraltar";
Names[Country.Guatemala] = "Guatemala";
Names[Country.HongKong] = "Hong Kong";
Names[Country.Jamaica] = "Jamaica";
Names[Country.Cambodia] = "Cambodia";
Names[Country.Kuwait] = "Kuwait";
Names[Country.Lebanon] = "Lebanon";
Names[Country.Libya] = "Libya";
Names[Country.Myanmar] = "Myanmar";
Names[Country.Malta] = "Malta";
Names[Country.Mexico] = "Mexico";
Names[Country.Malaysia] = "Malaysia";
Names[Country.Namibia] = "Namibia";
Names[Country.NorfolkIsland] = "Norfolk Island";
Names[Country.Nigeria] = "Nigeria";
Names[Country.Nicaragua] = "Nicaragua";
Names[Country.Nepal] = "Nepal";
Names[Country.Oman] = "Oman";
Names[Country.Panama] = "Panama";
Names[Country.Peru] = "Peru";
Names[Country.PapuaNewGuinea] = "Papua New Guinea";
Names[Country.Philippines] = "Philippines";
Names[Country.Pakistan] = "Pakistan";
Names[Country.PuertoRico] = "Puerto Rico";
Names[Country.Paraguay] = "Paraguay";
Names[Country.Qatar] = "Qatar";
Names[Country.SaudiArabia] = "Saudi Arabia";
Names[Country.SolomonIslands] = "Solomon Islands";
Names[Country.Singapore] = "Singapore";
Names[Country.SierraLeone] = "Sierra Leone";
Names[Country.ElSalvador] = "El Salvador";
Names[Country.Tajikistan] = "Tajikistan";
Names[Country.Turkey] = "Turkey";
Names[Country.Taiwan] = "Taiwan";
Names[Country.Ukraine] = "Ukraine";
Names[Country.Uruguay] = "Uruguay";
Names[Country.SaintVincentAndTheGrenadines] = "Saint Vincent and the Grenadines";
Names[Country.Vietnam] = "Vietnam";
Names[Country.CapeVerde] = "Cape Verde";
Names[Country.CzechRepublic] = "Czech Republic";
Names[Country.Germany] = "Germany";
Names[Country.Djibouti] = "Djibouti";
Names[Country.Denmark] = "Denmark";
Names[Country.Dominica] = "Dominica";
Names[Country.Algeria] = "Algeria";
Names[Country.Estonia] = "Estonia";
Names[Country.Spain] = "Spain";
Names[Country.Finland] = "Finland";
Names[Country.FederatedStatesOfMicronesia] = "Federated States of Micronesia";
Names[Country.France] = "France";
Names[Country.Gabon] = "Gabon";
Names[Country.Georgia] = "Georgia";
Names[Country.Guernsey] = "Guernsey";
Names[Country.Greenland] = "Greenland";
Names[Country.Gambia] = "Gambia";
Names[Country.Guadeloupe] = "Guadeloupe";
Names[Country.Greece] = "Greece";
Names[Country.Guyana] = "Guyana";
Names[Country.Honduras] = "Honduras";
Names[Country.Croatia] = "Croatia";
Names[Country.Haiti] = "Haiti";
Names[Country.Hungary] = "Hungary";
Names[Country.Ireland] = "Ireland";
Names[Country.IsleOfMan] = "Isle of Man";
Names[Country.Iraq] = "Iraq";
Names[Country.Iceland] = "Iceland";
Names[Country.Italy] = "Italy";
Names[Country.Jersey] = "Jersey";
Names[Country.Jordan] = "Jordan";
Names[Country.Kyrgyzstan] = "Kyrgyzstan";
Names[Country.Kiribati] = "Kiribati";
Names[Country.Kazakhstan] = "Kazakhstan";
Names[Country.Laos] = "Laos";
Names[Country.Liechtenstein] = "Liechtenstein";
Names[Country.SriLanka] = "Sri Lanka";
Names[Country.Lithuania] = "Lithuania";
Names[Country.Luxembourg] = "Luxembourg";
Names[Country.Latvia] = "Latvia";
Names[Country.Moldova] = "Moldova";
Names[Country.Montenegro] = "Montenegro";
Names[Country.Madagascar] = "Madagascar";
Names[Country.Macedonia] = "Macedonia";
Names[Country.Mali] = "Mali";
Names[Country.Mongolia] = "Mongolia";
Names[Country.Montserrat] = "Montserrat";
Names[Country.Mauritius] = "Mauritius";
Names[Country.Maldives] = "Maldives";
Names[Country.Malawi] = "Malawi";
Names[Country.Niger] = "Niger";
Names[Country.Netherlands] = "Netherlands";
Names[Country.Norway] = "Norway";
Names[Country.Nauru] = "Nauru";
Names[Country.Niue] = "Niue";
Names[Country.Poland] = "Poland";
Names[Country.PitcairnIslands] = "Pitcairn Islands";
Names[Country.Palestine] = "Palestine";
Names[Country.Portugal] = "Portugal";
Names[Country.Romania] = "Romania";
Names[Country.Serbia] = "Serbia";
Names[Country.Russia] = "Russia";
Names[Country.Rwanda] = "Rwanda";
Names[Country.Seychelles] = "Seychelles";
Names[Country.Sweden] = "Sweden";
Names[Country.SaintHelenaAscensionAndTristanDaCunha] = "Saint Helena, Ascension and Tristan da Cunha";
Names[Country.Slovenia] = "Slovenia";
Names[Country.Slovakia] = "Slovakia";
Names[Country.SanMarino] = "San Marino";
Names[Country.Senegal] = "Senegal";
Names[Country.Somalia] = "Somalia";
Names[Country.SaoTomeandPrincipe] = "São Tomé and Príncipe";
Names[Country.Chad] = "Chad";
Names[Country.Togo] = "Togo";
Names[Country.Tokelau] = "Tokelau";
Names[Country.TimorLeste] = "Timor-Leste";
Names[Country.Turkmenistan] = "Turkmenistan";
Names[Country.Tunisia] = "Tunisia";
Names[Country.Tonga] = "Tonga";
Names[Country.TrinidadAndTobago] = "Trinidad and Tobago";
Names[Country.BritishVirginIslands] = "British Virgin Islands";
Names[Country.Vanuatu] = "Vanuatu";
Names[Country.Samoa] = "Samoa";

export let CodeToCountry: {[code: string]: Country} = {};

for (const k in Codes) {
  CodeToCountry[Codes[k]] = Number(k);
}

export let All: Country[] = [
  Country.Global,
  Country.UnitedStates,
  Country.UnitedKingdom,
  Country.India,
  Country.Australia,
  Country.Canada,
  Country.NewZealand,
  Country.SouthAfrica
];

export default Country;
