import * as Promise from "bluebird";
import * as $ from "jquery";

import isURL from "../../../util/is-url";
import AbstractSuggestAPIFetcher from "./abstract";
import {Country, Codes as CountryCodes} from "../../countries";
import {Language, Codes as LanguageCodes} from "../../languages";
import SupportedCountries from "./ebay/supported-countries";
import SupportedLanguages from "./ebay/supported-languages";
import MarketIds from "./ebay/ids";

interface GlobalOptions {
    country: Country;
}

export default class AmazonSuggestAPIFetcher extends AbstractSuggestAPIFetcher<GlobalOptions> {

    public getAPIName(): string {
        return "eBay API";
    }

    protected getSupportedCountries(): Country[] {
        return SupportedCountries;
    }

    protected getSupportedLanguages(): Language[] {
        return SupportedLanguages;
    }

    protected getMarketID(): number {
        return MarketIds[this.options.country];
    }

    public requiresCountry() {
        return true;
    }

    public requiresLanguage() {
        return false;
    }

    /**
     * Get the URL to be used in the AJAX call
     * @param {string} keyword
     * @returns {string}
     */
    protected getURL(keyword: string): string {
        return `https://autosug.ebay.com/autosug?kwd=${encodeURIComponent(keyword)}&_jgr=1&sId=${this.getMarketID()}&_ch=0`;
    }

    /**
     * Filter the response keywords
     * @param {string[]} keywords
     * @returns {string[]}
     */
    protected filter(keywords: string[]): string[] {
        return keywords;
    }

    public fetch(keyword: string): Promise<string[]> {
        const varName = "ebay" + Date.now() + Math.floor(Math.random()*1000);
        return new Promise<string[]>((resolve, reject) => {
            $.ajax({
                url: this.getURL(keyword),
                method: "GET",
                dataType: "jsonp",
                jsonp: "callback",
                jsonpCallback: varName,
                timeout: 5000,
                success: (response) => {
                    resolve(this.filter((response.res || {}).sug || []));
                },
                error: () => {
                    reject(new Error(this.getErrorMessage()));
                }
            });
        });
    }

}