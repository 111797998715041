import * as Promise from "bluebird";
import SuggestAPIFetcher from "../fetcher";
import {Country, Codes as CountryCodes} from "../../countries";
import {Language, Codes as LanguageCodes} from "../../languages";

interface CommonOptions {
    country?: Country,
    language?: Language
}

abstract class AbstractSuggestAPIFetcher<TOptions extends CommonOptions> implements SuggestAPIFetcher {

    public constructor(protected options: TOptions) {

        //Check if the country is supported
        if(this.requiresCountry() && (this.getSupportedCountries().indexOf(options.country) === -1)) {
            throw new Error(`Unsupported country provided to suggest API ${this.getAPIName()}`);
        }

        //Check if the language is supported
        if(this.requiresLanguage() && (this.getSupportedLanguages().indexOf(options.language) === -1)) {
            throw new Error(`Unsupported language provided to suggest API ${this.getAPIName()}`);
        }
    }

    /**
     * Get the name of the API
     * @returns {string}
     */
    public abstract getAPIName(): string;

    /**
     * Get the list of supported countries
     * @returns {Country[]}
     */
    protected abstract getSupportedCountries(): Country[];

    /**
     * Get the list of supported languages
     * @returns {Language[]}
     */
    protected abstract getSupportedLanguages(): Language[];

    public abstract requiresCountry(): boolean;
    public abstract requiresLanguage(): boolean;

    public abstract fetch(keyword: string): Promise<string[]>;

    /**
     * Get the country code to be used
     * @returns {string}
     */
    protected getCountryCode(): string {
        return CountryCodes[this.options.country];
    }

    /**
     * Get the language code to be used
     * @returns {string}
     */
    protected getLanguageCode(): string {
        return LanguageCodes[this.options.language];
    }

    protected getErrorMessage(): string {
        return `${this.getAPIName()} request failed`;
    }

}

export default AbstractSuggestAPIFetcher;