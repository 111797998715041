import * as React from "react";
import {MySelect2 as Select2} from "../../lib/my-select-2";

import {AllKeywordTypes, KeywordType, KeywordTypeNames} from "../../lib/keyword/keyword-types";
import {getIntegerSelectedOptions} from "../../lib/util/html";

export interface SearchResultTypeProps {
  types?: KeywordType[],
  onChange: (types: number[]) => void,
  selected?: KeywordType[],
  selectId?: string,
  makeInline?: boolean,
  id: string,
  placeholder?: string
}

interface SearchResultTypeState {
  selectOptions: {
    id: number,
    text: string
  }[]
}

export class SearchResultTypeSelect extends React.Component<SearchResultTypeProps, SearchResultTypeState>{

  static defaultProps = {
    types: AllKeywordTypes,
    selectId: 'keyword-result-type',
    makeInline: false,
    placeholder: "Select One Or More Result Types"
  };

  constructor(props: SearchResultTypeProps) {
    super(props);
    this.state = {
      selectOptions: []
    };
  }

  componentDidMount() {
    let selectOptions  = this.props.types.map((type, i) => {
      return {
        id: type,
        text: KeywordTypeNames[type]
      };
    });
    this.setState({
      selectOptions
    });
  }

  handleChange = (e) => {
    const selected = getIntegerSelectedOptions(e.target);
    if(!_.isEqual(selected.sort(), this.props.selected.sort())){
      this.props.onChange(selected);
    }
  };

  render() {
    let {id} = this.props;
    return (
        <Select2
            id={id}
            multiple={true}
            data={this.state.selectOptions}
            options={
              {
                placeholder: this.props.placeholder,
              }
            }
            className="form-control select2"
            onChange={this.handleChange}
            value={this.props.selected}
        />
    );
  }

}

export default SearchResultTypeSelect;