import * as React from "react";
import * as _ from "lodash";
import {Popover} from "react-bootstrap";
import {SERP} from "../../lib/columns";
import {IKeywordResult} from "../../lib/api/keyword-keg/sf/results";
import {KkOverlayTrigger} from "../common/kk-overlay-trigger";

export interface ISERPMetricsProps {
    result: IKeywordResult
}

interface ISERPMetricsState {
}

export class SERPMetrics extends React.Component<ISERPMetricsProps, ISERPMetricsState> {

    render() {
        return (
            <span className="mr-2">
              {SERP.All.filter((type) => {
                  const column = SERP.MetricColumnCodes[type];
                  return _.some((_.isArray(column) ? column : [column]), (c) => !_.isUndefined(this.props.result[c]) && (this.props.result[c] > 0));
              }).map((type, i) => {
                  let popoverId = `${this.props.result.search_result.replace(' ', '')}-serp-${SERP.Names[type].replace(' ', '')}`;
                  let popover = <Popover id={popoverId}>
                      <Popover.Title as="h3">{SERP.Names[type]}</Popover.Title>
                      <Popover.Content>
                          {SERP.Descriptions[type]}
                      </Popover.Content>
                  </Popover>;
                  return <KkOverlayTrigger key={i} placement="bottom" overlay={popover}>
                      <span className={`mr-1 ${SERP.GlyphiconClasses[type]}`}></span>
                  </KkOverlayTrigger>;
              })}
            </span>
        );
    }
}

export default SERPMetrics;
