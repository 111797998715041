import * as React from "react";
import * as $ from "jquery";
import * as Promise from "bluebird";
import {Modal} from "react-bootstrap";

export interface IProps {
}

interface IState {
  show: boolean
}

interface ICheckLoggedInRepose {

}

export class SessionExpiryNotification extends React.Component<IProps, IState>{

  state = {
    show: false
  }

  componentDidMount() {
    this.detectLogout(true);
  }

  detectLogout = (initial: boolean) => {
    let thiz = this;
    this.checkLoggedIn().then(function (loggedIn) {
      if (loggedIn) {
        setTimeout(() => {
          thiz.detectLogout(false);
        }, 60000);
      } else if (!initial) {
        thiz.setState({
          show: true
        });
      }
    });
  }

  checkLoggedIn = () => {
    return new Promise<ICheckLoggedInRepose>((resolve, reject) => {
      $.ajax({
        url: `/auth/check_logged_in`,
        method: "GET",
        success: (response: {logged_in: boolean}) => {
          resolve(response.logged_in);
        },
        error: (jqXHR: {responseJSON: {message: string}}) => {
          reject(new Error(`/auth/check_logged_in returned an error : ${jqXHR.responseJSON.message}`));
        }
      } as JQueryAjaxSettings);
    });
  }

  login = () => {
    window.location.href = "/auth/login";
  }

  onHide = () => {
    this.setState({
      show: false
    });
  }

  handleModalShown = () => {
    feather.replace();
  };

  render() {

    return <Modal show={this.state.show} onHide={this.onHide} onShow={this.handleModalShown}>
      <Modal.Header>
        <figure className="icon text-danger">
          <i data-feather="clock" width={64} height={64} />
        </figure>
        <h5 className="modal-title">Session Expired</h5>
      </Modal.Header>
      <div className="modal-body">
        <p className="lead text-center">Your session has expired. You need to login again</p>
      </div>
      <div className="modal-actions has-multiple">
        <button type="button" className="btn btn-secondary" onClick={this.onHide}>Close</button>
        <button className="btn btn-primary" onClick={this.login}>Login Again</button>
      </div>
    </Modal>;
  }
}

export default SessionExpiryNotification;