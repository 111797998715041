import * as React from 'react';
import * as _ from 'lodash';
import {IFilters} from "../../lib/api/keyword-keg/search/filters";
import {IndustryInterface} from "../../lib/api/keyword-keg/search/industries";
import * as ReactTagInput from "react-tag-input";

const ReactTags = ReactTagInput['WithContext'];

export interface IFilterTagsProps {
    filters: IFilters,
    industries: {[id: number]: IndustryInterface},
    uiPermissions: {
        show_advanced_metrics: boolean,
        serpFilter: boolean
    }
}

interface IFilterTagsState {

}

export class FilterTags extends React.Component<IFilterTagsProps, IFilterTagsState> {

    props: IFilterTagsProps;

    getFilterTags(): any[] {
        return [
            [(this.props.filters.monthly_volume || {}).min, 'monthly_volume.min', `Search Volume >= ${(this.props.filters.monthly_volume || {}).min}`],
            [(this.props.filters.monthly_volume || {}).max, 'monthly_volume.max', `Search Volume <= ${(this.props.filters.monthly_volume || {}).max}`],
            [(this.props.filters.value || {}).min, 'value.min', `Value >= ${(this.props.filters.value || {}).min}`],
            [(this.props.filters.value || {}).max, 'value.max', `Value <= ${(this.props.filters.value || {}).max}`],
            [(this.props.filters.cpc || {}).min, 'cpc.min', `CPC >= ${(this.props.filters.cpc || {}).min}`],
            [(this.props.filters.cpc || {}).max, 'cpc.max', `CPC <= ${(this.props.filters.cpc || {}).max}`],
            [(this.props.filters.words || {}).min, 'words.min', `Words >= ${(this.props.filters.words || {}).min}`],
            [(this.props.filters.words || {}).max, 'words.max', `Words <= ${(this.props.filters.words || {}).max}`],
            [(this.props.filters.length || {}).min, 'length.min', `Length >= ${(this.props.filters.length || {}).min}`],
            [(this.props.filters.length || {}).max, 'length.max', `Length <= ${(this.props.filters.length || {}).max}`],
            [(this.props.filters.competition || {}).min, 'competition.min', `Competition >= ${(this.props.filters.competition || {}).min}`],
            [(this.props.filters.competition || {}).max, 'competition.max', `Competition <= ${(this.props.filters.competition || {}).max}`],
            [(this.props.filters.kw_potential || {}).min, 'kw_potential.min', `Keyword Power >= ${(this.props.filters.kw_potential || {}).min}`],
            [(this.props.filters.kw_potential || {}).max, 'kw_potential.max', `Keyword Power <= ${(this.props.filters.kw_potential || {}).max}`],
            [(this.props.filters.onpage_difficulty || {}).min, 'onpage_difficulty.min', `On-Page Difficulty >= ${(this.props.filters.onpage_difficulty || {}).min}`],
            [(this.props.filters.onpage_difficulty || {}).max, 'onpage_difficulty.max', `On-Page Difficulty <= ${(this.props.filters.onpage_difficulty || {}).max}`],
            [(this.props.filters.offpage_difficulty || {}).min, 'offpage_difficulty.min', `Off-Page Difficulty >= ${(this.props.filters.offpage_difficulty || {}).min}`],
            [(this.props.filters.offpage_difficulty || {}).max, 'offpage_difficulty.max', `Off-Page Difficulty <= ${(this.props.filters.offpage_difficulty || {}).max}`],
            [(this.props.filters.seo_difficulty || {}).min, 'seo_difficulty.min', `SEO Difficulty >= ${(this.props.filters.seo_difficulty || {}).min}`],
            [(this.props.filters.seo_difficulty || {}).max, 'seo_difficulty.max', `SEO Difficulty <= ${(this.props.filters.seo_difficulty || {}).max}`],
            [(this.props.filters.ctr_opportunity || {}).min, 'ctr_opportunity.min', `CTR Scope >= ${(this.props.filters.ctr_opportunity || {}).min}`],
            [(this.props.filters.ctr_opportunity || {}).max, 'ctr_opportunity.max', `CTR Scope <= ${(this.props.filters.ctr_opportunity || {}).max}`],
        ].filter((filter) => {
            return !_.isUndefined(filter[0]) && !_.isNull(filter[0]);
        }).map((filter) => {
            return {id: filter[1], text: filter[2]}
        }).concat(
            ((_.isUndefined(this.props.filters.industries) || _.isNull(this.props.filters.industries)) ? [] : this.props.filters.industries).map((id) => {
                return {id: `industry-${id}`, text: this.props.industries[id].name, industry: id};
            })
        ).concat(
            this.props.uiPermissions.serpFilter ? _.keys(this.props.filters.serp || {}).filter((p: string) => this.props.filters.serp[p] !== "maybe").map((property: string) => {
                    const name = (() => {switch (property) {
                        case "ads": return "Ads";
                        case "total_local": return "Local Listings";
                        case "total_news": return "News Listings";
                        case "total_fresh": return "Fresh Listings";
                        case "has_snippet": return "Snippet";
                        case "total_videos": return "Video Listings";
                        case "total_images": return "Image Listings";
                        case "total_pla": return "Product Listings";
                        case "has_knowledge_graphs": return "Knowledge Graphs";
                    }})();
                    return {id: `serp-${property}`, text: `${this.props.filters.serp[property] == "yes" ? "Has" : "No"} ${name}`};
                }) : []
        ).concat(
            (_.has(this.props.filters, "regexp") && this.props.filters.regexp !== null && this.props.filters.regexp.trim().length) ? [{id: 'regexp', text: "RegExp : "+this.props.filters.regexp}] : []
        );
    }

    render(): JSX.Element | false | null {
        return (
            <div className="current-filter py-2">
                <div className="media">
                            <span className="mr-3">
                              <i className="fas fa-filter"/>
                            </span>
                    <div className="media-body">
                        {_.map(this.getFilterTags(), t => <span
                            className="badge badge-info text-contrast rounded-pill px-3 mr-2" key={t.id}>{t.text}</span>)}
                    </div>
                </div>
            </div>
        );
    }

}
