import * as React from "react";
import {Popover} from "react-bootstrap";
import {KkOverlayTrigger} from "../kk-overlay-trigger";

export interface IRegExpFilterProps{
  regexpError: boolean,
  getRegExp: () => string,
  handleRegExpChange: (regexp: string) => void
}

interface IRegExpFilterState{
}

export class RegExpFilter  extends React.Component<IRegExpFilterProps, IRegExpFilterState> {
    render(): JSX.Element | false | null {
        return (
            <div className="form-group">
                <label className="control-label">RegExp:</label>
                <div className="input-group">
                    <input className="form-control" ref="regexp" value={this.props.getRegExp()}
                           onChange={(e) => this.props.handleRegExpChange(e.target.value)} type="text"/>
                    <div className="input-group-append">
                        <KkOverlayTrigger placement="bottom" trigger="click" rootClose
                                          overlay={<Popover id={"regexp-help"} title="Regular Expression">
                                              <Popover.Content>
                                                  Enter a MySQL regular
                                                  expression here to filter the keywords. <br/>For e.g. <b>^[a-z[:blank:]]+$</b> will
                                                  filter only letters and spaces.
                                              </Popover.Content>
                                          </Popover>}>
                            <span className="input-group-text">?</span>
                        </KkOverlayTrigger>
                    </div>
                </div>
            </div>
        );
    }
}
