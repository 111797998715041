import * as React from "react";
import * as _ from "lodash";

import {AbstractSearchTool, IAbstractSearchToolProps, IAbstractSearchToolState} from './abstract-search-tool';
import {All as AllAPIs, IdentifiersToAPI, List as API} from "../lib/api/suggest/fetchers";
import {All as AllCountries, CodeToCountry, Country} from "../lib/api/countries";
import {All as AllLanguages, Language} from "../lib/api/languages";
import {AllKeywordTypes as AllResultTypes, KeywordType} from "../lib/keyword/keyword-types";
import {execute} from "../lib/api/keyword-keg/sf/execute";
import {IParams as IResultParams, IResponse, results} from "../lib/api/keyword-keg/sf/results";
import * as Promise from "bluebird";
import {IKeywordsSourceType} from "../lib/api/keyword-keg/keywords-source";
import {IFilters} from "../lib/api/keyword-keg/search/filters";
import {deleteResults, IDeleteResultsParams} from "../lib/api/keyword-keg/sf/delete-results";
import {number_format} from "../lib/util/number-format";
import {isMobile} from "react-device-detect";

interface IDashboardProps extends IAbstractSearchToolProps {
}

interface IDashboardState extends IAbstractSearchToolState {
}

export default class Dashboard extends AbstractSearchTool<IDashboardProps, IDashboardState> {

  constructor(props: IDashboardProps){
    super(props);
    this.state = this.initState(props);
  }

  componentDidMount() {
    super.componentDidMount();
    $('#find-keywords-tutorial').removeClass('d-none');
  }

  initState(props: IDashboardProps): IDashboardState{
    let state = super.initState(props);
    return _.assign(state, {
    });
  }

  showAPIFilter(){
    return true;
  }

  executeSearch(keyword: string, recaptcha: string, apis: API[], countries: Country[], languages: Language[], types: KeywordType[]){
    return execute(keyword, recaptcha, apis, countries, languages, types);
  }

  results(params: Partial<IResultParams>): Promise<IResponse>{
    return results(params);
  }

  getKeywordSource(){
    return {
      sourceType: IKeywordsSourceType.SearchFork,
      id: this.state.searchId
    };
  }

  onFiltersLoaded(filters: IFilters){
    //Ignore filter APIs when API is provided in query
    if (!this.state.isFromQuery && filters.apis && filters.apis.length) {
      this.parameterRow.setSelectedAPIs(_.intersection(AllAPIs, filters.apis));
    }
    if (filters.countries && filters.countries.length) {
      let scs = _.intersection(AllCountries, filters.countries);
      this.parameterRow.setSelectedCountries(scs);
    }
    if (filters.languages && filters.languages.length) {
      this.parameterRow.setSelectedLanguages(_.intersection(AllLanguages, filters.languages));
    }
    if (filters.resultTypes && filters.resultTypes.length) {
      this.parameterRow.setSelectedTypes(_.intersection(AllResultTypes, filters.resultTypes));
    }
    super.onFiltersLoaded(filters);
  }

  onFilterProcessingDone() {
    const qsFilters = this.getQueryStringFilters();
    if (qsFilters.query) {
      this.keywordInput.setTagInputValue(qsFilters.query);
    }
    this.setState({keywordInputValue: qsFilters.query ? qsFilters.query : this.state.keywordInputValue}, () => {
      if (qsFilters.api) {
        const newFilters = _.merge({}, this.state.filters);
        newFilters.apis = [IdentifiersToAPI[qsFilters.api]];
        newFilters.countries = [CodeToCountry[qsFilters.country]];
        this.parameterRow.setSelectedAPIs(_.intersection(newFilters.apis, AllAPIs));
        this.parameterRow.setSelectedCountries(_.intersection(newFilters.countries, AllCountries));
        this.setState({filters: newFilters, isFromQuery: false}, () => {
          this.startUrlFiltersSearch(qsFilters);
        });
      } else {
        this.startUrlFiltersSearch(qsFilters);
      }
    });
  }

  showQuestionMarkAddOn(){
    return !isMobile;
  }

  deleteResults(params: IDeleteResultsParams){
    return deleteResults(params);
  }

  renderNumberOfKeywordsFound() {
    return <div className="h4">
      Found {number_format(this.state.pagination.nb_results)} unique keywords for {this.state.requestedKeyword}
    </div>;
  }

  renderTitleText(): string {
    return "Keyword Finder";
  }
}
