import {Language} from "../../../languages";

const SupportedLanguages = [
    Language.English,
    Language.Arabic,
    Language.ChineseSimplified,
    Language.Dutch,
    Language.French,
    Language.German,
    Language.Italian,
    Language.Japanese,
    Language.Korean,
    Language.NorwegianNorsk,
    Language.Polish,
    Language.PortuguesePortugal,
    Language.Russian,
    Language.Spanish,
    Language.Swedish,
    Language.Turkish
];

export default SupportedLanguages;