import * as React from "react";

import {All as AllCountries, Country, Names as CountryNames} from "../../lib/api/countries";
import {MySelect2 as Select2} from "../../lib/my-select-2";
import {getIntegerSelectedOptions} from "../../lib/util/html";

export interface CountrySelectProps {
    selected: Country[],
    onChange: (countries: Country[]) => void,
    multiple: boolean,
    id: string
}

interface CountrySelectState {
}

export class CountrySelect extends React.Component<CountrySelectProps, CountrySelectState> {


    handleChange = (e) => {
        const selected = getIntegerSelectedOptions(e.target);
        if(!_.isEqual(selected.sort(), this.props.selected.sort())){
            this.props.onChange(selected);
        }
    };

    render() {
        let {id} = this.props;
        return (
            <Select2
                id={id}
                multiple={this.props.multiple}
                data={AllCountries.map(country => {
                    return {
                        id: country,
                        text: CountryNames[country]
                    };
                })}
                options={
                    {
                        placeholder: 'Select A Country',
                    }
                }
                className="form-control select2"
                onChange={this.handleChange}
                value={this.props.selected}
            />
        );
    }

}

export default CountrySelect;
