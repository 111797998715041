import * as Promise from "bluebird";
import {IColumn} from "./search/columns";
import { Base64 } from 'js-base64';

export interface IPermissions{
  serpFilter: boolean,
  ctr_opportunity: boolean,
  kw_potential: boolean,
  seo_difficulty: boolean
}

export interface IConfig {
  allColumns: IColumn[],
  lastListAddedTo: number,
  auth: {
    authenticated: boolean,
    user: null | {
      id: number,
      plan: any,
      alwaysUsePreferredCountry: boolean,
      hasExpiredSubscription: boolean,
      preferredCountry: {
        id: number,
        code: string,
        name: string
      }
    }
  },
  rowLimit: number,
  blockedSearchRestartTime: number,
  ui: {
    permissions: IPermissions,
    keywordLimit?: number,
    trendDirection: string
  },
  selectMultipleCountries: boolean,
  selectMultipleApis: boolean,
  showAllMetricsToFreeUsers: boolean,
  recaptcha: {
    enable: boolean,
    site_key: string
  },
  planMultiKeywordSearchLimit: number,
  bigExportThreshold: number,
  globalNotification?: {
    title: string,
    description: string,
    color: string,
    bootstrapColor: string
  }
}

export function getConfig(): Promise<IConfig> {
  let res = window.kk;
  if(res.globalNotification){
    res.globalNotification = JSON.parse(Base64.decode(res.globalNotification));
  }
  return Promise.resolve(res);
}

export default getConfig;