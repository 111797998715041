import * as React from "react";
import * as _ from 'lodash';
import {Modal} from 'react-bootstrap';
import {
    IColumn,
    IKeywordResult,
    IPagination,
    IParams,
    IParams as IResultParams,
    IResponse
} from "../lib/api/keyword-keg/sf/results";
import {
    clearDefaultUserFilter,
    getUserFilters,
    IFilters,
    ISaveUserFilterResponse,
    IUserFilter,
    IUserFiltersResponse,
    saveUserFilter,
    updateFilters
} from "../lib/api/keyword-keg/search/filters";
import {IConfig} from "../lib/api/keyword-keg/config";
import {IToolbarHolderStateInterface, Toolbar} from "./common/toolbar";
import {getColumns} from "../lib/api/keyword-keg/search/columns";
import {getIndustries, IndustryInterface} from "../lib/api/keyword-keg/search/industries";
import number_format from "../lib/util/number-format";
import {IKeywordsSource} from "../lib/api/keyword-keg/keywords-source";
import {KeywordType as ResultType} from "../lib/keyword/keyword-types";
import KeywordSearchResultTable from "./keyword-search-result-table";
import {Codes as CountryCodes, Country} from "../lib/api/countries";
import {SessionExpiryNotification} from "./session-expiry-notification";
import * as Promise from "bluebird";
import {determineColumnsToUse} from "../lib/search/columns";

export interface IErrorBody {
    error: boolean,
    code: string,
    handled?: boolean,
    data?: {
        planLimit?: number,
        keywordCount?: number,
        planName?: string,
        regex?: string,
        keywords?: string[],
        keywordLengthLimit?: number
    }
}

export interface IKeywordViewerProps {
    config: IConfig,
    paginationList?: number[],
}

export interface IKeywordViewerState extends IToolbarHolderStateInterface {
    isRetrievingResults: boolean,
    resultsRetrievalError: boolean,
    resultsRetrievalErrorTitle?: string | React.ReactElement<any>,
    resultsRetrievalErrorBody?: string | React.ReactElement<any>,
    metricsUpdating: boolean,
    metricsUpdateProgress: number,
    metricsUpdatingModalShown: boolean,
    globalNotificationModalShown: boolean,
    metricsUpdatingModalSuppressed: boolean,
    filters?: IFilters,
    appliedFilters: IUserFilter,
    userFilters: IUserFilter[],
    keywordFilter?: string,
    requestedPage?: number,
    searchId?: number,
    pagination?: IPagination,
    columns?: IColumn[],
    sortOrder?: 'asc' | 'desc',
    sortColumn?: string,
    preventSorting?: boolean,
    industries?: { [id: number]: IndustryInterface },
    config: IConfig,
    currency?: {
        rate: number,
        symbol: string
    }
}

export abstract class KeywordViewer<P extends IKeywordViewerProps, S extends IKeywordViewerState>
    extends React.Component<P, S> {

    static defaultProps = {
        paginationList: [50, 100, 500, 1000, 2000, 5000]
    };

    protected topProgressBar = new Mprogress({
        template: 3
    });
    private processingMetricsModalProgress: Mprogress;

    initState(props: IKeywordViewerProps): IKeywordViewerState {
        return {
            currency: {symbol: "$", rate: 1},
            resultsPerPage: 50,
            industries: {},
            sortOrder: 'desc',
            sortColumn: 'monthly_volume',
            resultsRetrievalError: false,
            isRetrievingResults: false,
            requestedPage: 1,
            metricsUpdating: false,
            metricsUpdateProgress: 0,
            metricsUpdatingModalShown: false,
            metricsUpdatingModalSuppressed: false,
            userFilters: [],
            appliedFilters: null,
            columns: [],
            globalNotificationModalShown: false,
            config: props.config,

            searchedCountry: 'all',
            keywords: [],
            filteredKeywords: [],
            negativeKeywords: [],
            selectedKeywords: [],
        };
    }

    protected getResultsCallbacks: { (): void; }[] = [];
    protected table: KeywordSearchResultTable;

    toolbar: Toolbar;
    myUserFilter: IUserFilter;

    componentDidMount(): void {
        $('#application-loading').addClass('d-none');
    }

    loadColumns(): Promise<void> {
        return getColumns().then((response) => {
            // console.log("Keyword view. loadColumns", {columns: response.columns});
            this.setState({
                columns: response.columns
            });
        });
    }

    loadIndustries(): Promise<void> {
        return getIndustries().then((industries: IndustryInterface[]) => {
            this.setState({
                industries: industries
            });
        });
    }

    loadFilters(): Promise<void> {
        return getUserFilters().then((response: IUserFiltersResponse) => {
            let filters = JSON.parse(response.defaultFilter.filters);
            this.setState({
                filters: filters,
                appliedFilters: response.defaultFilter,
                userFilters: response.filters
            });
        });
    }

    updateFilters(changes: IFilters, userFilter?: IUserFilter, then?: () => any) {
        if (!this.state.filters) {
            return;
        }
        changes = changes || {};
        const newFilters = _.assign({}, this.state.filters, changes);
        if (userFilter != null) {
            saveUserFilter(userFilter.name, userFilter.id, newFilters).then((res: ISaveUserFilterResponse) => {
                this.setState({
                    appliedFilters: res.filter,
                    userFilters: res.filters
                })
            });
        } else {
            updateFilters(newFilters).then(() => {
            });
            clearDefaultUserFilter();
            this.setState({
                appliedFilters: null
            })
        }
        this.setState({filters: newFilters}, then);
    };

    updateFiltersAndGetResults = (changes: IFilters, userFilter?: IUserFilter, then?: () => void) => {
        let proceed = () => {
            this.updateFilters(changes, userFilter, () => {
                this.setState({
                    requestedPage: 1
                }, () => {
                    this.getResults(then);
                });
            });
        };
        if (!userFilter) {
            this.setState({
                appliedFilters: null
            }, proceed);
        } else {
            proceed();
        }
    };

    applyUserFilterAndGetResults = (userFilter: IUserFilter) => {
        let filters = userFilter.filters ? JSON.parse(userFilter.filters) : {};
        let newFilters = _.assign({}, this.state.filters, filters);
        this.updateFilters(newFilters, userFilter, () => {
            this.setState({
                requestedPage: 1
            }, () => {
                this.getResults();
            });
        });
    };

    getCountryParameter() {
        if (this.props.config.auth.user) {
            return this.props.config.auth.user.preferredCountry.code;
        }
        return "us";
    }

    prepareResultsParams(): Partial<IResultParams> {
        let params = {
            page: this.state.requestedPage,
            pageSize: this.state.resultsPerPage,
            keywordFilter: this.state.keywordFilter,
            negativeKeywords: this.state.negativeKeywords
        };
        if (this.state.filters && this.state.filters.sort && !this.state.preventSorting) {
            params = _.assign({}, params, {
                sortColumn: this.state.filters.sort.column,
                sortOrder: this.state.filters.sort.order
            });
        }
        params = _.assign({}, params, {
            country: this.getCountryParameter(),
            filters: this.state.filters
        });

        return params;
    }

    showResults() {
        return true;
    }

    // noinspection JSUnusedGlobalSymbols
    protected _getResultsDebounced = _.debounce(() => {
        this.clearError();
        this.setState({
            isRetrievingResults: true
        });
        let params = this.getResultParams();
        this.results(params).then((response) => {
            this.processKeywordsRespose(response);
        }).catch((error) => {
            this.resultRetrievalFailed(error);
        });
    }, 150);

    abstract results(params: Partial<IResultParams>): Promise<IResponse>;

    getResults(then?: () => void, preserveModalSuppression?: boolean) {
        if (then) {
            this.getResultsCallbacks.push(then);
        }
        if (!preserveModalSuppression && this.state.metricsUpdatingModalSuppressed) {
            this.setState({
                metricsUpdatingModalSuppressed: false
            });
        }
        this._getResultsDebounced();
    }

    handleSortUpdate = (column: string, order: "asc" | "desc") => {
        this.doHandleSortUpdate(column, order);
    };

    doHandleSortUpdate(column: string, order: "asc" | "desc", then?: () => void) {
        this.updateFiltersAndGetResults({
            sort: {
                column: column,
                order: order
            }
        }, this.state.appliedFilters, then);
    };

    handleTableSelectionChange = (selectedKeywords: IKeywordResult[], all: boolean) => {
        this.setState({
            selectedKeywords: selectedKeywords,
            allKeywordsSelected: all
        });
    };

    handlePageSelection = (page: number) => {
        this.setState({
            requestedPage: page
        }, () => {
            this.getResults();
        });
    };

    handleResultsPerPageChanged = (rpp: number) => {
        this.setState({
            resultsPerPage: rpp,
            requestedPage: 1
        }, () => {
            this.getResults();
        });
    };

    updateKeywordFilter = (value: string) => {
        this.setState({
            keywordFilter: value,
            requestedPage: 1
        }, () => {
            this.getResults();
        });
    };

    onNegativeKeywordsChanged = (keywords: string[], getResults: boolean) => {
        let array1 = keywords ? keywords.slice() : [];
        let array2 = this.state.negativeKeywords ? this.state.negativeKeywords.slice() : [];

        if (_.isEqual(array1.sort(), array2.sort())) {
            return;
        }
        this.setState({
            negativeKeywords: keywords,
            requestedPage: 1
        }, () => {
            if (getResults) {
                this.getResults();
            }
        });
    };

    onHideSearchError = () => {
        this.setState({
            resultsRetrievalError: false
        });
    };

    onHideMetricsUpdatingModal = () => {
        this.setState({
            metricsUpdatingModalShown: false,
            metricsUpdatingModalSuppressed: true
        });
    };

    onHideGlobalNotificationModal = () => {
        this.setState({
            globalNotificationModalShown: false
        });
    };

    onUpdatingMetricsResponse(response: IResponse) {
        this.setState({
            metricsUpdatingModalShown: !this.state.metricsUpdatingModalSuppressed,
            metricsUpdating: true,
            metricsUpdateProgress: response.progress
        });
    }

    clearError() {
        this.setState({
            resultsRetrievalError: false,
            resultsRetrievalErrorTitle: null,
            resultsRetrievalErrorBody: null
        });
    }

    componentDidUpdate(prevProps: P, prevState: S) {
        if (this.state.isRetrievingResults !== prevState.isRetrievingResults) {
            if (this.state.isRetrievingResults) {
                this.topProgressBar.start();
            } else {
                this.topProgressBar.end();
            }
        }

        if (this.state.metricsUpdateProgress != prevState.metricsUpdateProgress) {
            if (this.processingMetricsModalProgress) {
                this.processingMetricsModalProgress.set(this.state.metricsUpdateProgress / 100);
            }
        }
    }

    onResultsResponse(response: IResponse) {
        let config = this.state.config;
        if (response.perm) {
            config.ui.permissions = response.perm;
        }
        if (response.maxResults) {
            config.rowLimit = response.maxResults === -1 ? null : response.maxResults;
        }
        this.setState({
            metricsUpdating: false,
            metricsUpdatingModalShown: false,
            isRetrievingResults: false,
            pagination: response.pagination,
            keywords: response.results,
            sortOrder: response.sort_order,
            sortColumn: response.sort_column,
            currency: response.currency,
            searchedCountry: response.country,
            config,
            columns: determineColumnsToUse(response.columns, response.country),
        }, () => {
            _.each(this.getResultsCallbacks, (then) => {
                then();
            });
            this.getResultsCallbacks = [];
        });
    }

    processKeywordsRespose(response: IResponse) {
        if (response.status == 'updating_metrics') {
            this.onUpdatingMetricsResponse(response);
            setTimeout(() => {
                this.getResults(null, true);
            }, 2000);
        } else {
            this.onResultsResponse(response);
        }
    }

    resultRetrievalFailed(e?: IErrorBody) {
        let searchErrorTitle = null;
        let searchErrorBody = null;
        console.log(e)
        if (e && e.error) {
            if (e.handled) {
                return;
            }
            if (e.code == 'EXCEEDED_PLAN_MULTI_KEYWORD_SEARCH_LIMIT') {
                searchErrorTitle = "Multi-Keyword Search Limit Exceeded";
                if (e.data.planName == 'Free') {
                    searchErrorBody = `As a free user, you can only search up to
        ${e.data.planLimit} keywords separated by commas and newlines in one multi-keyword search.
        You tried to search ${e.data.keywordCount} keywords. Please try again with fewer keywords.`;
                } else {
                    searchErrorBody = `Under the ${e.data.planName} plan, you can only search up to
        ${e.data.planLimit} keywords separated by commas and newlines in one multi-keyword search.
        You tried to search ${e.data.keywordCount} keywords. Please try again with fewer keywords.`;
                }
            } else if (e.code == 'KEYWORDS_TOO_LONG') {
                const keywords = (e.data.keywords || []);
                searchErrorTitle = `${keywords.length === 1 ? 'Keyword' : 'Keywords'} Too Long`;
                searchErrorBody = <div>
                    <p>{keywords.length === 1 ? 'This keyword exceeds' : 'These keywords exceed'} the input-keyword-length limit:</p>
                    <ul>
                        {keywords.map((kw, i) => <li key={i}><strong>{kw}</strong></li>)}
                    </ul>
                    <p>Individual keywords must not exceed {e.data.keywordLengthLimit} characters.</p>
                </div>;
            } else if (e.code == 'FREE_USER_CANNOT_EXECUTE_MULTI_KEYWORD_SEARCH') {
                searchErrorTitle = "Multi-Keyword Search Not Allowed";
                searchErrorBody = `As a free user, you are not allowed to search multiple keywords separated by commas and newlines in one search.
        You tried to search ${e.data.keywordCount} keywords. Please try again with one keyword.`;
            } else if (e.code == 'BAD_REGEX') {
                searchErrorTitle = "Bad Regular Expression";
                searchErrorBody = this.renderBadRegexBody(e);
            }
        }
        this.setState({
            resultsRetrievalError: true,
            resultsRetrievalErrorTitle: searchErrorTitle,
            resultsRetrievalErrorBody: searchErrorBody,
            isRetrievingResults: false
        });
    };

    renderBadRegexBody(e: IErrorBody) {
        return <span>
      {`The string `}
            <strong>{e.data.regex}</strong>
            {` is not a valid MySQL regular expression.`}
    </span>;
    };

    renderSearchError() {
        return <Modal show={this.state.resultsRetrievalError} onHide={this.onHideSearchError}>
            <Modal.Header>
                <Modal.Title>{this.state.resultsRetrievalErrorTitle || `Something is not right here`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {this.state.resultsRetrievalErrorBody
                        || `The server encountered a problem while processing your request. Please try again later. We apologize for this.`}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-default" onClick={this.onHideSearchError}>Close</button>
            </Modal.Footer>
        </Modal>;
    }

    onProcessingMetricsModalShown = () => {
        feather.replace();
        this.processingMetricsModalProgress = new Mprogress({
            template: 1,
            parent: "#processing-metrics-modal-progress"
        });
    };

    renderMetricsUpdatingModal() {
        return <Modal
            id="processing-modal"
            show={this.state.metricsUpdatingModalShown}
            onHide={this.onHideMetricsUpdatingModal}
            onShow={this.onProcessingMetricsModalShown}
        >
            <Modal.Header>
                <div className={"loading spinner icon " + (this.state.metricsUpdateProgress ? "d-none" : "")}></div>

                <div className={"" + (this.state.metricsUpdateProgress ? "" : "d-none")}>
                    <figure className="icon">
                        <i data-feather="clock" width="64" height="64"></i>
                        {/*<i className="far fa-clock font-lg"></i>*/}
                    </figure>
                    <h5 className="modal-title">Please wait...</h5>
                </div>
                <button type="button" className="close" onClick={this.onHideMetricsUpdatingModal} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <p className="lead text-center">We're loading the latest
                    metrics {this.state.metricsUpdateProgress ? `(${number_format(this.state.metricsUpdateProgress, 2)}%)` : ''}...</p>
                <div
                    id={"processing-metrics-modal-progress"}
                    className={"progress progress-sm " + (!this.state.metricsUpdateProgress ? "d-none" : "")}
                >
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={this.onHideMetricsUpdatingModal}>Close
                </button>
            </Modal.Footer>
        </Modal>;
    }

    renderGlobalNotificationModal() {
        let n = this.props.config.globalNotification;
        if (n) {
            return <Modal
                id="processing-modal"
                show={this.state.globalNotificationModalShown}
                onHide={this.onHideGlobalNotificationModal}
            >
                <Modal.Header>
                    <h5 className="modal-title">{n.title}</h5>
                </Modal.Header>
                <Modal.Body dangerouslySetInnerHTML={{__html: n.description}}>
                </Modal.Body>
                <div className="modal-actions has-multiple">
                    <button type="button" className="btn btn-secondary"
                            onClick={this.onHideGlobalNotificationModal}>Close
                    </button>
                </div>
            </Modal>;
        }
    }

    renderContentWrapper(content: React.ReactElement<any>) {
        return (
            <div className={"dashboard"}>
                {content}
            </div>);
    }

    renderMetricsUpdatingSection(): React.ReactElement<any> {
        if (!this.state.metricsUpdating) {
            return null;
        }
        return (
            <div className="container-fluid py-4">
                <h4 className="regular mt-4 m-md-0">
                    Loading latest metrics ({`${number_format(this.state.metricsUpdateProgress, 2)}%`})...
                </h4>
            </div>
        );
    }

    renderTopSection(): React.ReactElement<any> {
        return (<div className='keyword-view-render-top-section-wrapper'>
            {this.renderMetricsUpdatingSection()}
            <hr/>
        </div>);
    }

    abstract handleDeleteKeywords: (keywords: IKeywordResult[], all: boolean, then: () => void) => void;

    abstract getKeywordSource(): IKeywordsSource;

    abstract getResultParams: () => Partial<IParams>;

    abstract getExportName: () => string;

    onFilterResultTypeChange?: (selectedTypes: ResultType[]) => void;

    protected enableResultTypeFilter = false;

    protected hasDeducedTypes = false;

    renderTopPaginationLhs(): React.ReactElement<any> {
        return null;
    }

    getSeedKeyword(): string {
        return null;
    }

    isProcessing() {
        return this.state.isRetrievingResults;
    }

    isSortingPrevented(): boolean {
        return false;
    }

    renderLhsToolbarElements(): React.ReactElement<any> | React.ReactElement<any>[] {
        return null;
    }

    renderDeleteKeywordsButton(): React.ReactElement<any> {
        return null;
    }

    onBeforeExport: () => boolean;

    showSubscribeBanner() {
        if (this.state.config) {
            let {ctr_opportunity, kw_potential, seo_difficulty, serpFilter} = this.state.config.ui.permissions;
            if (ctr_opportunity && kw_potential && seo_difficulty && serpFilter) {
                return false;
            }
        }
        return !this.props.config.auth.user || !this.props.config.auth.user.plan;
    }

    renderFoundResultsInformation(): React.ReactElement<any> {
        return null;
    }

    renderToolbar() {
        return (
            <Toolbar
                ref={(me) => {
                    this.toolbar = me;
                }}
                lhsToolbarElements={this.renderLhsToolbarElements()}
                deleteKeywordsButton={this.renderDeleteKeywordsButton()}
                config={this.props.config}
                paginationList={this.props.paginationList}
                handleResultsPerPageChanged={this.handleResultsPerPageChanged}
                onFilterKeywordUpdate={this.updateKeywordFilter}
                onFilterResultTypeChange={this.onFilterResultTypeChange}
                pageKeywords={this.state.keywords}
                updateFilters={this.updateFiltersAndGetResults}
                applyUserFilter={this.applyUserFilterAndGetResults}
                selectedKeywords={this.state.selectedKeywords}
                allKeywordsSelected={this.state.allKeywordsSelected}
                filters={this.state.filters}
                loadedUserFilters={this.state.userFilters}
                appliedFilters={this.state.appliedFilters}
                resultsPerPage={this.state.resultsPerPage}
                handleDeleteKeywords={this.handleDeleteKeywords}
                negativeKeywords={this.state.negativeKeywords}
                industries={this.state.industries}
                currency={{symbol: this.state.currency.symbol, rate: this.state.currency.rate}}
                onNegativeKeywordsChanged={this.onNegativeKeywordsChanged}
                processing={this.isProcessing()}
                keywordsSource={this.getKeywordSource()}
                getResultParams={this.getResultParams}
                getExportName={this.getExportName}
                onBeforeExport={this.onBeforeExport}
                pagination={this.state.pagination}
                enableResultTypeFilter={this.enableResultTypeFilter}
                searchColumns={this.state.columns}
            />
        );
    }

    renderSearchResultsTable() {
        return (
            <KeywordSearchResultTable
                ref={(me) => {
                    this.table = me;
                }}

                topPaginationLHS={this.renderTopPaginationLhs()}
                config={this.state.config}
                seedKeyword={this.getSeedKeyword()}
                selectedCountries={(this.state.filters.countries || [Country.UnitedStates]).map((c) => CountryCodes[c])}
                preferredCountry={this.props.config.auth.user ? this.props.config.auth.user.preferredCountry.code : "all"}
                alwaysUsePreferred={this.props.config.auth.user ? this.props.config.auth.user.alwaysUsePreferredCountry : false}
                searchResults={this.state.keywords}
                searchColumns={this.state.columns}
                sortColumn={this.state.sortColumn}
                sortOrder={this.state.sortOrder}
                onSortUpdate={this.handleSortUpdate}
                resultsPerPage={this.state.resultsPerPage}
                currencyRate={this.state.currency.rate}
                currencySymbol={this.state.currency.symbol}
                onSelectionChange={this.handleTableSelectionChange}
                showSubscribeBanner={this.showSubscribeBanner()}
                pagination={this.state.pagination}
                handlePageSelection={this.handlePageSelection}
                sortingPrevented={this.isSortingPrevented()}
                processing={this.isProcessing()}
                hasDeducedTypes={this.hasDeducedTypes}
                keywordsSource={this.getKeywordSource()}
                columns={this.state.columns}
            />
        );
    }

    render() {
        return this.renderContentWrapper(
            <section className="section bg-light border-bottom search-form">
                {this.renderTopSection()}
                {this.showResults() && this.renderFoundResultsInformation()}
                {this.showResults() && this.renderToolbar()}
                {this.showResults() && this.renderSearchResultsTable()}
                <SessionExpiryNotification/>
                {this.renderSearchError()}
                {this.renderMetricsUpdatingModal()}
                {this.renderGlobalNotificationModal()}
            </section>
        );
    }

    triggerGlobalNotificationModal = () => {
        this.setState({
            globalNotificationModalShown: true
        });
    };

    getGlobalNotification() {
        let n = this.props.config.globalNotification;
        if (n) {
            return (
                <div
                    className={`mb-5 text-center alert alert-${n.bootstrapColor}`}
                    style={{cursor: 'pointer'}}
                    onClick={this.triggerGlobalNotificationModal}
                >
                    <strong>{n.title}</strong>
                </div>
            );
        }
    }
}
