import * as $ from "jquery";
import * as Promise from "bluebird";

export interface IResponse {
  keywords: string[]
}

export function getNegativeKeywords(): Promise<IResponse> {
  return new Promise<IResponse>((resolve, reject) => {
    $.ajax({
      url: `/negative-keywords`,
      method: "GET",
      success: function (response: {error: boolean, data: IResponse}) {
        resolve(response.data);
      },
      error: function (jqXHR: {responseJSON: {message: string}}) {
        reject(new Error(`/negative-keywords returned an error : ${jqXHR.responseJSON.message}`));
      }
    } as JQueryAjaxSettings)
  });
}

export default getNegativeKeywords;