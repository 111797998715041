import {Country} from "../../../countries";

const SupportedCountries = [
    Country.Australia,
    Country.Austria,
    Country.Canada,
    Country.France,
    Country.Germany,
    Country.India,
    Country.Italy,
    Country.Ireland,
    Country.UnitedKingdom,
    Country.Switzerland,
    Country.UnitedStates
];

export default SupportedCountries