import * as React from "react";
import {Button, Modal} from "react-bootstrap";
import {getExport} from "../../lib/api/keyword-keg/export/get";
import {createExport} from "../../lib/api/keyword-keg/export/create-v2";
import {IKeywordsSource, IKeywordsSourceType} from "../../lib/api/keyword-keg/keywords-source";
import {IKeywordResult, IPagination, IParams as IResultParams} from "../../lib/api/keyword-keg/sf/results";
import {IConfig} from "../../lib/api/keyword-keg/config";

export interface IExportDropdownProps {
    keywords: IKeywordResult[],
    getName: () => string,
    getCountry: () => string,
    getLanguage: () => string,
    onBeforeExport?: () => boolean,
    checkStatusTimeout: number,
    keywordsSource: IKeywordsSource,
    exportAll: boolean,
    getResultParams: () => Partial<IResultParams>,
    showSelected: boolean,
    selectedCount: number,
    showSubscriptionError: boolean,
    listId?: number,
    pagination: IPagination,
    config: IConfig,
    onExportStart?: () => void,
    onExportComplete?: () => void
}

export interface IExportDropdownState {
    currentId?: number,
    showSubscriptionErrorModal?: boolean,
    working?: boolean
}

export class ExportDropdown extends React.Component<IExportDropdownProps, IExportDropdownState> {

    state: IExportDropdownState = {
        currentId: null,
        showSubscriptionErrorModal: false,
        working: false
    };

    handleChange = (eventKey: string) => {
        if (this.props.onBeforeExport && !this.props.onBeforeExport()) {
            return;
        }
        if (this.props.showSubscriptionError) {
            this.setState({
                showSubscriptionErrorModal: true
            });
            return;
        }
        this.setState({
            working: true
        }, () => {
            this.props.onExportStart && this.props.onExportStart();
            let keywords = _.map(this.props.keywords, (k) => k.search_result);
            let resultIds = _.map(this.props.keywords, (k) => k.result_id);
            createExport({
                mType: eventKey,
                name: this.props.getName(),
                keywords: keywords,
                resultIds: resultIds,
                country: this.props.getCountry(),
                language: this.props.getLanguage(),
                listId: this.props.listId,
                source: this.props.keywordsSource,
                all: this.props.exportAll,
                resultParams: this.props.getResultParams()
            }).then((response) => {
                this.setState({
                    currentId: response.id
                });
                setTimeout(this.checkStatus, this.props.checkStatusTimeout);
            });
        });
    };

    checkStatus = () => {
        return getExport(this.state.currentId).then((response) => {
            if (response.status == "running") {
                setTimeout(this.checkStatus, this.props.checkStatusTimeout);
            } else {
                this.props.onExportComplete && this.props.onExportComplete();
                this.download(response.download_path);
            }
        });
    };

    download = (path?: string) => {
        if (path) {
            window.location = path;
        } else {
            window.location = `/export/${this.state.currentId}/download`;
        }
        this.setState({
            currentId: null,
            working: false
        });
    };

    getButtonText() {
        if (this.state.working) {
            return <span className="fas fa-spinner fa-spin"/>;
        }
        return this.props.showSelected ? [`Export `, <span key={'span'} className="badge badge-light">{this.props.selectedCount}</span>] : 'Export all';
    }

    isBigExport() {
        if (this.props.showSelected) {
            if (this.props.selectedCount > this.props.config.bigExportThreshold) {
                return true;
            }
        } else {
            if (this.props.pagination.nb_results > this.props.config.bigExportThreshold) {
                return true;
            }
        }
        return false;
    }

    canExportPDF() {
        if (this.props.showSelected) {
            if (this.props.selectedCount > 10000) {
                return false;
            }
        } else {
            if (this.props.pagination.nb_results > 10000) {
                return false;
            }
        }
        return true;
    }

    render() {
        return (
            <div className="btn-group nogroup mt-3 mt-md-0 mr-md-3">
                <button
                    disabled={this.state.working}
                    id="export-dropdown" type="button" className="btn btn-success dropdown-toggle"
                    data-toggle="dropdown">
                    {this.getButtonText()}
                </button>
                <div className="dropdown-menu">
                    <a
                        className="dropdown-item"
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            this.handleChange('csv');
                        }}
                    >
                        <i className="fas fa-file-csv mr-2"/>CSV
                    </a>
                    {!this.isBigExport() && <a
                        className="dropdown-item"
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            this.handleChange('excel');
                        }}
                    >
                        <i className="fas fa-file-excel mr-2"/>Excel
                    </a>}
                    {this.canExportPDF() && <a
                        className="dropdown-item"
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            this.handleChange('pdf');
                        }}
                    >
                        <i className="fas fa-file-pdf mr-2"/>PDF
                    </a>}
                    {this.props.keywordsSource.sourceType === IKeywordsSourceType.UploadedDataFile
                    && <a
                        className="dropdown-item"
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            this.handleChange('append_to_data_file');
                        }}
                    >
                        <i className="fas fa-file-excel mr-2"/>Append To Data File
                    </a>}
                </div>
                <Modal show={this.state.showSubscriptionErrorModal}
                       onHide={() => this.setState({showSubscriptionErrorModal: false})}>
                    <Modal.Header>
                        <Modal.Title>Subscription Needed</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>You need an active subscription to any of the Keyword Keg plans to export this data.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='default'
                                onClick={() => this.setState({showSubscriptionErrorModal: false})}>Close</Button>
                        <a className="btn btn-danger" href="/pricing">Subscribe</a>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

export default ExportDropdown;
