import * as $ from "jquery";
import * as Promise from "bluebird";

export interface IndustryInterface {
  id: number,
  name: string,
  parent_id: number
}

interface IResponse {
  industries: {
    [id: number]: IndustryInterface
  }
}

export function getIndustries(): Promise<{[id: number]: IndustryInterface}> {
  return new Promise<{[id: number]: IndustryInterface}>((resolve, reject) => {
    $.ajax({
      url: "/search/industries",
      method: "GET",
      dataType: "json",
      success: function (response: {error: boolean, data: IResponse}) {
        resolve(response.data.industries);
      },
      error: function (jqXHR: {responseJSON: {message: string}}) {
        reject(new Error(`/search/industries returned an error : ${jqXHR.responseJSON.message}`));
      }
    } as JQueryAjaxSettings)
  });
}

export default getIndustries;