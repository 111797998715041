import * as $ from "jquery";
import * as Promise from "bluebird";
import {IKeywordsSource, KeywordsSourceTypeNames} from "../keywords-source";
import {IKeywordResult, IParams as IResultParams, addResultParams} from "../sf/results";

export interface IResponse {
  id: number
}

export function createExport(
  params: {
    mType: string,
    name: string,
    keywords: string[],
    resultIds: number[],
    country: string,
    language: string,
    listId?: number,
    source: IKeywordsSource,
    all: boolean,
    resultParams?: Partial<IResultParams>
  }
): Promise<IResponse> {
  return new Promise<IResponse>((resolve, reject) => {
    let sourceName = KeywordsSourceTypeNames[params.source.sourceType];
    let data: {[name: string]: any} = {
      keywords: JSON.stringify(params.keywords),
      resultIds: JSON.stringify(params.resultIds),
      __csrf_token: $("meta[name='csrf-token']").attr('content'),
      source_name: sourceName,
      source_id: params.source.id,
      all: params.all,
      type: params.mType,
      name: params.name,
      country: params.country,
      language: params.language,
      listId: params.listId
    };
    if (params.resultParams) {
      data = addResultParams(data, params.resultParams);
    }
    $.ajax({
      url: `/export`,
      method: "POST",
      data: data,
      success: function (response: {error: boolean, data: IResponse}) {
        resolve(response.data);
      },
      error: function (jqXHR: {responseJSON: {message: string}}) {
        reject(new Error(`/export returned an error : ${jqXHR.responseJSON.message}`));
      }
    } as JQueryAjaxSettings)
  });
}

export default createExport;