import * as React from 'react';
import {IKeywordResult} from "../../lib/api/keyword-keg/sf/results";
import {Badge, Tooltip} from "react-bootstrap";
import {KeywordType, KeywordTypeNames} from "../../lib/keyword/keyword-types";
import {IdentifiersToAPI, Names} from "../../lib/api/suggest/fetchers";
import {CodeToCountry, Names as CountryNames} from "../../lib/api/countries";
import {CodeToLanguage, Names as LanguageNames} from "../../lib/api/languages";
import {MD5} from 'crypto-js';
import {KkOverlayTrigger} from "../common/kk-overlay-trigger";

export interface IKeywordSourceProps {
    source: IKeywordResult,
    id: string
}

interface IKeywordSourceState {

}

const TypeTags: { [key: string]: { name: string, flag: string } } = {
    "alphabetical": {
        name: KeywordTypeNames[KeywordType.Alphabetical],
        flag: "AL"
    },
    "questions": {
        name: KeywordTypeNames[KeywordType.Questions],
        flag: "QU"
    },
    "buyer-intent": {
        name: KeywordTypeNames[KeywordType.BuyerIntent],
        flag: "BI"
    },
    "product-info": {
        name: KeywordTypeNames[KeywordType.ProductInfo],
        flag: "PI"
    },
    "prepositions": {
        name: KeywordTypeNames[KeywordType.Prepositions],
        flag: "PR"
    },
    "comparisons": {
        name: KeywordTypeNames[KeywordType.Comparisons],
        flag: "CO"
    },
};

export class KeywordSource extends React.Component<IKeywordSourceProps, IKeywordSourceState> {

    props: IKeywordSourceProps;
    state = {};

    getFlags(
        idPrefix: string,
        labels: string[],
        lableToDisplayName: (label: string) => string,
        toolTipHeading: string[],
        labelToBadge: (label: string) => string,
        badgeClass: string) {
        let valid = _.filter(labels, (l) => {
            return !!lableToDisplayName(l);
        });
        if (valid.length == 0) {
            return null;
        }
        let tooltipText = toolTipHeading;
        tooltipText = tooltipText.concat(_.map(valid, (l) => {
            return lableToDisplayName(l);
        }));
        let tooltip = <Tooltip id={`${idPrefix}-tooltip-${this.props.id}`}>
            {_.map(tooltipText, (line) => {
                    return (
                        <div key={MD5(line).toString()}>
                            {line}
                        </div>);
                }
            )}
        </Tooltip>;
        let badgeLabel = valid[0];
        let superscript = "";
        if (valid.length > 1) {
            superscript += `+${valid.length - 1}`;
        }
        return <KkOverlayTrigger
            key={`${idPrefix}-${this.props.id}-${badgeLabel}`}
            placement="top" overlay={tooltip}>
            <span className={`rounded-pill mr-1 badge ${badgeClass}`}>
                {labelToBadge(badgeLabel)}
                {superscript.length > 0 && <sup>{superscript}</sup>}
            </span>
        </KkOverlayTrigger>;
    }

    getAPIFlags() {
        return this.getFlags(
            'api',
            this.props.source.apis,
            api => Names[IdentifiersToAPI[api]],
            [
                "Suggest APIs",
                "============"
            ],
            api => {
                if (api === 'keywordkeg') {
                    return 'KK';
                }
                return api.slice(0, 2).toUpperCase();
            },
        "badge-secondary");
    }

    getCountryFlags() {
        return this.getFlags(
            'country',
            this.props.source.countries,
            country => CountryNames[CodeToCountry[country]],
            [
                "Countries",
                "========="
            ],
            country => country.toUpperCase(),
            "badge-info text-white");
    }

    getLanguageFlags() {
        return this.getFlags(
            'language',
            this.props.source.languages,
            language => LanguageNames[CodeToLanguage[language]],
            [
                "Languages",
                "============"
            ],
            language => language.toUpperCase(),
            "");
    }

    getTypeFlags() {
        return this.getFlags(
            'type',
            this.props.source.types,
            resultType => TypeTags[resultType] ? TypeTags[resultType].name : null,
            [
                "Result Types",
                "============"
            ],
            resultType => TypeTags[resultType].flag,
            "badge-success");
    }

    render() {
        return <span>
      {/*this.getLanguageFlags()*/}
            {this.getAPIFlags()}
            {this.getCountryFlags()}
            {this.getTypeFlags()}
    </span>;
    }

}

export default KeywordSource;
