import * as React from "react";
import {IUserFilter} from "../../../lib/api/keyword-keg/search/filters";

export interface ISaveFilterFormProps{
  filterName: string,
  userFilter: IUserFilter
  handleFilterNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  handleSaveFilter: () => void
}

interface ISaveFilterFormState{

}

export class SaveFilterForm extends React.Component<ISaveFilterFormProps, ISaveFilterFormState> {

  shouldComponentUpdate(nextProps: ISaveFilterFormProps, nextState: ISaveFilterFormState){
    return this.props.filterName != nextProps.filterName
    || this.props.userFilter !== nextProps.userFilter;
  }

  render(): JSX.Element | false | null {
    return (
        <div className="input-group">
          <input
              type="text"
              className="form-control filter-name-input"
              placeholder="Enter filter name"
              value={this.props.filterName || ''}
              onChange={this.props.handleFilterNameChange}
          />
          <div className="input-group-append">
            <button
                type="button"
                className="btn btn-primary btn-rounded"
                disabled={!this.props.filterName}
                onClick={this.props.handleSaveFilter}
            >
              {this.props.userFilter ? `Save Filter` : `Create Filter`}
            </button>
          </div>
        </div>
    );
  }
}
