import * as React from "react";
import * as ReactDOM from "react-dom";
import * as _ from "lodash";
import * as $ from "jquery";
import {Popover} from "react-bootstrap";
import {KeywordDetailsModal} from "./common/keyword-details-modal";
import {TableBody} from './search-table/table-body';
import {IConfig} from "../lib/api/keyword-keg/config";
import {ISource} from "../lib/api/keyword-keg/sf/sources";
import {SERPLegend} from "./search-table/serp-legend";
import {IColumn, IKeywordResult, IPagination} from "../lib/api/keyword-keg/sf/results";
import {PaginationMaker} from "./common/pagination-maker";
import {binarySearch} from '../lib/util/binary-search';
import {IKeywordsSource} from "../lib/api/keyword-keg/keywords-source";
import {KkOverlayTrigger} from "./common/kk-overlay-trigger";


const DEFAULT_SORT_ORDER: { [columnId: string]: string } = {
    'search_result': 'asc',
    '*': 'desc'
};

type SearchSourcesType = { [keyword: string]: ISource };

export interface IProps {
    config: IConfig,
    seedKeyword?: string,
    selectedCountries: string[],
    preferredCountry: string,
    alwaysUsePreferred: boolean,
    searchResults: IKeywordResult[],
    searchColumns: IColumn[],
    searchSources?: SearchSourcesType,
    hasDeducedTypes?: boolean,
    sortColumn: string,
    sortOrder: "asc" | "desc",
    currencyRate: number,
    currencySymbol: string,
    onSortUpdate: (column: string, order: string) => void,
    resultsPerPage: number,
    allowSelect?: boolean,
    sortingPrevented?: boolean,
    onSelectionChange?: (selected: IKeywordResult[], all: boolean) => void,
    showSubscribeBanner: boolean,
    pagination: IPagination,
    handlePageSelection: (page: number) => void,
    keywordsSource: IKeywordsSource,
    columns: IColumn[],
    processing?: boolean,
    topPaginationLHS?: React.ReactElement<any> | boolean,
}

export interface IState {
    selected?: IKeywordResult[],
    selectedIds?: number[],
    allKeywordsSelected?: boolean,
    showDetailsModal?: boolean,
    detailsModalKeyword?: IKeywordResult,
    windowScrollHanlder?: () => void
}

class KeywordSearchResultTable extends React.Component<IProps, IState> {

    static defaultProps: Partial<IProps> = {
        allowSelect: true,
        sortingPrevented: false,
        searchSources: null,
        onSelectionChange: () => {
        },
        hasDeducedTypes: false
    };

    props: IProps;

    state: IState = {
        selected: [],
        selectedIds: [],
        showDetailsModal: false,
        detailsModalKeyword: null,
        allKeywordsSelected: false
    };

    redirectFreeUser() {
        if (this.props.showSubscribeBanner) {
            window.location.href = "/pricing";
            return true;
        }
        return false;
    }

    handleHeaderClick = (column: { id: string, name: string }) => {
        if (this.redirectFreeUser()) {
            return;
        }
        if (this.props.sortColumn == column.id) {
            this.props.onSortUpdate(column.id, (this.props.sortOrder == "asc" ? "desc" : "asc"));
        } else {
            if (DEFAULT_SORT_ORDER.hasOwnProperty(column.id)) {
                this.props.onSortUpdate(column.id, DEFAULT_SORT_ORDER[column.id]);
            } else {
                this.props.onSortUpdate(column.id, DEFAULT_SORT_ORDER['*']);
            }
        }
    };

    formatCellHeader(column: { id: string, name: string }, i: number) {

        let sortClass = "fas fa-sort-amount-down";
        let thClass = "";
        if (!this.props.sortingPrevented && this.props.sortColumn == column.id) {
            thClass = "text-success";
            sortClass = (this.props.sortOrder == "asc") ? "fas fa-sort-amount-up" : "fas fa-sort-amount-down";
        }

        let columnName = column.name.replace(/<br([\s]+)?\/>/, '');

        switch (column.id) {
            case "trend_local":
            case "trend":
                return (
                    <th className={`column-header-${column.id}`} key={i}>
                        <div>
                            <span dangerouslySetInnerHTML={{__html: columnName}}/>
                        </div>
                    </th>
                );
            default:
                return (
                    <th className={`column-header-${column.id} ${thClass}`} key={i}
                        onClick={this.handleHeaderClick.bind(this, column)}>
                        <div>
                            <span dangerouslySetInnerHTML={{__html: columnName}}/>
                            <i className={`${sortClass}`}/>
                        </div>
                    </th>
                );
        }
    }

    addSelected(keyword: IKeywordResult, then?: () => void) {
        let selectedIds = this.state.selectedIds.slice();
        if (binarySearch(selectedIds, keyword.result_id, (a: number, b: number) => a - b) < 0) {
            keyword.__ui_selected = true;
            keyword.randomThing = 'Mr. random';
            const selections = this.state.selected.concat([keyword]);
            selectedIds.push(keyword.result_id);
            selectedIds.sort((a, b) => a - b);
            let allSelected = selections.length >= this.props.pagination.nb_results;
            this.setState({
                selected: selections,
                selectedIds: selectedIds,
                allKeywordsSelected: allSelected
            }, then);
            this.props.onSelectionChange(selections, allSelected);
        } else {
            then && then();
        }
    }

    removeSelected(keyword: IKeywordResult, then?: () => void) {
        let selectedIds = this.state.selectedIds.slice();
        if (binarySearch(selectedIds, keyword.result_id, (a: number, b: number) => a - b) >= 0) {
            keyword.__ui_selected = false;
            const selections = _.filter(this.state.selected, (s) => {
                return s.result_id !== keyword.result_id;
            });
            selectedIds = _.filter(selectedIds, (id) => id !== keyword.result_id);
            let allSelected = false;
            this.setState({
                selected: selections,
                selectedIds: selectedIds,
                allKeywordsSelected: allSelected
            }, then);
            this.props.onSelectionChange(selections, allSelected);
        } else {
            then && then();
        }
    }

    handleKeywordSelectionChange = (keyword: IKeywordResult, selected: boolean) => {
        if (selected) {
            this.addSelected(keyword);
        } else {
            this.removeSelected(keyword);
        }
    };

    handleAllCheckboxChange = () => {
        let selectAll = !this.state.allKeywordsSelected;
        this.setState({
            allKeywordsSelected: selectAll
        }, () => {
            let selections = this.state.selected.slice();
            let selectedIds = this.state.selectedIds.slice();
            if (selectAll) {
                _.each(this.props.searchResults, (r) => {
                    r.__ui_selected = true;
                    if (binarySearch(selectedIds, r.result_id, (a: number, b: number) => a - b) < 0) {
                        selections.push(r);
                        selectedIds.push(r.result_id);
                        selectedIds.sort((a, b) => a - b);
                    }
                });
            } else {
                _.each(this.props.searchResults, (r) => {
                    r.__ui_selected = false;
                });
                selections = [];
                selectedIds = [];
            }
            this.setState({
                selected: selections,
                selectedIds: selectedIds
            });
            this.props.onSelectionChange(selections, selectAll);
        });
    };

    isSameSource(nextProps: IProps) {
        return this.props.keywordsSource.id == nextProps.keywordsSource.id
            && this.props.keywordsSource.sourceType == nextProps.keywordsSource.sourceType;
    }

    getSelectedKeywords = () => {
        return this.state.selected;
    };

    clearSelectedKeywords() {
        _.each(this.props.searchResults, (r) => {
            r.__ui_selected = false;
        });
        this.setState({
            selected: [],
            selectedIds: [],
            allKeywordsSelected: false
        });
        this.props.onSelectionChange([], false);
    };

    handlePageSelection = (index: any) => {
        this.props.handlePageSelection(index);
    };

    private getBlurredFirstTop() {
        const $this = $(ReactDOM.findDOMNode(this));
        let pos = $this.find("td.blurred:first").position();
        let top = pos && pos.top || 0;
        return top;
    }

    positionSignUpBanner() {
        const $this = $(ReactDOM.findDOMNode(this));
        let top = this.getBlurredFirstTop();
        $this.find(".sign-up-banner").css({
            top: top
        });
    }

    positionSubscribeBanner() {
        // const $this = $(ReactDOM.findDOMNode(this));
        // let top = this.getBlurredFirstTop();
        // $this.find(".subscribe-banner").css({
        //   top: top
        // });
    }

    componentDidMount() {
        // this.positionSignUpBanner();
        // this.positionSubscribeBanner();
        //
        // var $table = $('#search-results-table');
        // var $elem = $('#search-results-table thead');
        //
        // this.state.windowScrollHanlder = function () {
        //   if ($(window).scrollTop() > $table.offset().top) {
        //     $elem.css('transform', 'translate(0,' + ($(window).scrollTop() - $table.offset().top) + 'px)');
        //     $elem.addClass("floating");
        //   } else {
        //     $elem.removeClass("floating");
        //     $elem.css('transform', 'translate(0,0)');
        //   }
        // };
        // $(window).scroll(this.state.windowScrollHanlder);
    }

    componentWillUnmount() {
        $(window).off("scroll", this.state.windowScrollHanlder);
    }

    componentDidUpdate() {
        this.positionSignUpBanner();
        this.positionSubscribeBanner();
    }

    htmlToString(html: string) {
        return $("<div>").html(html).text();
    }

    showDetail = (keyword: IKeywordResult) => {
        this.setState({
            showDetailsModal: true,
            detailsModalKeyword: keyword
        });
    };


    renderColumnHeader = (column: IColumn, i: number) => {
        let popover = <Popover id={"column-" + column.id + "-popover"}>
            <Popover.Title as="h3">{this.htmlToString(column.name)}</Popover.Title>
            <Popover.Content>
                {column.description}
            </Popover.Content>
        </Popover>;
        return <KkOverlayTrigger key={i} placement="bottom" overlay={popover}>
            {this.formatCellHeader(column, i)}
        </KkOverlayTrigger>;
    };

    getClearResults() {
        //this.props.searchResults
        return _.filter(this.props.searchResults, r => r.search_result.indexOf('***redacted***') === -1);
    }

    getRedactedResults() {
        //this.props.searchResults
        return _.filter(this.props.searchResults, r => r.search_result.indexOf('***redacted***') !== -1);
    }

    render() {
        let redacted = this.getRedactedResults();
        let clear = this.getClearResults();
        return (
            <div className="container-fluid pt-4 keyword-search-result-table">
                <div className="row gap-y align-items-center">
                    <div className="col-lg-6">
                        {this.props.config.ui.permissions.serpFilter && <SERPLegend/>}
                    </div>
                    <div className="col-lg-6">
                        <nav className="d-flex align-items-center justify-content-end">
                            {this.props.processing && <div className="loading loading-sm spinner spinner-primary"/>}
                            {this.props.pagination.have_to_paginate && <PaginationMaker
                                pagination={this.props.pagination}
                                handlePageSelection={this.handlePageSelection}
                            />}
                        </nav>
                    </div>
                </div>
                <hr className="my-3"/>
                <div className="table-responsive">
                    <table className="table table-hover table-clean table-bordered">
                        <thead>
                        <tr>
                            {this.props.allowSelect && <th>
                                <div className="checkbox">
                                    <input type="checkbox" id="select_all"
                                           checked={this.state.allKeywordsSelected}
                                           onChange={this.handleAllCheckboxChange}
                                    />
                                    <label htmlFor="select_all"/>
                                </div>
                            </th>}
                            {this.props.searchColumns.map(this.renderColumnHeader)}
                        </tr>
                        </thead>
                        <TableBody
                            searchResults={clear}
                            pagination={this.props.pagination}
                            allowSelect={this.props.allowSelect}
                            searchColumns={this.props.searchColumns}
                            config={this.props.config}
                            hasDeducedTypes={this.props.hasDeducedTypes}
                            currencySymbol={this.props.currencySymbol}
                            showDetails={this.showDetail}
                            selectionChange={this.handleKeywordSelectionChange}
                            selected={this.state.selected}
                            selectedIds={this.state.selectedIds}
                            clearSelectedKeywords={() => {
                                this.clearSelectedKeywords();
                            }}
                        />
                        {redacted.length > 0 && <TableBody
                            searchResults={redacted}
                            pagination={this.props.pagination}
                            allowSelect={this.props.allowSelect}
                            searchColumns={this.props.searchColumns}
                            config={this.props.config}
                            hasDeducedTypes={this.props.hasDeducedTypes}
                            currencySymbol={this.props.currencySymbol}
                            showDetails={this.showDetail}
                            selectionChange={this.handleKeywordSelectionChange}
                            selected={this.state.selected}
                            selectedIds={this.state.selectedIds}
                            clearSelectedKeywords={() => {
                                this.clearSelectedKeywords();
                            }}
                            blurred
                        />}
                    </table>
                </div>
                <nav className="d-flex align-items-center justify-content-end">
                    {this.props.pagination.have_to_paginate && <PaginationMaker
                        pagination={this.props.pagination}
                        handlePageSelection={this.handlePageSelection}
                    />}
                </nav>
                <KeywordDetailsModal
                    show={this.state.showDetailsModal}
                    currencySymbol={this.props.currencySymbol}
                    onHide={() => this.setState({showDetailsModal: false})}
                    seedKeyword={this.props.seedKeyword}
                    columns={this.props.columns}
                    result={this.state.detailsModalKeyword}
                    config={this.props.config}
                />
            </div>
        );
    }

}

export default KeywordSearchResultTable;
