import * as $ from "jquery";
import * as Promise from "bluebird";
import {List, Identifiers as APICodes} from "../../suggest/fetchers";
import {Country, Codes as CountryCodes} from "../../countries";
import {Language, Codes as LanguageCodes} from "../../languages";
import {KeywordType, AllKeywordTypes, KeywordTypeCodes} from "../../../keyword/keyword-types";

export interface IResponse {
    id: number
}

interface ICreateSearchServerResponse {
    error: boolean,
    code?: string,
    handled?: boolean,
    message?: string,
    data: { id: number }
}

//Continuously check if search is complete every N seconds
const N = 2;

export class Executor {

    constructor(protected parameters: {
        keyword: string, recaptcha: string, apis?: List[], countries: Country[], languages: Language[], types: KeywordType[]
    }) {

    }

    getCreateSearchUrl() {
        return "/sf";
    }

    getStatusUrl(id: number) {
        return `/sf/${id}`;
    }

    prepareCreateSearchParameters() {
        const {keyword, apis, countries, languages, types, recaptcha} = this.parameters;
        let res: { [k: string]: any } = {
            __csrf_token: $('meta[name="csrf-token"]').attr('content'),
            keyword: keyword,
            countries: countries.map((c) => CountryCodes[c]),
            languages: languages.map((l) => LanguageCodes[l]),
            types: types.map((t) => KeywordTypeCodes[t]),
            recaptcha: recaptcha
        };
        if (apis) {
            res['apis'] = apis.map((a) => APICodes[a]);
        }
        return res;
    }

    processResponse(response: ICreateSearchServerResponse, resolve: (a: any) => void, reject: (a: any) => void) {
        if (!response.error) {
            resolve(response.data.id);
        } else {
            let redirect = null;
            if (response.code == "RateLimitCrossed") {
                redirect = "/error/search-limit-reached";
            } else if (response.code === "IPBlocked") {
                redirect = "/pricing";
            } else if (response.code === 'FreeUserDenied') {
                redirect = "/pricing?msg=SubscriptionRequired";
            }
            if(redirect){
                response.handled = true;
                reject(response);
                window.location.replace(redirect);
                return;
            }
            reject(response);
        }
    }

    execute(): Promise<IResponse> {

        return Promise.resolve().then(() => {
            let createSearchUrl = this.getCreateSearchUrl();
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: createSearchUrl,
                    method: "POST",
                    data: this.prepareCreateSearchParameters(),
                    success: (response: ICreateSearchServerResponse) => {
                        this.processResponse(response, resolve, reject);
                    },
                    error: (jqXHR: { responseJSON: { message: string } }) => {
                        reject(new Error(`${createSearchUrl} returned an error`));
                    }
                } as JQueryAjaxSettings);
            });
        }).then((id: number) => {

            let statusUrl = this.getStatusUrl(id);

            return new Promise<IResponse>((resolve, reject) => {

                //Continuously check if search is complete every N seconds
                function check() {
                    $.ajax({
                        url: statusUrl,
                        method: "GET",
                        success: (response: {
                            error: boolean, code?: string, message?: string,
                            data: {
                                status: string,
                                keywords?: string[],
                                count?: number
                            }
                        }) => {
                            if (!response.error) {
                                if (response.data.status == "finished") {
                                    resolve({id: id});
                                } else {
                                    setTimeout(check, N * 1000);
                                }
                            } else {
                                reject(new Error(`${statusUrl} returned an error`));
                            }
                        },
                        error: (jqXHR: { responseJSON: { message: string } }) => {
                            reject(new Error(`${statusUrl} returned an error`));
                        }
                    } as JQueryAjaxSettings);
                }

                setTimeout(check, N * 1000);
            });
        })
    }
}

export function execute(keyword: string, recaptcha: string, apis: List[], countries: Country[], languages: Language[], types: KeywordType[]): Promise<IResponse> {
    let e = new Executor({keyword, recaptcha, apis, countries, languages, types});
    return e.execute();
}

export default execute;
