import * as React from "react";
import {IConfig} from "../../lib/api/keyword-keg/config";

import {List as API, Names as APINames, All as AllApis} from "../../lib/api/suggest/fetchers";

import getAPIs from "../../lib/api/keyword-keg/search/apis";
import {MySelect2 as Select2} from "../../lib/my-select-2";
import {getIntegerSelectedOptions} from "../../lib/util/html";
import {All as AllCountries, Names as CountryNames} from "../../lib/api/countries";

export interface APISelectProps {
    selected: API[],
    onChange: (apis: API[]) => void,
    config: IConfig,
    id: string
}

interface APISelectState {
}

export class APISelect extends React.Component<APISelectProps, APISelectState> {

    state: APISelectState = {
    };

    handleChange = (e) => {
        const selected = getIntegerSelectedOptions(e.target);
        if(!_.isEqual(selected.sort(), this.props.selected.sort())){
            this.props.onChange(selected);
        }
    };

    render() {
        let {id} = this.props;
        console.log("Render API select");
        return (
            <Select2
                id={id}
                multiple={this.props.config.selectMultipleApis}
                data={AllApis.map(api => {
                    return {
                        id: api,
                        text: APINames[api]
                    };
                })}
                options={
                    {
                        placeholder: "Select APIs",
                    }
                }
                className="form-control select2"
                onChange={this.handleChange}
                value={this.props.selected}
            />
        );
    }

}

export default APISelect;