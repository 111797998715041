//this is needed because of a bug in the version of Tagify that we use preventing us from using
//the property tagify.value
export const getTagifyValue = (tagify) => {
    try{
        return _.map(tagify.getTagElms(), e => {
            return $(e).text()
        });
    } catch (e) {
        console.warn(e);
    }
    return [];
};