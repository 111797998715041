if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
  console = {
    log: function () {},
    warn: function () {},
    error: function () {},
    info: function () {},
    debug: function () {},
  };
}

export {};