import * as React from 'react';

import {IKeywordResult} from "../../lib/api/keyword-keg/sf/results";

interface IProps {
  blur: boolean,
  result: IKeywordResult,
  checked: boolean,
  selectionChange: (r: IKeywordResult, selected: boolean) => void
}

interface IState {
}

export class CheckTableCell extends React.Component<IProps, IState> {

  props: IProps;
  state: IState = {
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.selectionChange(this.props.result, e.target.checked);
  };

  shouldComponentUpdate(nextProps: IProps, nextState: IState) {
    return this.props.checked != nextProps.checked;
  }

  render(){
    let id = `checkbox_${this.props.result.result_id}`;
    return (
        <td>
          <div className="checkbox">
            <input type="checkbox" id={id}
                   checked={this.props.checked}
                   onChange={this.onChange}
            />
            <label htmlFor={id} />
          </div>
        </td>
    );
  }

}

export default CheckTableCell;