export enum IKeywordsSourceType {
  SearchFork,
  List,
  BulkUpload,
  WordCombination,
  UploadedDataFile,
  Pasf,
  RelatedKeywords
}

export let KeywordsSourceTypeNames: {[key: number]: string} = {};
KeywordsSourceTypeNames[IKeywordsSourceType.SearchFork] = 'search_fork';
KeywordsSourceTypeNames[IKeywordsSourceType.List] = 'list';
KeywordsSourceTypeNames[IKeywordsSourceType.BulkUpload] = 'bulk_upload';
KeywordsSourceTypeNames[IKeywordsSourceType.WordCombination] = 'word_combination';
KeywordsSourceTypeNames[IKeywordsSourceType.UploadedDataFile] = 'uploaded_data_file';
KeywordsSourceTypeNames[IKeywordsSourceType.Pasf] = 'pasf';
KeywordsSourceTypeNames[IKeywordsSourceType.RelatedKeywords] = 'related_keywords';

export interface IKeywordsSource {
  sourceType: IKeywordsSourceType,
  id: number
};
