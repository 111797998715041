import * as React from 'react';
import Clipboard from 'react-clipboard.js';
import {IConfig} from "../../lib/api/keyword-keg/config";
import {IColumn, IKeywordResult} from "../../lib/api/keyword-keg/sf/results";
import number_format from "../../lib/util/number-format";
import {getMonthShortName} from "../../lib/util/time";
import {toastSuccess} from "../../lib/util/toast";

const PRO_COLUMNS = ['onpage_difficulty', 'offpage_difficulty', 'seo_difficulty',
  'ctr_opportunity', 'kw_potential'];

export interface ICopyKeywordsButtonProps {
  isAll: () => boolean,
  getKeywords: () => IKeywordResult[],
  config: IConfig,
  searchColumns: IColumn[],
  currencySymbol: string,
}

interface ICopyKeywordsButtonState {

}

export class CopyKeywordsButton extends React.Component<ICopyKeywordsButtonProps, ICopyKeywordsButtonState> {

  getText = () => {
    let kws = this.props.getKeywords();
    let strs = _.map(kws, (kw) => kw.search_result);
    return strs.join("\n");
  };

  getTableData() {
    let kws = this.props.getKeywords();
    let allRows = kws.map((r: IKeywordResult, i) => {
      let rowData = this.props.searchColumns.map((column) => {
        return this.formatCell(column, r[column.id], r);
      });
      rowData = this.flatten(rowData);
      return rowData.join("\t");
    });
    return allRows.join("\n");
  }

  getKeywordsAndMetrics = () => {
    let rows = [
      this.getHeaders(),
      this.getTableData()
    ];
    return rows.join("\n");
  };

  formatTrend(cell: string) {
    let data = cell ? cell.split("|").map((d) => Number(d)) : _.fill(Array(12), '');
    let reverse = this.props.config.ui.trendDirection === "desc";
    if (!reverse) {
      data.reverse();
    }
    return data;
  };

  flatten(data: any[]) {
    return _.reduce(data, (carry: any[], item: any) => {
      if (_.isArray(item)) {
        return _.concat(carry, item);
      }
      carry.push(item);
      return carry;
    }, []);
  }

  formatCell(column: {id: string, name: string}, value: any, result: IKeywordResult) {
    if (!_.isUndefined(value) && (/^cpc(.*)/.test(column.id))) {
      return Number(value).toFixed(2);
    }
    if (column.id.match(/^value(.*)$/)) {
      return number_format(value);
    }
    if (column.id.match(/^monthly_volume(.*)/)) {
      return number_format(value);
    }
    if (column.id.match(/trend(.*)$/)) {
      return this.formatTrend(value);
    }
    if (column.id == "search_result" || column.id == "keyword") {
      return value;
    }
    if (PRO_COLUMNS.indexOf(column.id) !== -1) {
      if ((column.id == "ctr_opportunity") && !this.props.config.ui.permissions.ctr_opportunity) {
        return "";
      } else if ((['onpage_difficulty', 'offpage_difficulty', 'seo_difficulty'].indexOf(column.id) !== -1) && !this.props.config.ui.permissions.seo_difficulty) {
        return "";
      } else if ((column.id == "kw_potential") && !this.props.config.ui.permissions.kw_potential) {
        return "";
      }
      if (value == 0) {
        if(column.id == 'kw_potential' && result.seo_difficulty > 0){
          return value;
        } else {
          return "-";
        }
      } else {
        return value;
      }
    }
    return value;
  }

  getHeaders() {
    let labels = this.props.searchColumns.map((column, i) => {
      if (column.id.match(/trend(.*)$/)) {
        return this.getTrendHeader(column);
      }
      let res = this.htmlToString(column.name);
      if ((/^cpc(.*)/.test(column.id)) || column.id.match(/^value(.*)$/)) {
        res += " (" + this.props.currencySymbol + ")";
      }
      return res;
    });
    labels = this.flatten(labels);
    return labels.join("\t");
  }

  getTrendHeader(column: IColumn) {
    let reverse = this.props.config.ui.trendDirection === "desc";
    let res = [];
    for (var i = 0; i < 12; i++) {
      res.push(this.getTooltip((reverse ? i : (12 - i - 1))));
    }
    return res;
  }

  getTooltip(i: number) {
    const today = new Date();
    const target = new Date(today.setMonth(today.getMonth() - (i + 1)));
    return `${getMonthShortName(target.getMonth())} ${target.getFullYear()}`;
  }

  htmlToString(html: string) {
    return $("<div>").html(html).text();
  }

  onSuccess = (metrics: boolean) => () => {
    let str = metrics ? 'keywords and metrics' : 'keywords';
    toastSuccess(`${this.props.getKeywords().length} ${str} copied to clipboard successfully.`);
  };

  render(): JSX.Element | false | null {
    return (
        <div className="btn-group">
          <button className="btn btn-primary dropdown-toggle" type="button"
                  data-toggle="dropdown">
            {this.props.isAll() && `Copy All`}
            {!this.props.isAll() && `Copy `}
            {!this.props.isAll() && <span className="badge badge-light">{this.props.getKeywords().length}</span>}
          </button>
          <div className="dropdown-menu">
            <Clipboard
                className="dropdown-item"
                option-text={this.getText}
                component="a"
                button-href="#"
                onSuccess={this.onSuccess(false)}
                onClick={e => e.nativeEvent.preventDefault()}
            >
              <i className="fas fa-copy mr-2"/> Copy {this.props.isAll() ? "all" : `${this.props.getKeywords().length}`} keywords
            </Clipboard>
            <Clipboard
                className="dropdown-item"
                option-text={this.getKeywordsAndMetrics}
                component="a"
                button-href="#"
                onSuccess={this.onSuccess(true)}
                onClick={e => e.nativeEvent.preventDefault()}
            >
              <i className="fas fa-check-double mr-2"/> Copy {this.props.isAll() ? "all" : `${this.props.getKeywords().length}`} keywords and metrics
            </Clipboard>
          </div>
        </div>
    );
  }
}

export default CopyKeywordsButton;