import {Country} from "../../../countries";

const SupportedCountries = [
    Country.UnitedStates,
    Country.Australia,
    Country.Singapore,
    Country.India,
    Country.Indonesia,
    Country.NewZealand,
    Country.Belgium,
    Country.France,
    Country.Ireland,
    Country.Italy,
    Country.UnitedKingdom,
    Country.Brazil
];

export default SupportedCountries