import * as React from "react";
import * as _ from "lodash";

export interface SparklineBarProps {
    num: number,
    data: number[],
    height: number,
    tooltip?: string
}

interface SparklineBarState {
}

export class SparklineBar extends React.Component<SparklineBarProps, SparklineBarState> {

    elem: HTMLDivElement;

    calculateHeight = () => {
        let {data, num, height} = this.props;
        if (_.max(data) == num) {
            return height;
        } else {
            return (num / _.max(data)) * height;
        }
    };

    componentWillUnmount(): void {
        $(this.elem).unbind();
    }

    setSparklineBarRef = (div: HTMLDivElement) => {
        if(this.elem){
            $(this.elem).unbind();
        }
        this.elem = div;
        $(this.elem).tooltip();
    };

    render() {
        return <div
            ref={this.setSparklineBarRef}
            className="sparkline-bar"
            title={this.props.tooltip ? this.props.tooltip : `Value: ${this.props.num}`}
            style={{
            height: this.calculateHeight()
        }}/>;
    }
}