import * as React from 'react';
import {Modal, Row, Col} from 'react-bootstrap';
import {IColumn} from "../../lib/api/keyword-keg/search/columns";
import number_format from "../../lib/util/number-format";
import {IConfig} from "../../lib/api/keyword-keg/config";
import {Sparkline} from "../sparkline";
import {IKeywordResult} from "../../lib/api/keyword-keg/sf/results";

const NORMAL_COLUMNS = ['monthly_volume', 'cpc', 'competition', 'value'];
const PRO_COLUMNS = ['onpage_difficulty', 'offpage_difficulty', 'seo_difficulty',
 'ctr_opportunity', 'kw_potential'];


export interface IKeywordDetailsModalProps {
  show: boolean,
  currencySymbol: string,
  onHide: () => void,
  seedKeyword?: string,
  columns: IColumn[],
  result: IKeywordResult,
  config: IConfig
}

interface IKeywordDetailsModalState {

}

export class KeywordDetailsModal extends React.Component<IKeywordDetailsModalProps, IKeywordDetailsModalState> {

  props: IKeywordDetailsModalProps;
  state: IKeywordDetailsModalState = {};

  getNormalColumns(): IColumn[] {
    let cols = _.filter(this.props.columns, (c) => NORMAL_COLUMNS.indexOf(c.id) !== -1);
    return _.sortBy(cols, (c) => {
      return NORMAL_COLUMNS.indexOf(c.id);
    })
  }

  getConditionalColumns(): {column: IColumn, show: boolean}[] {
    let cols = _.filter(this.props.columns, (c) => PRO_COLUMNS.indexOf(c.id) !== -1);
    cols = _.sortBy(cols, (c) => {
      return PRO_COLUMNS.indexOf(c.id);
    })
    return _.map(cols, (c) => {
      if (['onpage_difficulty', 'offpage_difficulty', 'seo_difficulty'].indexOf(c.id) !== -1) {
        return {
          column: c,
          show: this.props.config.ui.permissions.seo_difficulty
        };
      } else if (c.id === 'ctr_opportunity') {
        return {
          column: c,
          show: this.props.config.ui.permissions.ctr_opportunity
        };
      } else if (c.id === 'kw_potential') {
        return {
          column: c,
          show: this.props.config.ui.permissions.kw_potential
        };
      } else {
        return {
          column: c,
          show: true
        }
      }
    });
  }

  formatTrend(cell: string) {
    let data = cell ? cell.split("|").map((d) => Number(d)) : [];
    return <Sparkline
      data={data}
      reverse={this.props.config.ui.trendDirection === "desc"}
      width={100}
      height={30}
      gap={2}
      color="#d36a5f" />;
  };

  formatCell(column: {id: string, name: string}, value: any) {

    if (!_.isUndefined(value) && (/^cpc(.*)/.test(column.id))) {
      return this.props.currencySymbol + Number(value).toFixed(2);
    }

    if (column.id.match(/^value(.*)$/)) {
      return this.props.currencySymbol + number_format(value);
    }

    if (column.id.match(/^monthly_volume(.*)/)) {
      return number_format(value);
    }

    if (column.id.match(/trend(.*)$/)) {
      return this.formatTrend(value);
    }

    if (PRO_COLUMNS.indexOf(column.id) !== -1) {
      if (value == 0) {
        return "-";
      } else {
        return `${value}/100`;
      }
    }
    return value;
  }

  render() {
    return <Modal id="keyword-details"
      show={this.props.show} onHide={this.props.onHide}>
      {(this.props.seedKeyword) && <Modal.Header closeButton>
        <h5 className="modal-title">{this.props.seedKeyword}</h5>
      </Modal.Header>}
      {this.props.result && <Modal.Body>
        <h5 className="bold text-center mb-5">{this.props.result.search_result}</h5>
        <dl className="mb-4">
        {this.getNormalColumns().map((c) => {
          return [
            <dt key={`${c.id}-title`}>{c.name.replace(/<br([\s]+)?\/>/, '')} : </dt>,
            <dd key={`${c.id}-data`}>{this.formatCell(c, this.props.result[c.id])}</dd>
          ];
        })}
        </dl>
        <dl>
        {this.getConditionalColumns().map((condition) => {
          let c = condition.column;
          return [
            <dt key={`${c.id}-title`}>{c.name.replace(/<br([\s]+)?\/>/, '')} : </dt>,
            <dd key={`${c.id}-data`}>{this.formatCell(c, this.props.result[c.id])}</dd>
          ];
        })}
        </dl>
      </Modal.Body>}
    </Modal>
  }

}

export default KeywordDetailsModal;