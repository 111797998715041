import * as React from "react";
import * as _ from "lodash";
import {Pagination} from "react-bootstrap";
import {IPagination} from "../../lib/api/keyword-keg/sf/results";

export interface IProps {
  pagination: IPagination,
  handlePageSelection: (page: number) => void
}

export interface IState {
}

export class PaginationMaker extends React.Component<IProps, IState> {

  min = 1;
  current = this.props.pagination.current_page;
  max = this.props.pagination.last_page;
  padding = 5;
  items: any[] = [];
  pages: number[] = [];


  addPage(i: number) {
    if (_.indexOf(this.pages, i) == -1 && i >= this.min && i <= this.props.pagination.last_page) {
      if (this.pages.length > 0 && this.pages[this.pages.length - 1] < i - 1) {
        this.items.push(
          <Pagination.Ellipsis
            key={`ellipsis-page-${i}`}
          />
        );
      }
      let active = i === this.props.pagination.current_page;
      this.items.push(
        <Pagination.Item
          key={`page-${i}-active-${active ? '1' : '0'}`}
          onClick={this.handleClick(i)}
          active={active}
        >{i}</Pagination.Item>
      );
      this.pages.push(i);
    }
  }

  handleClick = (page: number) => (event: any) => {
    this.props.handlePageSelection(page);
  };

  render() {
    this.items = [];
    this.pages = [];
    this.addPage(this.min);
    let padding = this.padding;
    let current = this.props.pagination.current_page;
    let left = Math.max(this.min, current - Math.floor(padding / 2));
    let right = Math.min(this.props.pagination.last_page, left + padding);
    left = Math.max(this.min, right - padding);
    for (let i = left; i <= right; i++) {
      this.addPage(i);
    }
    this.addPage(this.props.pagination.last_page);
    return (<Pagination variant="small" className={'ml-3 mb-0 pagination justify-content-end'}>
      <Pagination.First
        disabled={this.props.pagination.is_first_page}
        onClick={this.handleClick(this.min)}
      />
      <Pagination.Prev
        disabled={this.props.pagination.is_first_page}
        onClick={this.handleClick(current - 1)}
      />
      {this.items}
      <Pagination.Next
        disabled={this.props.pagination.is_last_page}
        onClick={this.handleClick(current + 1)}
      />
      <Pagination.Last
        disabled={this.props.pagination.is_last_page}
        onClick={this.handleClick(this.props.pagination.last_page)}
      />
    </Pagination>);
  }

}

export default PaginationMaker;
