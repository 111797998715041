import {Country} from "../../../countries";

let Domains: {[key: number]: string} = {};

Domains[Country.UnitedStates] = "amazon.com";
Domains[Country.Australia] = "amazon.co.jp";
Domains[Country.Brazil] = "amazon.com";
Domains[Country.Canada] = "amazon.com";
Domains[Country.France] = "amazon.co.uk";
Domains[Country.Germany] = "amazon.co.uk";
Domains[Country.India] = "amazon.co.uk";
Domains[Country.Italy] = "amazon.co.uk";
Domains[Country.Japan] = "amazon.co.jp";
Domains[Country.Mexico] = "amazon.com";
Domains[Country.Netherlands] = "amazon.co.uk";
Domains[Country.Spain] = "amazon.co.uk";
Domains[Country.UnitedKingdom] = "amazon.co.uk";

export default Domains;