import * as React from "react";
import {Popover} from "react-bootstrap";
import {SERP} from "../../lib/columns";
import {KkOverlayTrigger} from "../common/kk-overlay-trigger";

export interface ISerpLegendProps {

}

interface ISerpLegendState {

}

export class SERPLegend extends React.Component<ISerpLegendProps, ISerpLegendState> {


    render() {
        return <div className="nav">
            {SERP.All.map((type, i) => {
                return <KkOverlayTrigger key={i} placement="bottom" overlay={
                    <Popover id={`serp-legend-${SERP.Names[type].replace(' ', '')}`}>
                        <Popover.Title as="h3">{SERP.Names[type]}</Popover.Title>
                        <Popover.Content>
                            {SERP.Descriptions[type]}
                        </Popover.Content>
                    </Popover>
                }>
                    <span
                        className="mr-2 rounded-circle border icon-md d-flex align-items-center justify-content-center">
                        <i className={SERP.GlyphiconClasses[type]}/>
                    </span>
                </KkOverlayTrigger>;
            })}
        </div>
    }

}

export default SERPLegend;
