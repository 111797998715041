import * as React from "react";
import * as _ from "lodash";
import {IFilters} from "../../../lib/api/keyword-keg/search/filters";

export interface INumericFilterProps {
  handleNumericLimitChange: (name: string, constraint: string, val: any) => any,
  label: string,
  name: string,
  step?: string,
  max?: string,
  filters: IFilters,
}

interface INumericFilterState {

}

export class NumericFilter extends React.Component<INumericFilterProps, INumericFilterState> {

  getNumericFilter = (name: string, constraint: string, props?: INumericFilterProps) => {
    if (!props) {
      props = this.props;
    }
    return props.filters.hasOwnProperty(name) ? (props.filters[name].hasOwnProperty(constraint) && !_.isNull(props.filters[name][constraint]) ? props.filters[name][constraint] : "") : "";
  };

  shouldComponentUpdate(nextProps: INumericFilterProps, nextState: INumericFilterState) {
    return this.getNumericFilter(this.props.name, 'min', this.props)
      != this.getNumericFilter(this.props.name, 'min', nextProps)
      || this.getNumericFilter(this.props.name, 'max', this.props)
      != this.getNumericFilter(this.props.name, 'max', nextProps);
  }

  render(): JSX.Element | false | null {
    return (
        <div className="col-md-4">
          <div className="form-group">
            <label className="control-label">{this.props.label}</label>
            <div className="input-group">
              <input className="form-control" placeholder="Min"
                     value={this.getNumericFilter(this.props.name, 'min')}
                     onChange={(e) => this.props.handleNumericLimitChange(this.props.name, "min", e.target.value)}
                     type="number"
                     min="0"
                     step={this.props.step}
                     max={this.props.max}
              />
              <input className="form-control" placeholder="Max"
                     value={this.getNumericFilter(this.props.name, 'max')}
                     onChange={(e) => this.props.handleNumericLimitChange(this.props.name, "max", e.target.value)}
                     type="number"
                     min="0"
                     step={this.props.step}
                     max={this.props.max}
              />
            </div>
          </div>
        </div>
    );
  }
}
