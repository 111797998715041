import * as React from "react";
import * as _ from 'lodash';
import {KeywordInput} from './new-dash/keyword-input-v2';
import {List as API} from "../lib/api/suggest/fetchers";
import {Codes as CountryCodes, Country} from "../lib/api/countries";
import {Language} from "../lib/api/languages";
import {AllKeywordTypes as AllResultTypes, KeywordType} from "../lib/keyword/keyword-types";
import {IResponse as ISearchResponse} from "../lib/api/keyword-keg/sf/execute";
import {
    defaultPagination,
    IKeywordResult,
    IParams as IResultParams,
    IResponse
} from "../lib/api/keyword-keg/sf/results";

import {IDeleteResultsParams} from "../lib/api/keyword-keg/sf/delete-results";
import {ParameterRow as DashboardParameterRow} from './dashboard/parameter-row';
import {getUserFilters, IFilters, IUserFilter, IUserFiltersResponse} from "../lib/api/keyword-keg/search/filters";
import * as qs from "qs";
import {RandomProgressBar} from "./dashboard/random-progress-bar";
import KeywordSearchResultTable from "./keyword-search-result-table";
import {IGrecaptcha, onRecaptchaLoad} from '../lib/recaptcha/recaptcha-util';
import {IKeywordViewerProps, IKeywordViewerState, KeywordViewer} from './keyword-view';
import * as Promise from "bluebird";
import {number_format} from "../lib/util/number-format";

export interface IAbstractSearchToolProps extends IKeywordViewerProps {
}

export interface IAbstractSearchToolState extends IKeywordViewerState {
    keywordInputValue: string,
    strictMode: boolean,
    keywordInputError: string | boolean,
    showingSearchRunning: boolean,
    searchIsComplete: boolean,
    isFromQuery?: boolean,
    hasResultsToShow?: boolean,
    requestedKeyword?: string,
    searchRunning: boolean
}

interface UrlParams {
    api: string,
    query: string,
    autostart: string,
    country: string
}

interface IQueryFilters {
    api?: string,
    autostart?: boolean,
    query?: string,
    country?: string
}

export abstract class AbstractSearchTool<P extends IAbstractSearchToolProps, S extends IAbstractSearchToolState> extends KeywordViewer<P, S> {

    protected recaptchaWidgetId: string;
    protected recaptchaSearchRequested: boolean;
    protected parameterRow: DashboardParameterRow;
    protected progressBar: RandomProgressBar;
    protected table: KeywordSearchResultTable;
    protected keywordInput: KeywordInput;
    private searchFormProgress: Mprogress;

    initState(props: IAbstractSearchToolProps): IAbstractSearchToolState {
        let state = super.initState(props);
        return _.assign(state, {
            keywordInputValue: "",
            requestedKeyword: "",
            strictMode: false,
            keywordInputError: false,
            searchIsComplete: false,
            isFromQuery: false,
            hasResultsToShow: false,
            showingSearchRunning: false,
            searchRunning: false,

            searchIsRunning: false,
            filters: {},
            columns: [],
            pagination: defaultPagination(),
            searchId: null,
            allKeywordsSelected: false,
            negativeKeywords: [],
            searchClicked: false,
        });
    }

    componentDidMount() {
        super.componentDidMount();
        const params = this.getQueryStringFilters();
        if (params.query && params.autostart) {
            this.showSearchStarted();
        }
        this.loadColumns().then(() => {
        });
        this.loadIndustries().then(() => {
        });
        if (this.props.config.auth.authenticated) {
            this.loadFilters().then(() => {
            });
        } else {
            this.onFilterProcessingDone();
        }
        this.initRecaptcha();
    }

    isRecaptchaEnabled = () => !this.props.config.auth.authenticated && this.props.config.recaptcha.enable;

    initRecaptcha() {
        if (!this.isRecaptchaEnabled()) {
            return;
        }
        onRecaptchaLoad((grecaptcha) => {
            this.renderRecaptcha(grecaptcha);
            if (this.recaptchaSearchRequested) {
                this.executeRecaptcha();
            }
        });
    }

    renderRecaptcha(grecaptcha: IGrecaptcha) {
        this.recaptchaWidgetId = grecaptcha.render('recaptcha-container', {
            'sitekey': this.props.config.recaptcha.site_key,
            'callback': this.recaptchaOnSubmit,
            size: 'invisible'
        });
    }

    executeRecaptcha = () => {
        onRecaptchaLoad((grecaptcha) => {
            let withRecaptcha = () => {
                try {
                    grecaptcha.execute(this.recaptchaWidgetId);
                } catch (e) {
                    console.log(e);
                    try {
                        grecaptcha.reset(this.recaptchaWidgetId);
                        this.renderRecaptcha(grecaptcha);
                    } catch (e) {
                        console.log(e);
                    }
                    setTimeout(withRecaptcha, 100);
                }
            };
            withRecaptcha();
        });
    };

    recaptchaOnSubmit = (token: string) => {
        this.continueSearchClickProcessing(token);
    };

    validateInputKeywords() {
        if (!this.state.keywordInputValue.trim()) {
            this.setState({
                keywordInputError: "Please enter a keyword",
                searchRunning: false
            });
            return false;
        }
        this.setState({
            keywordInputError: false,
        });
        return true;
    }

    continueSearchClickProcessing(recaptcha: string) {
        if (this.isRecaptchaEnabled()) {
            onRecaptchaLoad((grecaptcha) => {
                grecaptcha.reset();
                this.recaptchaSearchRequested = false;
            });
        }
        if (!this.validateInputKeywords()) {
            return;
        }
        const parameters = this.parameterRow.getParameters();
        this.search(this.state.keywordInputValue, recaptcha, parameters.apis,
            parameters.countries, parameters.languages, parameters.resultTypes);
    };

    showSearchStarted() {
        if (this.state.showingSearchRunning) {
            return;
        }
        // this.progressBar.restart();
        this.setState({
            showingSearchRunning: true,
            hasResultsToShow: false
        })
    }

    loadFilters(): Promise<void> {
        return getUserFilters().then((res: IUserFiltersResponse) => {
            let filters: IFilters = JSON.parse(res.defaultFilter.filters);
            this.setState({
                filters: filters,
                appliedFilters: res.defaultFilter,
                userFilters: res.filters
            }, () => {
                this.onFiltersLoaded(filters);
            });
        });
    }

    onFiltersLoaded(filters: IFilters) {
        this.onFilterProcessingDone();
    }

    onFilterProcessingDone() {
    }

    getQueryStringFilters() {
        const params: UrlParams = qs.parse(window.location.search.slice(1));
        let filter: IQueryFilters = {};
        if (params.api) {
            filter.api = params.api;
        }
        if (params.country) {
            filter.country = params.country;
        }
        if (params.autostart) {
            filter.autostart = Boolean(JSON.parse(params.autostart));
        } else {
            filter.autostart = false;
        }
        filter.query = params.query || "";
        return filter;
    }

    startUrlFiltersSearch(qsFilters: IQueryFilters) {
        if (qsFilters.autostart && qsFilters.query) {
            this.handleSearchMouseDown();
        } else {
            this.createInputTags();
        }
    }

    getInputKeywords(keyword: string) {
        return _.filter(keyword.split(/[\n,]/), (kw) => {
            return !!kw;
        });
    }

    getRequestedKeyword(keyword: string) {
        let keywords = this.getInputKeywords(keyword);
        if (keywords.length < 2) {
            return `"${keyword}"`;
        }
        return keywords.length + ' seed keywords';
    }

    search(keyword: string, recaptcha: string, apis: API[], countries: Country[], languages: Language[], types: KeywordType[]) {
        this.showSearchStarted();
        this.clearError();
        this.setState({
            keywordFilter: null
        }, () => {
            this.executeSearch(keyword, recaptcha, apis, countries, languages, types).then((response) => {
                this.setState({
                    requestedPage: 1,
                    requestedKeyword: this.getRequestedKeyword(keyword),
                    searchId: response.id
                }, () => {
                    this.getResults();
                });
            }).catch((e) => {
                this.resultRetrievalFailed(e);
            });
        });
    }

    abstract executeSearch(keyword: string, recaptcha: string, apis: API[], countries: Country[], languages: Language[], types: KeywordType[]): Promise<ISearchResponse>;

    resultRetrievalFailed(error?: any) {
        super.resultRetrievalFailed(error);
        this.setState({
            showingSearchRunning: false,
            searchRunning: false
        });
    }

    componentDidUpdate(prevProps: P, prevState: S) {
        if(this.state.searchRunning != prevState.searchRunning){
            let $submit = $('.search-submit-button');
            if(this.state.searchRunning){
                this.searchFormProgress.start();
                this.searchFormProgress.set(0);
                $(".search-progress").removeClass("d-none");
                $submit.addClass('btn-loading');
            } else {
                let $ajaxButton = $submit.parent('.ajax-button');
                this.searchFormProgress.end();
                $(".success", $ajaxButton).addClass("done");
                setTimeout(() => {
                    $(".search-progress").addClass("d-none");
                    //console.log('clearing status');
                    $submit.removeClass('btn-loading').removeClass('loading-end');
                    $(".success,.failed", $ajaxButton).removeClass("done");
                }, 1000);
            }
        }
        super.componentDidUpdate(prevProps, prevState);
    }

    getResultParams = (): Partial<IResultParams> => {
        let params = this.prepareResultsParams();
        params = _.assign({}, params, {
            strictMode: this.state.strictMode,
            id: this.state.searchId
        });
        return params;
    };

    getCountryParameter() {
        const parameters = this.parameterRow.getParameters();
        let countries = parameters.countries;
        let country = 'all';
        if (countries.length == 1) {
            country = CountryCodes[countries[0]];
        }
        return country;
    }

    onResultsResponse(response: IResponse) {
        super.onResultsResponse(response);
        this.setState({
            hasResultsToShow: response.pagination.nb_results > 0,
            showingSearchRunning: false,
            searchRunning: false,
            searchIsComplete: true,
        });
    }

    onUpdatingMetricsResponse(response: IResponse) {
        super.onUpdatingMetricsResponse(response);
        this.setState({
            showingSearchRunning: false,
            searchRunning: false
        });
    }

    showResults() {
        return !this.state.showingSearchRunning && this.state.searchIsComplete && !this.state.metricsUpdating;
    }

    setKeywordInputValue = (value: string, thenSearch?: boolean) => {
        this.setState({
            keywordInputValue: value
        }, () => {
            if (thenSearch) {
                this.handleSearchProcessing();
            }
        });
    };

    handleStrictModeChange = (strictMode: boolean) => {
        this.setState({
            strictMode: strictMode
        }, () => {
            if (this.state.searchId !== null) {
                this.getResults();
            }
        });
    };

    handleSearchMouseDown = () => {
        this.keywordInput.addTagsFromInput(true);
    };

    handleSearchProcessing = () => {
        if (this.state.searchRunning) {
            return;
        }
        if (!this.validateInputKeywords()) {
            return;
        }
        this.setState({
            searchRunning: true
        }, () => {
            if (this.isRecaptchaEnabled()) {
                this.recaptchaSearchRequested = true;
                this.executeRecaptcha();
            } else {
                this.continueSearchClickProcessing(null);
            }
        });
    };

    // noinspection JSUnusedGlobalSymbols
    handleDeleteKeywords = (keywords: IKeywordResult[], all: boolean, then: () => void) => {
        let resultIds = _.map(keywords, (kw) => {
            return kw.result_id;
        });
        this.deleteResults({
            id: this.state.searchId,
            resultIds: resultIds,
            all: all
        }).then(() => {
            this.table.clearSelectedKeywords();
            this.getResults(then);
        });
    };

    abstract deleteResults(params: IDeleteResultsParams): Promise<{}>;

    isFreeUser = () => {
        return !this.props.config.auth.user || !this.props.config.auth.user.plan;
    };

    // noinspection JSUnusedGlobalSymbols
    getExportName = () => {
        let keywords = _.filter(this.state.requestedKeyword.split(/[\n,]/), (kw) => {
            return !!kw;
        });
        return keywords.length > 1 ? `${keywords[0]}-${keywords.length}-keywords` : keywords[0];
    };

    abstract renderNumberOfKeywordsFound(): React.ReactElement<any>;

    updateFilters(changes: IFilters, userFilter?: IUserFilter, then?: () => any) {
        super.updateFilters(changes, userFilter ? userFilter : this.state.appliedFilters, then);
    }

    showAPIFilter() {
        return false;
    }

    getResultTypeChoices() {
        return AllResultTypes;
    }

    getInitiallySelectedResultTypes() {
        return AllResultTypes;
    }

    showQuestionMarkAddOn() {
        return false;
    }

    preventMultipleCountrySelection() {
        return false;
    }

    renderDashboardParameterRow() {
        return (
            <DashboardParameterRow
                ref={(me) => {
                    this.parameterRow = me;
                }}
                onFilterUpdate={this.updateFilters.bind(this)}
                config={this.props.config}
                hideAPI={!this.showAPIFilter()}
                resultTypes={this.getResultTypeChoices()}
                selectedTypes={this.getInitiallySelectedResultTypes()}
                preventMultipleCountrySelection={this.preventMultipleCountrySelection()}
            />
        );
    }

    renderContentWrapper(content: React.ReactElement<any>) {
        return content;
    }

    renderTopSection(): React.ReactElement<any> {
        return (<div>
            {this.renderKeywordInputSectionTitle()}
            {this.renderKeywordInputSection()}
            {this.renderMetricsUpdatingSection()}
            <hr/>
        </div>);
    }

    createInputTags() {
        this.keywordInput.addTagsFromInput();
    }

    setProgressElement = (e) => {
        let progress = new Mprogress({
            template: 1,
            parent: "#" + $(e).attr('id')
        });
        $(e).data('progress', progress);
        progress.start();
        this.searchFormProgress = progress;
    };

    renderKeywordInputSection(): React.ReactElement<any> {
        return (
            <div id="search-form" className="show">
                <div className="container-fluid py-4">
                    <div className="form cozy" id="search-results-form">
                        <div className="row gap-y">
                            <div className="col-md-11">
                                <KeywordInput
                                    ref={(r) => this.keywordInput = r}
                                    strictMode={this.state.strictMode}
                                    onChange={this.setKeywordInputValue}
                                    onStrictModeChange={this.handleStrictModeChange}
                                    error={this.state.keywordInputError}
                                    isFreeUser={this.isFreeUser}
                                    config={this.props.config}
                                    showQuestionMarkAddOn={this.showQuestionMarkAddOn()}
                                />
                                {this.renderDashboardParameterRow()}
                            </div>
                            <div className="col-md-1 align-self-md-end">
                                <div className="ajax-button">
                                    <div className="fas fa-check btn-status text-success success"/>
                                    <div className="fas fa-times btn-status text-danger failed"/>
                                    <button
                                        onMouseDown={this.handleSearchMouseDown}
                                        className="btn btn-primary search-submit-button" type="button"><i className="fa fa-search"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"search-progress d-none"}>
                        <hr/>
                        <div className="progress"
                             data-toggle="formProgress"
                             id={"search-form-progress-bar-container"}
                             ref={this.setProgressElement}
                        >
                        </div>
                    </div>
                    {!this.props.config.auth.authenticated
                    && this.props.config.recaptcha.enable
                    && <div id="recaptcha-container" />}
                </div>
            </div>
        );
    }

    renderTopPaginationLhs(): React.ReactElement<any> {
        return (
            <div className="results-found-summary">
                <div className="visible-sm visible-xs text-center">
                    {this.renderNumberOfKeywordsFound()}
                </div>
                <div className="hidden-xs hidden-sm text-left">
                    {this.renderNumberOfKeywordsFound()}
                </div>
            </div>
        );
    }

    getSeedKeyword() {
        return this.state.keywordInputValue;
    }

    renderFoundResultsInformation(){
        return (
            <div className="container-fluid py-4">
                <h4 className="regular mt-4 m-md-0">
                    {`Found ${number_format(this.state.pagination.nb_results)} unique keywords for `}
                    <span className="bold">{this.state.requestedKeyword}</span>
                </h4>
            </div>
        );
    }

    abstract renderTitleText(): string;

    renderTitle(){
        return (
            <header className="page header section bg-light">
                <div className="container text-center pb-3 pt-4">
                    {this.getGlobalNotification()}
                    <h3 className="bold font-md">{this.renderTitleText()}</h3>
                </div>
            </header>
        );
    }

    renderKeywordInputSectionTitle(): React.ReactElement<any> {
        return (<div>
            {this.renderTitle()}
        </div>);
    }
}
