import GoogleSuggestAPIFetcher from "./google";
import SupportedCountries from "./youtube/supported-countries";
import SupportedLanguages from "./youtube/supported-languages";
import {Country} from "../../countries";
import {Language} from "../../languages";

export default class YoutubeSuggestAPIFetcher extends GoogleSuggestAPIFetcher {

    public getAPIName(): string {
        return "YouTube API";
    }

    protected getSupportedCountries(): Country[] {
        return SupportedCountries;
    }

    protected getSupportedLanguages(): Language[] {
        return SupportedLanguages;
    }

    /**
     * Get the URL to be used in the AJAX call
     * @param {string} keyword
     * @returns {string}
     */
    protected getURL(keyword: string): string {
        return `https://www.google.com/complete/search?output=search&client=chrome&q=${encodeURIComponent(keyword)}&hl=${this.getLanguageCode()}&gl=${this.getCountryCode()}&ds=yt`;
    }

}