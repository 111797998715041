import '../lib/polyfills';
import * as React from "react";
import * as ReactDOM from "react-dom";
import * as $ from "jquery";
import {getConfig, IConfig} from "../lib/api/keyword-keg/config";

import Dashboard from "../components/dashboard";
import ErrorBoundary from "../lib/util/error-boundary";

// import "../../css/common.css";
// import "../../css/dashboard.css";
// import "../../css/mvpready-admin.css";
// import "../../css/mvpready-landing.css";

interface IAppProps {
  config: IConfig
}

interface IAppState {
}

class App extends React.Component<IAppProps, IAppState> {
  props: IAppProps;
  render() {
    return <div>
      <Dashboard
          config={this.props.config}
      />
    </div>
  }

}

getConfig().then((config) => {
  $('#loading-indicator').remove();
  ReactDOM.render(<App config={config} />, document.getElementById('search-tool'));
});
