import * as $ from "jquery";
import * as Promise from "bluebird";

export interface IResponse {
    last_list_added_to: number,
    lists: {
        id: number,
        name: string,
        user_id: number
    }[]
}

export function getAllLists(): Promise<IResponse>  {
    return new Promise<IResponse>((resolve, reject) => {
        $.ajax({
            url: "/lists",
            method: "GET",
            success: function (response: {error: boolean, data: IResponse}) {
                resolve(response.data);
            },
            error: function (jqXHR: {responseJSON: {message: string}}) {
                reject(new Error(`/lists returned an error : ${jqXHR.responseJSON.message}`));
            }
        } as JQueryAjaxSettings)
    });
}

export default getAllLists;