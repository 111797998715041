import * as Promise from "bluebird";
import * as $ from "jquery";

import AbstractSuggestAPIFetcher from "./abstract";
import {Country, Codes as CountryCodes} from "../../countries";
import {Language, Codes as LanguageCodes} from "../../languages";
import SupportedCountries from "./bing/supported-countries";
import SupportedLanguages from "./bing/supported-languages";

interface GlobalOptions {
    country: Country;
    language: Language;
}

export default class BingSuggestAPIFetcher extends AbstractSuggestAPIFetcher<GlobalOptions> {

    public getAPIName(): string {
        return "Bing API";
    }

    protected getSupportedCountries(): Country[] {
        return SupportedCountries;
    }

    protected getSupportedLanguages(): Language[] {
        return SupportedLanguages;
    }

    public requiresCountry() {
        return true;
    }

    public requiresLanguage() {
        return true;
    }

    /**
     * Get the URL to be used in the AJAX call
     * @param {string} keyword
     * @returns {string}
     */
    protected getURL(keyword: string): string {
        return `https://api.bing.com/osjson.aspx?query=${encodeURIComponent(keyword)}&mkt=${this.getLanguageCode()}-${this.getCountryCode().toUpperCase()}&jsontype=callback`;
    }

    /**
     * Filter the response keywords
     * @param {string[]} keywords
     * @returns {string[]}
     */
    protected filter(keywords: string[]): string[] {
        return keywords;
    }

    public fetch(keyword: string): Promise<string[]> {
        const varName = "bing" + Date.now() + Math.floor(Math.random()*1000);
        return new Promise<string[]>((resolve, reject) => {
            $.ajax({
                url: this.getURL(keyword),
                method: "GET",
                dataType: "jsonp",
                jsonp: "jsoncallback",
                jsonpCallback: varName,
                timeout: 5000,
                success: (response) => {
                    resolve(this.filter(response[1]));
                },
                error: () => {
                    reject(new Error(this.getErrorMessage()));
                }
            });
        });
    }

}