import * as React from 'react';
import {Popover} from 'react-bootstrap';
import {CountrySelect} from "./country-select-select2";
import {APISelect} from "./api-select";
import {SearchResultTypeSelect} from "./search-result-type-select";
import {Codes as CountryCodes, Country} from "../../lib/api/countries";
import {Codes as LanguageCodes, Language} from "../../lib/api/languages";
import {Identifiers as APICodes, List as API} from "../../lib/api/suggest/fetchers";
import {
    AllKeywordTypes,
    KeywordType as ResultType,
    KeywordTypeCodes as ResultTypeCodes,
    KeywordTypeDescriptions,
    KeywordTypeNames
} from "../../lib/keyword/keyword-types";
import {IFilters} from "../../lib/api/keyword-keg/search/filters";
import {IConfig} from "../../lib/api/keyword-keg/config";
import {KkOverlayTrigger} from "../common/kk-overlay-trigger";

export interface IParameterRowProps {
    onFilterUpdate: (filters: IFilters) => void,
    config: IConfig,
    hideAPI?: boolean,
    resultTypes?: ResultType[],
    selectedTypes?: ResultType[]
    preventMultipleCountrySelection?: boolean
}

interface IParameterRowState {
    selectedCountries?: Country[],
    selectedLanguages?: Language[],
    selectedAPIs?: API[],
    selectedTypes?: ResultType[]
}

export class ParameterRow extends React.Component<IParameterRowProps, IParameterRowState> {

    static defaultProps = {
        resultTypes: AllKeywordTypes,
        selectedTypes: AllKeywordTypes,
        hideAPI: false,
        preventMultipleCountrySelection: false
    };

    warningStyle = {color: "#ffaf5c"};

    constructor(props: IParameterRowProps) {
        super(props);
        this.state = {
            selectedCountries: [Country.UnitedStates],
            selectedLanguages: [Language.English],
            selectedAPIs: [API.Google],
            selectedTypes: props.selectedTypes
        };
    }

    getParameters() {
        return {
            countries: this.state.selectedCountries,
            languages: this.state.selectedLanguages,
            apis: this.state.selectedAPIs,
            resultTypes: this.state.selectedTypes
        }
    }

    getParametersAsCodes() {
        return {
            countries: this.state.selectedCountries.map((c) => CountryCodes[c]),
            languages: this.state.selectedLanguages.map((l) => LanguageCodes[l]),
            apis: this.state.selectedAPIs.map((a) => APICodes[a]),
            resultTypes: this.state.selectedTypes.map((t) => ResultTypeCodes[t])
        }
    }

    canSelectMultipleCountries() {
        return !this.props.preventMultipleCountrySelection && this.props.config.selectMultipleCountries;
    }

    setSelectedCountries = (countries: Country[]) => {
        if (!this.canSelectMultipleCountries() && countries.length > 1) {
            countries = [countries[0]];
        }
        this.setState({selectedCountries: countries}, () => this.props.onFilterUpdate(this.getParameters()));
    };

    setSelectedLanguages = (languages: Language[]) => {
        this.setState({selectedLanguages: languages}, () => this.props.onFilterUpdate(this.getParameters()));
    };

    setSelectedAPIs = (apis: API[]) => {
        if (!this.props.config.selectMultipleApis && apis.length > 1) {
            apis = [apis[0]];
        }
        this.setState({selectedAPIs: apis}, () => this.props.onFilterUpdate(this.getParameters()));
    };

    setSelectedTypes = (types: ResultType[]) => {
        this.setState({selectedTypes: types}, () => this.props.onFilterUpdate(this.getParameters()));
    };

    render() {
        let resultTypePopover = <Popover id={"result-type-selector-popover"}>
            <Popover.Title as="h3">
                You can choose individual result types based on your search or simply select all to get the maximum
                number of results :
            </Popover.Title>
            <Popover.Content>
                {AllKeywordTypes.map((type, i) => {
                    return <p key={type}>
                        <b>{KeywordTypeNames[type]}</b> - {KeywordTypeDescriptions[type]}
                        <br/>
                    </p>
                })}
            </Popover.Content>
        </Popover>;

        let md1 = "col-md-3";
        let md2 = "col-md-6";
        // let md1 = "col-md-4";
        // let md2 = "col-md-4";
        if (this.props.hideAPI) {
            md1 = "col-md-6";
            md2 = "col-md-6";
        }

        return (
            <div className="row gap-y">
                <div className={md1}>
                    <label htmlFor="countries"
                           className="control-label">
                        Countries
                    </label>
                    <CountrySelect
                        id={"countries-select-form-control"}
                        selected={this.state.selectedCountries}
                        onChange={this.setSelectedCountries}
                        multiple={this.canSelectMultipleCountries()}
                    />
                </div>
                {!this.props.hideAPI && <div className={md1}>
                    <label htmlFor="apis"
                           className="control-label">
                        APIs
                    </label>
                    <APISelect
                        id={"apis-select-form-control"}
                        selected={this.state.selectedAPIs}
                        onChange={this.setSelectedAPIs}
                        config={this.props.config}/>
                </div>}
                <div className={md2 + ' result-types-select-form-control-holder'}>
                    <label htmlFor="keyword-result-type"
                           className="control-label">
                        <span className="mr-2">Result Types</span>
                        <KkOverlayTrigger
                            hideOnMobile
                            placement="bottom" trigger={['hover', 'focus']} overlay={resultTypePopover}>
                            <i
                                className="far fa-question-circle text-info"/>
                        </KkOverlayTrigger>
                    </label>
                    <SearchResultTypeSelect
                        id={"result-types-select-form-control"}
                        selected={this.state.selectedTypes}
                        onChange={this.setSelectedTypes}
                        types={this.props.resultTypes}
                    />
                </div>
            </div>
        );
    }

}

export default ParameterRow;
