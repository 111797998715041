export module SERP {

    export enum Types {
        Snippet,
        KnowledgeGraph,
        AdListings,
        LocalListings,
        VideoListings,
        ImageListings,
        FreshListings,
        NewsListings,
        ProductListings
    }

    export let Names: {[type: number]: string} = {};

    Names[Types.Snippet] = "Snippet";
    Names[Types.KnowledgeGraph] = "Knowledge Graph";
    Names[Types.AdListings] = "Ad Listings";
    Names[Types.LocalListings] = "Local Listings";
    Names[Types.VideoListings] = "Video Listings";
    Names[Types.ImageListings] = "Image Listings";
    Names[Types.FreshListings] = "Fresh Listings";
    Names[Types.NewsListings] = "News Listings";
    Names[Types.ProductListings] = "Product Listings";

    export let Descriptions: {[type: number]: string} = {};

    Descriptions[Types.Snippet] = "Google shows a snippet at the top of the SERPs for this keyword.";
    Descriptions[Types.KnowledgeGraph] = "Google shows a knowledge graph on the right hand side of the SERPs for this keyword.";
    Descriptions[Types.AdListings] = "Google shows advertisements at the top of the SERPs for this keyword.";
    Descriptions[Types.LocalListings] = "Google shows local listings at the top of the SERPs for this keyword.";
    Descriptions[Types.VideoListings] = "Google shows videos on the first page of the SERPs for this keyword.";
    Descriptions[Types.ImageListings] = "Google shows images on the first page of the SERPs for this keyword.";
    Descriptions[Types.FreshListings] = "Google marks results in the SERPs with a recent date for this keyword.";
    Descriptions[Types.NewsListings] = "Google shows news listings at the top of the SERPs for this keyword.";
    Descriptions[Types.ProductListings] = "Google shows Product listing ads at the top of the SERPs for this keyword.";

    export let GlyphiconClasses: {[type: number]: string} = {};

    GlyphiconClasses[Types.Snippet] = "fas fa-file-alt";
    GlyphiconClasses[Types.KnowledgeGraph] = "fas fa-chart-bar";
    GlyphiconClasses[Types.AdListings] = "fas fa-money-bill-alt";
    GlyphiconClasses[Types.LocalListings] = "fas fa-map-marker-alt";
    GlyphiconClasses[Types.VideoListings] = "fab fa-youtube";
    GlyphiconClasses[Types.ImageListings] = "fas fa-images";
    GlyphiconClasses[Types.FreshListings] = "fas fa-calendar-alt";
    GlyphiconClasses[Types.NewsListings] = "fas fa-video";
    GlyphiconClasses[Types.ProductListings] = "fas fa-shopping-cart";

    //TODO - remove
    // GlyphiconClasses[Types.Snippet] = "fa-file-text-o";
    // GlyphiconClasses[Types.KnowledgeGraph] = "fa-bar-chart-o";
    // GlyphiconClasses[Types.AdListings] = "fa-money";
    // GlyphiconClasses[Types.LocalListings] = "fa-map-marker";
    // GlyphiconClasses[Types.VideoListings] = "fa-youtube-play";
    // GlyphiconClasses[Types.ImageListings] = "fa-picture-o";
    // GlyphiconClasses[Types.FreshListings] = "fa-calendar";
    // GlyphiconClasses[Types.NewsListings] = "fa-video-camera";
    // GlyphiconClasses[Types.ProductListings] = "fa-shopping-cart";

    export let MetricColumnCodes: {[type: number]: string | string[]} = {};

    MetricColumnCodes[Types.Snippet] = "has_snippet";
    MetricColumnCodes[Types.KnowledgeGraph] = "has_knowledge_graphs";
    MetricColumnCodes[Types.AdListings] = ['total_ads_top', 'total_ads_bottom'];
    MetricColumnCodes[Types.LocalListings] = "total_local";
    MetricColumnCodes[Types.VideoListings] = "total_videos";
    MetricColumnCodes[Types.ImageListings] = "total_images";
    MetricColumnCodes[Types.FreshListings] = "total_fresh";
    MetricColumnCodes[Types.NewsListings] = "total_news";
    MetricColumnCodes[Types.ProductListings] = "total_pla";

    export const All: Types[] = [
        Types.Snippet,
        Types.KnowledgeGraph,
        Types.AdListings,
        Types.LocalListings,
        Types.VideoListings,
        Types.ImageListings,
        Types.FreshListings,
        Types.NewsListings,
        Types.ProductListings
    ];

}