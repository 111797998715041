import * as React from "react";
import * as _ from "lodash";
import {Popover} from "react-bootstrap";
import {CopyKeywordsButton} from "./copy-keywords-button";
import {DeleteKeywordsButton} from "./delete-keywords-button";
import {AddToListButton} from "./add-to-list-button";
import {ExportDropdown} from "./export-dropdown";
import {count} from "../../lib/search/filter";
import {IFilters, IUserFilter} from "../../lib/api/keyword-keg/search/filters";
import {AddToListModal} from "./add-selected-to-list-modal";
import {NegativeKeywordsModal} from "./negative-keywords-modal";
import {FilterModal} from "./filter-modal";
import {IndustryInterface} from "../../lib/api/keyword-keg/search/industries";
import {Codes as CountryCodes} from "../../lib/api/countries";
import {SearchResultTypeSelect} from "../dashboard/search-result-type-select";
import {IColumn, IKeywordResult, IPagination, IParams as IResultParams} from "../../lib/api/keyword-keg/sf/results";
import {IConfig} from "../../lib/api/keyword-keg/config";
import {
    AllKeywordTypes,
    BulkUploadKeywordTypes,
    KeywordType as ResultType,
    KeywordTypeDescriptions,
    KeywordTypeNames
} from "../../lib/keyword/keyword-types";
import {IKeywordsSource} from "../../lib/api/keyword-keg/keywords-source";
import {FilterButton} from './filter-button';
import {KkOverlayTrigger} from "./kk-overlay-trigger";
import {FilterTags} from "../dashboard/filter-tags";

export interface ToolbarProps {
    config: IConfig,
    paginationList: number[],
    handleResultsPerPageChanged: (rpp: number) => void,
    getExportName: () => string,
    resultsPerPage: number,
    pageKeywords: IKeywordResult[],
    selectedKeywords: IKeywordResult[],
    allKeywordsSelected: boolean,
    filters: IFilters,
    loadedUserFilters: IUserFilter[],
    appliedFilters: IUserFilter,
    handleDeleteKeywords?: (keywords: IKeywordResult[], all: boolean, then: () => void) => void,
    onNegativeKeywordsChanged: (keywords: string[], getResults: boolean) => void,
    negativeKeywords: string[],
    onFilterKeywordUpdate: (value: string) => void,
    onFilterResultTypeChange?: (selectedTypes: ResultType[]) => void,
    updateFilters: (changes: IFilters, userFilter?: IUserFilter) => void,
    applyUserFilter: (uf: IUserFilter) => void,
    industries: { [id: number]: IndustryInterface },
    currency?: { symbol: string, rate: number },
    lhsToolbarElements?: React.ReactElement<any> | React.ReactElement<any>[] | boolean,
    rhsToolbarElements?: React.ReactElement<any> | boolean,
    deleteKeywordsButton?: React.ReactElement<any> | boolean,
    enableResultTypeFilter?: boolean,
    keywordsSource: IKeywordsSource,
    getResultParams: () => Partial<IResultParams>,
    processing?: boolean,
    onBeforeExport?: () => boolean,
    redirectFreeUser?: () => boolean,
    pagination: IPagination,
    wrapperClasses?: string,
    searchColumns: IColumn[],
}

interface ToolbarState {
    showAddToListModal?: boolean,
    addToListKeywords?: IKeywordResult[],
    showNegativeKeywordsModal?: boolean,
    showFilterModal?: boolean,
    selectedTypes?: ResultType[],
    exportInProgress: boolean
}

export interface IToolbarHolderStateInterface {
    keywords?: IKeywordResult[],
    searchedCountry?: string,
    filteredKeywords?: string[],
    selectedKeywords?: IKeywordResult[],
    allKeywordsSelected?: boolean,
    negativeKeywords?: string[],
    resultsPerPage?: number
}

export class Toolbar extends React.Component<ToolbarProps, ToolbarState> {

    private filterModal: FilterModal;

    // noinspection JSUnusedGlobalSymbols
    static defaultProps = {
        lhsToolbarElements: false,
        rhsToolbarElements: false,
        deleteKeywordsButton: false,
        enableResultTypeFilter: false,
        selectedKeywords: [] as IKeywordResult[],
        currency: {rate: 1, symbol: "$"},
        processing: false,
        onFilterResultTypeChange: () => {
        }
    };

    state: ToolbarState = {
        showAddToListModal: false,
        addToListKeywords: [],
        showNegativeKeywordsModal: false,
        showFilterModal: false,
        selectedTypes: [],
        exportInProgress: false,
    };

    hideAddToListModal = () => {
        this.setState({
            addToListKeywords: [],
            showAddToListModal: false
        });
    };

    handleDelete = (keywords: IKeywordResult[], all: boolean, then: () => void) => {
        if (this.redirectFreeUser()) {
            return;
        }
        this.props.handleDeleteKeywords(keywords, all, then);
    };

    handlePaginationSelectChange = (value: string) => {
        this.props.handleResultsPerPageChanged(Number(value));
    };

    handleKeywordFilterChange = _.debounce((value: string) => {
        this.props.onFilterKeywordUpdate(value);
    }, 300);

    getUserMaxKeywords = (keywords: IKeywordResult[]) => () => {
        if (this.props.config.rowLimit === null) {
            return keywords;
        }
        const limit = (this.props.config.auth.authenticated && this.props.config.auth.user.plan) ? keywords.length : this.props.config.rowLimit;
        return keywords.slice(0, limit);
    };

    getExportCountry = () => {
        if (!this.props.config.auth.user.alwaysUsePreferredCountry
            && this.props.filters.countries
            && this.props.filters.countries.length == 1) {
            return CountryCodes[this.props.filters.countries[0]];
        }
        if (this.props.config.auth.user.preferredCountry) {
            return this.props.config.auth.user.preferredCountry.code;
        }
        return 'all';
    };

    areAllWordsSelected = () => this.props.allKeywordsSelected || this.props.selectedKeywords.length === 0;

    handleNegativeKeywordsClicked = () => {
        this.redirectFreeUser() || this.setState({showNegativeKeywordsModal: true});
    };

    handleFilterClicked = (e?: React.MouseEvent<HTMLAnchorElement>) => {
        if (e) {
            e.preventDefault();
        }
        this.redirectFreeUser() || this.setState({showFilterModal: true});
    };

    handleClearFilters = (e?: React.MouseEvent<HTMLAnchorElement>) => {
        if (e) {
            e.preventDefault();
        }
        this.redirectFreeUser() || this.filterModal.resetFilters();
    };

    handleNewFilter = (e?: React.MouseEvent<HTMLAnchorElement>) => {
        if (e) {
            e.preventDefault();
        }
        if (this.redirectFreeUser()) {
            return;
        }
        this.filterModal.resetFilters();
        this.setState({showFilterModal: true});
    };

    getExportLanguage = () => {
        return "en";
    }

    getExportName = () => {
        return (this.props.getExportName && this.props.getExportName());
    }

    handleNegativeKeywordsModalHidden = () => {
        this.setState({showNegativeKeywordsModal: false});
    }

    handleFilterModalHidden = () => {
        this.setState({showFilterModal: false});
    }

    setSelectedTypes = (types: ResultType[]) => {
        this.setState({selectedTypes: types}, () => {
            this.props.onFilterResultTypeChange(this.state.selectedTypes);
        });
    };

    redirectFreeUser() {
        if (this.props.redirectFreeUser) {
            return this.props.redirectFreeUser();
        }
        if (!this.props.config.auth.authenticated) {
            window.location.href = "/auth/login";
            return true;
        } else if (this.props.config.auth.authenticated && !this.props.config.auth.user.plan) {
            window.location.href = "/pricing";
            return true;
        }
        return false;
    }

    isFreeUser() {
        return !this.props.config.auth.authenticated
            || this.props.config.auth.authenticated && !this.props.config.auth.user.plan;
    }

    onBeforeExport = () => {
        if (this.props.onBeforeExport) {
            return this.props.onBeforeExport();
        }
        return !this.redirectFreeUser();
    }

    renderAddToListButton() {
        return (
            <AddToListButton
                getKeywords={this.getUserMaxKeywords(this.props.selectedKeywords)}
                isAll={() => this.addAllToList()}
                handleAddToList={this.handleAddToList}
            />
        );
    }

    addAllToList() {
        return !this.props.allKeywordsSelected && this.props.selectedKeywords.length == 0;
    }

    exportAll() {
        return !this.props.allKeywordsSelected && this.props.selectedKeywords.length == 0;
    }

    handleAddToList = () => {
        if (this.redirectFreeUser()) {
            return;
        }
        const selections = this.props.selectedKeywords;
        this.showAddToListModal(selections);
    };

    showAddToListModal = (selections: IKeywordResult[]) => {
        this.setState({
            addToListKeywords: selections,
            showAddToListModal: true
        });
    };

    renderDeleteKeywordsButton() {
        if (this.props.deleteKeywordsButton) {
            return this.props.deleteKeywordsButton;
        }
        return (this.props.selectedKeywords.length > 0) && <DeleteKeywordsButton
            getKeywords={this.getUserMaxKeywords(this.props.selectedKeywords)}
            isAll={() => false}
            onDelete={this.handleDelete}
        />;
    }

    getKeywordsToCopy = () => {
        let words = this.props.selectedKeywords;
        if (words.length == 0) {
            words = this.props.pageKeywords;
        }
        let kws = this.getUserMaxKeywords(words);
        return kws();
    }

    renderCopyButton() {
        return <CopyKeywordsButton
            getKeywords={this.getKeywordsToCopy}
            isAll={() => false}
            searchColumns={this.props.searchColumns}
            currencySymbol={this.props.currency.symbol}
            config={this.props.config}
        />;
    }

    getKeywordsToExport = () => {
        if (this.exportAll()) {
            return [];
        }
        return this.props.selectedKeywords;
    }

    renderNegativeKeywordsButton() {
        let popover = <Popover
            id={"negative-keywords-popup-button"}
        >
            <Popover.Content>
                Use negative keyword filter to hide phrases you are not interested in ranking for
            </Popover.Content>
        </Popover>;
        return <KkOverlayTrigger placement="bottom" overlay={popover}>
            <button className="btn btn-primary"
                    onClick={this.handleNegativeKeywordsClicked}
            >
                {`Negative Keywords `}
                <span className="badge badge-light">{this.props.negativeKeywords.length}</span>
            </button>
        </KkOverlayTrigger>;
    }

    renderPaginationSelect() {
        return (
            <div className="btn-group">
                <button id="export-dropdown" type="button" className="btn btn-alternate dropdown-toggle"
                        data-toggle="dropdown">
                    {`Show ${this.props.resultsPerPage}`}
                </button>
                <div className="dropdown-menu">
                    {this.props.paginationList.map((num: number, i: number) => {
                        return <a className="dropdown-item"
                                  href="#"
                                  key={`pagination-item-${i}`}
                                  onClick={e => {
                                      e.preventDefault();
                                      this.handlePaginationSelectChange(`${num}`);
                                  }}
                        >{`Show ${num}`}</a>;
                    })}
                </div>
            </div>
        );
    }

    renderExportDropdown(){
        return (
            <ExportDropdown
                keywords={this.getKeywordsToExport()}
                exportAll={this.exportAll()}
                keywordsSource={this.props.keywordsSource}
                getResultParams={this.props.getResultParams}
                getName={this.getExportName}
                getCountry={this.getExportCountry}
                getLanguage={this.getExportLanguage}
                checkStatusTimeout={2000}
                showSelected={this.props.selectedKeywords.length > 0}
                selectedCount={this.props.selectedKeywords.length}
                showSubscriptionError={false}
                onBeforeExport={this.onBeforeExport}
                config={this.props.config}
                pagination={this.props.pagination}
                onExportStart={this.onExportStart}
                onExportComplete={this.onExportComplete}
            />
        );
    }

    renderExportInProgressMessage() {
        return this.state.exportInProgress && <div className="alert text-center">
            <p>
                As soon as your export file is generated, it will automatically download.
                You can always come back to <a target="_blank" href="/exports">your exports page</a> and download this
                file directly, once it's generated.
            </p>
        </div>;
    }

    onExportStart = () => {
        this.setState({
            exportInProgress: true
        });
    };

    onExportComplete = () => {
        this.setState({
            exportInProgress: false
        });
    };

    render() {

        let resultTypePopover = <Popover id={"result-type-help"}>
            <Popover.Title as="h3">
                You can choose individual result types based on your search or simply select all to get the maximum
                number of results :
            </Popover.Title>
            <Popover.Content>
                {BulkUploadKeywordTypes.map((type, i) => {
                    return <p key={type}>
                        <b>{KeywordTypeNames[type]}</b> - {KeywordTypeDescriptions[type]}
                        <br/>
                    </p>
                })}
            </Popover.Content>
        </Popover>;

        return (
            <div className="bg-light border-bottom border-top">
                <div className="container-fluid py-4">
                    <div className="btn-toolbar align-items-center">
                        {
                            this.props.lhsToolbarElements
                        }
                        <div className="btn-group nogroup mt-3 mt-md-0 mr-md-3">
                            {this.renderAddToListButton()}
                            {this.renderCopyButton()}
                            {this.renderDeleteKeywordsButton()}
                            {this.renderNegativeKeywordsButton()}
                        </div>
                        <div className="btn-group nogroup mt-4 mt-md-0 mr-md-3 btn-group-filter-search">
                            {this.renderPaginationSelect()}
                            <FilterButton
                                filters={this.props.filters}
                                appliedFilters={this.props.appliedFilters}
                                loadedUserFilters={this.props.loadedUserFilters}
                                openFilterModal={this.handleFilterClicked}
                                newFilters={this.handleNewFilter}
                                clearFilters={this.handleClearFilters}
                                applyUserFilter={this.props.applyUserFilter}
                            />
                            {!this.isFreeUser() && <div className="input-group mt-3 mt-md-0 ml-md-2">
                                <input
                                    className="form-control"
                                    placeholder="Filter keywords"
                                       onChange={(e) => this.handleKeywordFilterChange($(e.target).val())}/>
                            </div>}
                        </div>
                        {this.renderExportDropdown()}
                        {this.props.enableResultTypeFilter && (
                            <div className={"btn-group nogroup mt-3 mt-md-0 mr-md-3"}>
                                <SearchResultTypeSelect
                                    selectId='keyword-result-type-filter'
                                    makeInline={true}
                                    selected={this.state.selectedTypes}
                                    onChange={this.setSelectedTypes}
                                    types={BulkUploadKeywordTypes}
                                    placeholder={"Choose Result Types"}
                                />
                                <KkOverlayTrigger placement="bottom" trigger={['hover', 'focus']}
                                                  overlay={resultTypePopover}>
                                    <span className="btn btn-circle far fa-question-circle text-info"></span>
                                </KkOverlayTrigger>
                            </div>
                        )}
                    </div>
                    {count(this.props.filters) > 0 && <FilterTags filters={this.props.filters} industries={this.props.industries}
                                                              uiPermissions={this.props.config.ui.permissions}/>}
                    {this.renderExportInProgressMessage()}
                </div>

                {this.props.config.auth.authenticated && <AddToListModal
                    show={this.state.showAddToListModal}
                    onHide={this.hideAddToListModal}
                    keywords={this.state.addToListKeywords}
                    addAll={this.addAllToList()}
                    keywordsSource={this.props.keywordsSource}
                    getResultParams={this.props.getResultParams}
                />}
                {this.props.config.auth.authenticated && <NegativeKeywordsModal
                    show={this.state.showNegativeKeywordsModal}
                    onHide={this.handleNegativeKeywordsModalHidden}
                    onNegativeKeywordsChange={this.props.onNegativeKeywordsChanged}
                />}
                {<FilterModal

                    ref={(me) => {
                        this.filterModal = me;
                    }}

                    show={this.state.showFilterModal}
                    onHide={this.handleFilterModalHidden}
                    onChange={this.props.updateFilters}
                    allowSERPFilter={this.props.config.ui.permissions.serpFilter}
                    allowCTROpportunityFilter={this.props.config.ui.permissions.ctr_opportunity}
                    allowKeywordPotentialFilter={this.props.config.ui.permissions.kw_potential}
                    allowSEODifficultyFilter={this.props.config.ui.permissions.seo_difficulty}
                    industries={this.props.industries}
                    currency={this.props.currency}
                    filters={this.props.filters}
                    userFilter={this.props.appliedFilters}
                />}

            </div>
        );
    }
}
