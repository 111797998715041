import * as Promise from "bluebird";
import * as $ from "jquery";

import AbstractSuggestAPIFetcher from "./abstract";
import {Country, Codes as CountryCodes} from "../../countries";
import {Language, Codes as LanguageCodes} from "../../languages";
import SupportedCountries from "./alibaba/supported-countries";
import SupportedLanguages from "./alibaba/supported-languages";

interface GlobalOptions {
    language: Language
}

export default class FiverrSuggestAPIFetcher extends AbstractSuggestAPIFetcher<GlobalOptions> {

    public getAPIName(): string {
        return "Fiverr API";
    }

    protected getSupportedCountries(): Country[] {
        return SupportedCountries;
    }

    protected getSupportedLanguages(): Language[] {
        return SupportedLanguages;
    }

    public requiresCountry() {
        return false;
    }

    public requiresLanguage() {
        return true;
    }

    /**
     * Get the URL to be used in the AJAX call
     * @param {string} keyword
     * @returns {string}
     */
    protected getURL(keyword: string): string {
        if(LanguageCodes[this.options.language] == "en") {
            return `https://www.fiverr.com/search/autocomplete?query=${encodeURIComponent(keyword)}`;
        } else {
            return `https://${this.getLanguageCode()}.fiverr.com/search/autocomplete?query=${encodeURIComponent(keyword)}`;
        }
    }

    /**
     * Filter the response keywords
     * @param {string[]} keywords
     * @returns {string[]}
     */
    protected filter(keywords: string[]): string[] {
        //Ask API returns HTML
        return keywords;
    }

    public fetch(keyword: string): Promise<string[]> {
        const varName = "fiverr" + Date.now() + Math.floor(Math.random()*1000);
        return new Promise<string[]>((resolve, reject) => {
            $.ajax({
                url: this.getURL(keyword),
                method: "GET",
                dataType: "jsonp",
                jsonp: "callback",
                jsonpCallback: varName,
                timeout: 5000,
                success: (response: {suggestions: {value: string}[]}) => {
                    resolve(this.filter(response.suggestions.map((r) => r.value)));
                },
                error: () => {
                    reject(new Error(this.getErrorMessage()));
                }
            });
        });
    }

}