import * as React from "react";
import {OverlayTrigger, OverlayTriggerProps} from "react-bootstrap";
import {isMobile} from "react-device-detect";

export class KkOverlayTrigger extends React.Component<OverlayTriggerProps & {
  hideOnMobile?: boolean
}>{
  render(){
    let {children, hideOnMobile,  ...others} = this.props;
    if(isMobile){
      if(hideOnMobile){
        return children;
      }
    }
    return (
      <OverlayTrigger {...others}>
        {children}
      </OverlayTrigger>
    );
  }
}
