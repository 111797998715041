import * as React from 'react';

import {TableRow} from './table-row';
import {IColumn, IKeywordResult, IPagination} from "../../lib/api/keyword-keg/sf/results";
import {IConfig} from "../../lib/api/keyword-keg/config";
import {binarySearch} from "../../lib/util/binary-search";

interface IProps {
  searchResults: IKeywordResult[],
  pagination: IPagination,
  allowSelect: boolean,
  searchColumns: IColumn[],
  selectionChange: (r: IKeywordResult, selected: boolean) => void,
  config: IConfig,
  hasDeducedTypes: boolean,
  currencySymbol: string,
  showDetails: (keyword: IKeywordResult) => void,
  selected: IKeywordResult[],
  selectedIds: number[],
  clearSelectedKeywords: () => void,
  blurred?: boolean
}

interface IState {
}

export class TableBody extends React.Component<IProps, IState> {

  props: IProps;
  state: IState = {
  };

  willChangeSelection(nextProps: IProps) {
    return this.props.selected.length != nextProps.selected.length;
  }

  shouldComponentUpdate(nextProps: IProps, nextState: IState) {
    let willChangePage = this.willChangePage(nextProps);
    let willChangeSelection = this.willChangeSelection(nextProps);
    let willChangeColumns = this.props.searchColumns.length !== nextProps.searchColumns.length;
    return willChangePage || willChangeSelection || willChangeColumns;
  }

  willChangePage(nextProps: {searchResults: IKeywordResult[], pagination: IPagination}) {
    if (!!this.props.searchResults != !!nextProps.searchResults) {
      return true;
    }
    if (this.props.searchResults.length != nextProps.searchResults.length) {
      return true;
    }
    if (!this.props.searchResults || this.props.searchResults.length == 0) {
      return false;
    }
    return this.props.searchResults[0].result_id !== nextProps.searchResults[0].result_id
      || this.props.searchResults[this.props.searchResults.length - 1].result_id
      !== nextProps.searchResults[nextProps.searchResults.length - 1].result_id
      //Detect pagination change
      || this.props.pagination.last_page !== nextProps.pagination.last_page
      || this.props.pagination.current_page !== nextProps.pagination.current_page
      || this.props.pagination.last_index !== nextProps.pagination.last_index;
  }

  isSelected = (keyword: IKeywordResult) => {
    return binarySearch(this.props.selectedIds, keyword.result_id, (a: number, b: number) => a - b) >= 0;
  };

  render(){
    return (
        <tbody>
        {this.props.blurred && <tr>
          <td className="p-0" colSpan={12}>
            <div className="subscribe-banner text-center">
              <a href="/pricing" className="btn btn-danger btn-lg text-center">Purchase a plan to see all the data</a>
            </div>
          </td>
        </tr>}
        {this.props.searchResults.map((r: IKeywordResult, i) => {
          return <TableRow
              allowSelect={this.props.allowSelect}
              searchColumns={this.props.searchColumns}
              key={`${r.result_id}-${i + this.props.pagination.first_index}`}
              result={r}
              selectionChange={this.props.selectionChange}
              showDetails={this.props.showDetails}
              config={this.props.config}
              hasDeducedTypes={this.props.hasDeducedTypes}
              currencySymbol={this.props.currencySymbol}
              selected={this.isSelected(r)}
          />;
        })}
        </tbody>
    );
  }

}

export default TableBody;