export interface IGrecaptcha {
  render: (container: string,
    parameters: {
      sitekey: string,
      callback: (token: string) => any,
      size?: string
    },
    inherit?: string
  ) => any,
  execute: (recaptchaWidgetId?: string) => any,
  reset: (recaptchaWidgetId?: string) => any,
  getResponse: () => any,
}
export function onRecaptchaLoad(callback: (grecaptcha: IGrecaptcha) => any) {
  if (typeof grecaptcha !== 'undefined' && typeof grecaptcha.execute !== 'undefined') {
    callback(grecaptcha);
  } else {
    setTimeout(() => onRecaptchaLoad(callback), 100);
  }
}