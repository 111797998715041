import * as React from "react";
import TreeSelect from 'rc-tree-select';
import * as _ from "lodash";
import {IndustryInterface} from "../../../lib/api/keyword-keg/search/industries";

import '../../../../css/rc-tree-select.css';

export interface IIndustryFilterProps {
    industries: { [id: number]: IndustryInterface },
    selectedIndustries: number[],
    handleIndustriesChange: (industries: number[]) => void
}

interface IIndustryFilterState {
}

export class IndustryFilter extends React.Component<IIndustryFilterProps, IIndustryFilterState> {

    buildIndustryTree() {
        let list = _.values(this.props.industries).map((industry: IndustryInterface) => {
            return {
                id: industry.id,
                value: `${industry.id}`,
                label: industry.name,
                parent_id: `${industry.parent_id}`,
                children: []
            };
        });
        let treeList = [];
        let lookup = {};
        list.forEach(function (obj) {
            lookup[obj.id] = obj;
        });
        list.forEach(function (obj) {
            if (obj['parent_id'] !== '0') {
                lookup[obj['parent_id']]['children'].push(obj);
            } else {
                treeList.push(obj);
            }
        });
        return treeList;
    };

    shouldComponentUpdate(nextProps: IIndustryFilterProps, nextState: IIndustryFilterState) {
        if (_.isEqual(this.props.selectedIndustries, nextProps.selectedIndustries)) {
            return false;
        }
        return !_.isEqual(_.sortBy(this.props.selectedIndustries), _.sortBy(nextProps.selectedIndustries));
    }

    render(): JSX.Element | false | null {
        return (
            <div className="form-group">
                <label className="control-label">Industry:</label>
                <div className="input-group">
                    <TreeSelect
                        style={{width: "100%"}}
                        transitionName="rc-tree-select-dropdown-slide-up"
                        choiceTransitionName="rc-tree-select-selection__choice-zoom"
                        dropdownStyle={{maxHeight: 300, overflow: "auto", zIndex: 9999999}}
                        placeholder={<i>Select industry</i>}
                        allowClear treeLine treeCheckable multi
                        value={this.props.selectedIndustries}
                        treeData={this.buildIndustryTree()}
                        treeNodeFilterProp="label"
                        filterTreeNode={false}
                        showCheckedStrategy={'SHOW_PARENT'}
                        onChange={this.props.handleIndustriesChange}
                    />
                </div>
            </div>
        );
    }
}
