import * as $ from "jquery";
import * as Promise from "bluebird";

export interface IColumn {
  id: string,
  name: string,
  description: string
}

export interface IResponse {
  columns: IColumn[],
  default_sort_column: string,
  default_sort_order: "asc" | "desc"
}

export function getColumns(): Promise<IResponse> {
  return new Promise<IResponse>((resolve, reject) => {
    $.ajax({
      url: "/search/columns",
      method: "GET",
      success: function (response: {error: boolean, data: IResponse}) {
        resolve(response.data);
      },
      error: function (jqXHR: {responseJSON: {message: string}}) {
        reject(new Error(`/search/columns returned an error : ${jqXHR.responseJSON.message}`));
      }
    } as JQueryAjaxSettings)
  });
}

export default getColumns;