import * as React from 'react';
import {IKeywordResult} from "../../lib/api/keyword-keg/sf/results";

export interface IDeleteKeywordsButtonProps {
  isAll: () => boolean,
  getKeywords: () => IKeywordResult[],
  onDelete: (keywords: IKeywordResult[], isAll: boolean, then: () => void) => void
}

interface IDeleteKeywordsButtonState {
  working: boolean
}

export class DeleteKeywordsButton extends React.Component<IDeleteKeywordsButtonProps, IDeleteKeywordsButtonState> {

  props: IDeleteKeywordsButtonProps;
  state: IDeleteKeywordsButtonState = {
    working: false
  };

  handleDelete = () => {
    this.setState({
      working: true
    }, () => {
      let toDelete = this.props.isAll() ? [] : this.props.getKeywords();
      this.props.onDelete(toDelete, this.props.isAll(), () => {
        this.setState({
          working: false
        });
      });
    });
  };

  getButtonText() {
    if (this.state.working) {
      return <span><i className="fas fa-spinner fa-spin" /> Deleting ...</span>;
    }
    return (
      <span>
        Delete {this.props.isAll() ? "all" : <span className="badge badge-light">{this.props.getKeywords().length}</span>}
      </span>
    )
  }

  render() {
    return <button className="btn btn-primary" onClick={this.handleDelete} disabled={this.state.working}>
      {this.getButtonText()}
    </button>
  }

}

export default DeleteKeywordsButton;