import * as _ from "lodash";

export enum KeywordType {
    Alphabetical,
    Questions,
    BuyerIntent,
    ProductInfo,
    Prepositions,
    Comparisons
}

export let KeywordTypeNames: {[type: number]: string} = {};

KeywordTypeNames[KeywordType.Alphabetical] = "Alphabetical";
KeywordTypeNames[KeywordType.Questions] = "Questions";
KeywordTypeNames[KeywordType.BuyerIntent] = "Buyer Intent";
KeywordTypeNames[KeywordType.ProductInfo] = "Product Info";
KeywordTypeNames[KeywordType.Prepositions] = "Prepositions";
KeywordTypeNames[KeywordType.Comparisons] = "Comparisons";

export let KeywordTypeFilters: {[type: number]: string[] | null} = {};

KeywordTypeFilters[KeywordType.Alphabetical] = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
KeywordTypeFilters[KeywordType.Questions] = ['are', 'can', 'best ways', 'did', 'do', 'does', 'how', 'i need', 'is', 'should', 'was', 'what', 'when', 'where', 'which', 'who', 'whose', 'why', 'will', 'would'];
KeywordTypeFilters[KeywordType.BuyerIntent] = ['auction', 'bargain', 'buy', 'coupon', 'cash back', 'clearance', 'closeout', 'discount', 'deal', 'deals', 'shipping', 'for sale', 'for rent', 'offer', 'order'];
KeywordTypeFilters[KeywordType.ProductInfo] = ['affordable', 'best',  'biggest', 'bonus', 'budget', 'cheap', 'cheapest', 'compare', 'comparison', 'expensive', 'fast', 'faster', 'guide', 'inexpensive', 'low cost', 'low priced', 'luxury', 'review', 'scam', 'second hand', 'software', 'solution', 'step by step', 'top', 'used', 'videos'];
KeywordTypeFilters[KeywordType.Prepositions] = ['for', 'like', 'near', 'to', 'versus', 'with', 'without'];

export let KeywordTypeCodes: {[type: number]: string} = {};

KeywordTypeCodes[KeywordType.Alphabetical] = "alphabetical";
KeywordTypeCodes[KeywordType.Questions] = "questions";
KeywordTypeCodes[KeywordType.BuyerIntent] = "buyer-intent";
KeywordTypeCodes[KeywordType.ProductInfo] = "product-info";
KeywordTypeCodes[KeywordType.Prepositions] = "prepositions";
KeywordTypeCodes[KeywordType.Comparisons] = "comparisons";

export let KeywordTypeDescriptions: {[type: number]: string} = {};

KeywordTypeDescriptions[KeywordType.Alphabetical] = "This shows you all related keywords found in alphabetical order.";
KeywordTypeDescriptions[KeywordType.Questions] = "This shows you all related keywords that comprise a question. Very useful for figuring out blog content ideas.";
KeywordTypeDescriptions[KeywordType.BuyerIntent] = "This shows you all related keywords where the searcher is probably ready to buy a product.";
KeywordTypeDescriptions[KeywordType.ProductInfo] = "This shows you all related keywords where the searcher is doing research on products.";
KeywordTypeDescriptions[KeywordType.Prepositions] = "This shows you all related keywords that have prepositions in them.";
KeywordTypeDescriptions[KeywordType.Comparisons] = "This shows you all related keywords where the searcher is comparing different items.";

export const AllKeywordTypes: KeywordType[] = [
    KeywordType.Alphabetical,
    KeywordType.Questions,
    KeywordType.BuyerIntent,
    KeywordType.ProductInfo,
    KeywordType.Prepositions,
    KeywordType.Comparisons,
];

export const BulkUploadKeywordTypes: KeywordType[] = [
    KeywordType.Questions,
    KeywordType.BuyerIntent,
    KeywordType.ProductInfo,
    KeywordType.Prepositions,
    KeywordType.Comparisons,
];

export default KeywordType;