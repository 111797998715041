import * as React from 'react';
import {IKeywordResult} from "../../lib/api/keyword-keg/sf/results";

interface IAddToListButtonProps {
  isAll: () => boolean,
  getKeywords: () => IKeywordResult[],
  handleAddToList: () => void
}

interface IState {
}

export class AddToListButton extends React.Component<IAddToListButtonProps, IState> {

  props: IAddToListButtonProps;
  state: IState = {
  };

  handleAddToList = () => {
    this.props.handleAddToList();
  };

  getButtonText() {
    if (this.props.isAll()) {
      return (
        <span>
          Add All To List
      </span>
      )
    }
    return (
      <span>
        {this.props.getKeywords().length == 1 ?
          `Add 1 Record To List` :
          `Add ${this.props.getKeywords().length} Records To List`}
      </span>
    )
  }

  render() {
    return <button className="btn btn-primary" onClick={this.handleAddToList}>
      {this.getButtonText()}
    </button>
  }

}

export default AddToListButton;