import * as React from 'react';

import {CheckTableCell} from './check-table-cell';
import {DataTableCell} from './data-table-cell';
import {IColumn, IKeywordResult} from "../../lib/api/keyword-keg/sf/results";
import {IConfig} from "../../lib/api/keyword-keg/config";

interface IProps {
  result: IKeywordResult,
  allowSelect: boolean,
  searchColumns: IColumn[],
  selectionChange: (r: IKeywordResult, selected: boolean) => void,
  showDetails: (keyword: IKeywordResult) => void,
  config: IConfig,
  hasDeducedTypes: boolean,
  currencySymbol: string,
  selected: boolean
}

interface IState {
}

export class TableRow extends React.Component<IProps, IState> {

  props: IProps;
  state: IState = {
  };
  
  shouldBlur(r: IKeywordResult) {
    return r.search_result.indexOf('***redacted***') !== -1;
  }

  shouldComponentUpdate(nextProps: IProps, nextState: IState) {
    return this.props.result.result_id !== nextProps.result.result_id
        || this.props.selected != nextProps.selected
        || this.props.searchColumns.length !== nextProps.searchColumns.length;
  }

  render() {
    let r = this.props.result;
    let blur = this.shouldBlur(r);
    return <tr>
      {this.props.allowSelect && <CheckTableCell
        checked={this.props.selected}
        selectionChange={this.props.selectionChange}
        result={r}
        blur={blur} />}
      {this.props.searchColumns.map((column, columnIndex) => {
        return <DataTableCell
          blur={blur}
          result={r}
          column={column}
          currencySymbol={this.props.currencySymbol}
          config={this.props.config}
          hasDeducedTypes={this.props.hasDeducedTypes}
          key={`data-cell-${columnIndex}`}
          showDetails={this.props.showDetails}
        />
      })}
    </tr>
  }

}

export default TableRow;