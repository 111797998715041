import FetcherInterface from "./fetcher";
import Country from "../countries";
import Language from "../languages";

import AmazonAPIFetcher from "./fetchers/amazon";
import AskAPIFetcher from "./fetchers/ask";
import BingAPIFetcher from "./fetchers/bing";
import GoogleAPIFetcher from "./fetchers/google";
import WikiPediaAPIFetcher from "./fetchers/wikipedia";
import YahooAPIFetcher from "./fetchers/yahoo";
import YandexAPIFetcher from "./fetchers/yandex";
import YouTubeAPIFetcher from "./fetchers/youtube";
import EBayAPIFetcher from "./fetchers/ebay";
import AlibabaAPIFetcher from "./fetchers/alibaba";
import PlayStoreAPIFetcher from "./fetchers/play-store";
import FiverrAPIFetcher from "./fetchers/fiverr";

import AmazonAPISupportedCountries from "./fetchers/amazon/supported-countries";
import AskAPISupportedCountries from "./fetchers/ask/supported-countries";
import BingAPISupportedCountries from "./fetchers/bing/supported-countries";
import GoogleAPISupportedCountries from "./fetchers/google/supported-countries";
import WikiPediaAPISupportedCountries from "./fetchers/wikipedia/supported-countries";
import YahooAPISupportedCountries from "./fetchers/yahoo/supported-countries";
import YandexAPISupportedCountries from "./fetchers/yandex/supported-countries";
import YouTubeAPISupportedCountries from "./fetchers/youtube/supported-countries";
import EbayAPISupportedCountries from "./fetchers/ebay/supported-countries";
import AlibabaAPISupportedCountries from "./fetchers/alibaba/supported-countries";
import PlayStoreAPISupportedCountries from "./fetchers/play-store/supported-countries";
import FiverrAPISupportedCountries from "./fetchers/fiverr/supported-countries";

import AmazonAPISupportedLanguages from "./fetchers/amazon/supported-languages";
import AskAPISupportedLanguages from "./fetchers/ask/supported-languages";
import BingAPISupportedLanguages from "./fetchers/bing/supported-languages";
import GoogleAPISupportedLanguages from "./fetchers/google/supported-languages";
import WikiPediaAPISupportedLanguages from "./fetchers/wikipedia/supported-languages";
import YahooAPISupportedLanguages from "./fetchers/yahoo/supported-languages";
import YandexAPISupportedLanguages from "./fetchers/yandex/supported-languages";
import YouTubeAPISupportedLanguages from "./fetchers/youtube/supported-languages";
import EbayAPISupportedLanguages from "./fetchers/ebay/supported-languages";
import AlibabaAPISupportedLanguages from "./fetchers/alibaba/supported-languages";
import PlayStoreAPISupportedLanguages from "./fetchers/play-store/supported-languages";
import FiverrAPISupportedLanguages from "./fetchers/fiverr/supported-languages";

export enum List {
    Amazon,
    Ask,
    Bing,
    Google,
    WikiPedia,
    Yahoo,
    Yandex,
    YouTube,
    EBay,
    Alibaba,
    PlayStore,
    Fiverr,
    KeywordKeg
}

export let Identifiers: {[api: number]: string} = {};

Identifiers[List.Amazon] = "amazon";
Identifiers[List.Ask] = "ask";
Identifiers[List.Bing] = "bing";
Identifiers[List.Google] = "google";
Identifiers[List.WikiPedia] = "wikipedia";
Identifiers[List.Yahoo] = "yahoo";
Identifiers[List.Yandex] = "yandex";
Identifiers[List.YouTube] = "youtube";
Identifiers[List.EBay] = "ebay";
Identifiers[List.Alibaba] = "alibaba";
Identifiers[List.PlayStore] = "play-store";
Identifiers[List.Fiverr] = "fiverr";
Identifiers[List.KeywordKeg] = "keywordkeg";

export let IdentifiersToAPI: {[identifier: string]: List} = {};

IdentifiersToAPI[Identifiers[List.Amazon]] = List.Amazon;
IdentifiersToAPI[Identifiers[List.Ask]] = List.Ask;
IdentifiersToAPI[Identifiers[List.Bing]] = List.Bing;
IdentifiersToAPI[Identifiers[List.Google]] = List.Google;
IdentifiersToAPI[Identifiers[List.WikiPedia]] = List.WikiPedia;
IdentifiersToAPI[Identifiers[List.Yahoo]] = List.Yahoo;
IdentifiersToAPI[Identifiers[List.Yandex]] = List.Yandex;
IdentifiersToAPI[Identifiers[List.YouTube]] = List.YouTube;
IdentifiersToAPI[Identifiers[List.EBay]] = List.EBay;
IdentifiersToAPI[Identifiers[List.Alibaba]] = List.Alibaba;
IdentifiersToAPI[Identifiers[List.PlayStore]] = List.PlayStore;
IdentifiersToAPI[Identifiers[List.Fiverr]] = List.Fiverr;
IdentifiersToAPI[Identifiers[List.KeywordKeg]] = List.KeywordKeg;

export let Names: {[api: number]: string} = {};

Names[List.Amazon] = "Amazon";
Names[List.Ask] = "Ask";
Names[List.Bing] = "Bing";
Names[List.Google] = "Google";
Names[List.WikiPedia] = "Wikipedia";
Names[List.Yahoo] = "Yahoo";
Names[List.Yandex] = "Yandex";
Names[List.YouTube] = "YouTube";
Names[List.EBay] = "eBay";
Names[List.Alibaba] = "Alibaba";
Names[List.PlayStore] = "Play Store";
Names[List.Fiverr] = "Fiverr";
Names[List.KeywordKeg] = "KeywordKeg";

export let Constructors: {[identifier: number]: any} = {};

Constructors[List.Amazon] = AmazonAPIFetcher;
Constructors[List.Ask] = AskAPIFetcher;
Constructors[List.Bing] = BingAPIFetcher;
Constructors[List.Google] = GoogleAPIFetcher;
Constructors[List.WikiPedia] = WikiPediaAPIFetcher;
Constructors[List.Yahoo] = YahooAPIFetcher;
Constructors[List.Yandex] = YandexAPIFetcher;
Constructors[List.YouTube] = YouTubeAPIFetcher;
Constructors[List.EBay] = EBayAPIFetcher;
Constructors[List.Alibaba] = AlibabaAPIFetcher;
Constructors[List.PlayStore] = PlayStoreAPIFetcher;
Constructors[List.Fiverr] = FiverrAPIFetcher;

export interface RequirementInterface {
    country: boolean,
    language: boolean
}

export let Requirements: {[identifier: number]: RequirementInterface} = {};

Requirements[List.Amazon] = {country: true, language: false};
Requirements[List.Ask] = {country: false, language: false};
Requirements[List.Bing] = {country: true, language: true};
Requirements[List.Google] = {country: true, language: true};
Requirements[List.WikiPedia] = {country: true, language: false};
Requirements[List.Yahoo] = {country: true, language: false};
Requirements[List.Yandex] = {country: false, language: false};
Requirements[List.YouTube] = {country: true, language: true};
Requirements[List.EBay] = {country: true, language: false};
Requirements[List.Alibaba] = {country: false, language: true};
Requirements[List.PlayStore] = {country: true, language: true};
Requirements[List.Fiverr] = {country: false, language: true};

export let SupportedCountries: {[identifier: number]: Country[]} = {};

SupportedCountries[List.Amazon] = AmazonAPISupportedCountries;
SupportedCountries[List.Ask] = AskAPISupportedCountries;
SupportedCountries[List.Bing] = BingAPISupportedCountries;
SupportedCountries[List.Google] = GoogleAPISupportedCountries;
SupportedCountries[List.WikiPedia] = WikiPediaAPISupportedCountries;
SupportedCountries[List.Yahoo] = YahooAPISupportedCountries;
SupportedCountries[List.Yandex] = YandexAPISupportedCountries;
SupportedCountries[List.YouTube] = YouTubeAPISupportedCountries;
SupportedCountries[List.EBay] = EbayAPISupportedCountries;
SupportedCountries[List.Alibaba] = AlibabaAPISupportedCountries;
SupportedCountries[List.PlayStore] = PlayStoreAPISupportedCountries;
SupportedCountries[List.Fiverr] = FiverrAPISupportedCountries;

export let SupportedLanguages: {[identifier: number]: Language[]} = {};

SupportedLanguages[List.Amazon] = AmazonAPISupportedLanguages;
SupportedLanguages[List.Ask] = AskAPISupportedLanguages;
SupportedLanguages[List.Bing] = BingAPISupportedLanguages;
SupportedLanguages[List.Google] = GoogleAPISupportedLanguages;
SupportedLanguages[List.WikiPedia] = WikiPediaAPISupportedLanguages;
SupportedLanguages[List.Yahoo] = YahooAPISupportedLanguages;
SupportedLanguages[List.Yandex] = YandexAPISupportedLanguages;
SupportedLanguages[List.YouTube] = YouTubeAPISupportedLanguages;
SupportedLanguages[List.EBay] = EbayAPISupportedLanguages;
SupportedLanguages[List.Alibaba] = AlibabaAPISupportedLanguages;
SupportedLanguages[List.PlayStore] = PlayStoreAPISupportedLanguages;
SupportedLanguages[List.Fiverr] = FiverrAPISupportedLanguages;

export const All: List[] = [
    List.Amazon,
    List.Ask,
    List.Alibaba,
    List.Bing,
    List.Google,
    List.WikiPedia,
    List.Yahoo,
    List.Yandex,
    List.YouTube,
    List.EBay,
    List.PlayStore
];

export function checkCountrySupport(api: List, country: Country): boolean {
    return SupportedCountries[api].indexOf(country) !== -1;
}

export function checkLanguageSupport(api: List, language: Language): boolean {
    return SupportedLanguages[api].indexOf(language) !== -1;
}

export default List;
