import * as _ from "lodash";
import {IFilters} from "../api/keyword-keg/search/filters";
import {IndustryInterface as IIndustry} from "../api/keyword-keg/search/industries";


const FILTER_SUBJECTS = [
    'monthly_volume', 'value', 'cpc', 'words', 'length', 'competition', 'onpage_difficulty','offpage_difficulty', 'seo_difficulty', 'ctr_opportunity', 'kw_potential'
];

/**
 * Count the number of filters applied
 * @param {IFilters} filters
 * @returns {number}
 */
export function count(filters: IFilters): number {
  let count = FILTER_SUBJECTS.reduce((total, column) => {
    const min = _.get(filters, [column, 'min'], null) === null ? 0 : 1;
    const max = _.get(filters, [column, 'max'], null) === null ? 0 : 1;
        return total+min+max;
  }, 0);
    if(filters.regexp && filters.regexp.trim().length) count++;
  let industries = _.get(filters, 'industries', []);
    if(!_.isNull(industries) && industries.length) count++;
    if(filters.serp) {
    count += _.filter(_.keys(filters.serp), (k) => {
      return filters.serp[k] !== "maybe";
    }).length;
  }
  return count;
}